import { useCallback } from 'react';

import { Col, message } from 'antd';

import { MaterialsStateSelector } from '@modules/materials/domain/store/selector';
import { updateMaterialInfo } from '@modules/materials/domain/store/slice';
import { ModulesWrapper } from '@shared/components/ModulesWrapper/ModulesWrapper';
import { TiptapEditor } from '@shared/components/TiptapEditor/TiptapEditor';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';

export const MaterialsDescription: React.FC = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const messageKey = 'updatedMaterialDescription';
  const dispatch = useAppDispatch();

  const { currentTabLang, materialsInfo } = useAppSelector(
    MaterialsStateSelector,
  );

  const handleUpdateFinish = useCallback(
    (value: any) => {
      messageApi.open({
        key: messageKey,
        type: 'loading',
        content: 'Загрузка..',
      });

      const updatedMaterialInfo = {
        materialInfo: { description: value },
        lang: currentTabLang,
        id: materialsInfo?.id,
      };
      dispatch(updateMaterialInfo(updatedMaterialInfo)).then(({ meta }) => {
        if (meta.requestStatus === 'fulfilled') {
          messageApi.open({
            key: messageKey,
            type: 'success',
            content: 'Данные успешно обновлены.',
            duration: 1,
          });
        } else {
          messageApi.open({
            key: messageKey,
            type: 'error',
            content: 'Произошла ошибка при обновлении данных.',
            duration: 1,
          });
        }
      });
    },
    [currentTabLang, materialsInfo],
  );

  return (
    <>
      {contextHolder}
      <ModulesWrapper title="Описание">
        <Col span={24} style={{ width: '100%' }}>
          <TiptapEditor
            initialValue={materialsInfo?.description ?? ''}
            onSave={handleUpdateFinish}
          />
        </Col>
      </ModulesWrapper>
    </>
  );
};
