import { axiosInstance } from '@libs/http';
import {
  ContactState,
  IContact,
} from '@modules/contacts/domain/interface/interface';
import { ContactsApi } from '@modules/contacts/domain/store/api';
import { ContactsSliceConstants } from '@modules/contacts/domain/store/sliceConstants';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialState: ContactState = {
  language: 'ru',
  contacts: [],
  loading: false,
  currentContact: null,
  languages: {
    ru: {},
    en: {},
    kg: {},
  },
};

export const getContacts = createAsyncThunk(
  ContactsSliceConstants.GetContacts,
  async function ({ lang }: { lang: string }, { rejectWithValue }) {
    try {
      const { data } = await axiosInstance.get<Awaited<IContact[]>>(
        `${ContactsApi.contacts}?lang=${lang}&slug=contacts`,
      );

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getContact = createAsyncThunk(
  ContactsSliceConstants.GetContact,
  async function (
    { code, lang }: { code: string; lang: string },
    { rejectWithValue },
  ) {
    try {
      const { data } = await axiosInstance.get<Awaited<IContact>>(
        `${ContactsApi.contact}?code=${code}&lang=${lang}`,
      );

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const createContact = createAsyncThunk(
  ContactsSliceConstants.CreateContact,
  async function (
    { contacts, language }: { contacts: any; language: string },
    { rejectWithValue, dispatch },
  ) {
    try {
      const { data } = await axiosInstance.post(
        ContactsApi.createContact,
        contacts,
      );

      dispatch(getContacts({ lang: language }));

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const deleteContact = createAsyncThunk(
  ContactsSliceConstants.DeleteContact,
  async function (
    { id, language }: { id: number; language: string },
    { rejectWithValue, dispatch },
  ) {
    try {
      const { data } = await axiosInstance.delete(
        `${ContactsApi.deleteContact}/${id}`,
      );

      dispatch(getContacts({ lang: language }));

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const editContact = createAsyncThunk(
  ContactsSliceConstants.EditContact,
  async function (
    { contact, language }: { contact: any; language: string },
    { rejectWithValue, dispatch },
  ) {
    try {
      const { code, title, content } = contact;
      const { data } = await axiosInstance.patch(
        `${ContactsApi.editContact}/${code}?lang=${language}`,
        { title, content, metaTitle: '', metaDescription: '' },
      );

      dispatch(getContacts({ lang: language }));

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

const ContactsSlice = createSlice({
  name: 'ContactsSlice',
  initialState,
  reducers: {
    changeCurrentLanguage: (state, { payload }) => {
      state.language = payload;
    },
    changeCurrentContact: (state, { payload }) => {
      state.currentContact = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getContacts.fulfilled, (state, { payload }) => {
        return {
          ...state,
          contacts: payload,
          loading: false,
        };
      })
      .addCase(getContacts.pending, (state, { payload }) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getContacts.rejected, (state, { payload }) => {
        return {
          ...state,
          loading: false,
        };
      });
    builder
      .addCase(getContact.fulfilled, (state, { payload }) => {
        return {
          ...state,
          currentContact: payload,
          loading: false,
        };
      })
      .addCase(getContact.pending, (state, { payload }) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getContact.rejected, (state, { payload }) => {
        return {
          ...state,
          loading: false,
        };
      });
  },
});

export default ContactsSlice.reducer;
export const { changeCurrentLanguage, changeCurrentContact } =
  ContactsSlice.actions;
