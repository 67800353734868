import { PropsWithChildren } from 'react';

import { Col, Row, Typography } from 'antd';

type ModulesWrapperProps = {
  footer?: JSX.Element;
  header?: JSX.Element;
  title?: string;
} & PropsWithChildren;

export const ModulesWrapper: React.FC<ModulesWrapperProps> = ({
  footer,
  header,
  title,
  children,
}) => {
  return (
    <Row
      gutter={[0, 24]}
      style={{
        backgroundColor: '#FFFFFF',
        padding: 24,
        borderRadius: 6,
        border: '1px solid rgb(0, 0, 0, 0.06)',
      }}
    >
      <Col span={24}>
        {header || (
          <Typography.Title style={{ fontSize: 20 }}>{title}</Typography.Title>
        )}
      </Col>
      <Col span={24}>{children}</Col>
      <Col span={24}>{footer || null}</Col>
    </Row>
  );
};
