import React from 'react';

import { Form, Input } from 'antd';

import { TableEditorProps } from '@ui-kit/customTable/types';

export const EditString: React.FC<TableEditorProps> = React.memo(
  ({ customColumn, editValidator }) => {
    const { dataIndex, title } = customColumn;
    const validationRules = editValidator?.rules;

    return (
      <Form.Item
        name={dataIndex}
        style={{ margin: 0 }}
        rules={
          validationRules ?? [
            {
              required: true,
              message: title,
            },
          ]
        }
      >
        <Input />
      </Form.Item>
    );
  },
);
