import React, { useEffect } from 'react';

import { Button, Flex, Typography } from 'antd';

import {
  AlignCenterOutlined,
  AlignLeftOutlined,
  AlignRightOutlined,
  BoldOutlined,
  FileImageOutlined,
  ItalicOutlined,
  LinkOutlined,
  OrderedListOutlined,
  RedoOutlined,
  StrikethroughOutlined,
  UnderlineOutlined,
  UndoOutlined,
} from '@ant-design/icons';
import { extensions } from '@shared/components/TiptapEditor/constants/extensions';
// eslint-disable-next-line import/no-extraneous-dependencies
import { BubbleMenu, EditorContent, useEditor } from '@tiptap/react';

import './tiptap.css';

interface TiptapEditorProps {
  initialValue?: string;
  onSave?: (value: string) => void;
  onChange?: (value: string) => void;
}

export const TiptapEditor: React.FC<TiptapEditorProps> = React.memo(
  ({ initialValue, onSave, onChange }) => {
    const editor = useEditor({
      extensions,
      content: initialValue,
      onUpdate: ({ editor: currentEditor }) => {
        if (onChange) {
          const html = currentEditor.getHTML();
          onChange(html);
        }
      },
    });

    useEffect(() => {
      if (editor && initialValue) {
        editor.commands.setContent(initialValue);
      }
    }, [initialValue]);

    if (!editor) {
      return <div>123</div>;
    }

    const addImage = () => {
      const url = window.prompt('URL');

      if (url) {
        editor.chain().focus().setImage({ src: url }).run();
      }
    };

    const addIframe = () => {
      let url = window.prompt('URL');

      const isValidUrl = url?.startsWith('https');

      const isYoutube = url?.includes('youtube');

      if (isYoutube && isValidUrl) {
        url = url?.replace('watch?v=', 'embed/') ?? '';
      }

      if (isValidUrl && url?.length) {
        editor
          .chain()
          .focus()
          .setParagraph()
          .setIframe({
            src: url,
            width: '500px',
            height: '500px',
          })
          .run();
      }
    };

    const handleSave = () => {
      if (onSave) {
        const contentHTML = editor.getHTML();
        onSave(contentHTML);
      }
    };

    const setLink = () => {
      const previousUrl = editor.getAttributes('link').href;
      const url = window.prompt('URL', previousUrl);

      if (url === null) {
        return;
      }

      editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: url })
        .run();
    };

    return (
      <div className="container">
        <div className="editor">
          <div className="menu">
            <Button
              onClick={() => editor.chain().focus().toggleBold().run()}
              disabled={!editor.can().chain().focus().toggleBold().run()}
              className={editor.isActive('bold') ? 'is-active' : ''}
              icon={<BoldOutlined />}
              type="text"
            />
            <Button
              type="text"
              icon={<ItalicOutlined />}
              onClick={() => editor.chain().focus().toggleItalic().run()}
              disabled={!editor.can().chain().focus().toggleItalic().run()}
              className={editor.isActive('italic') ? 'is-active' : ''}
            />
            <Button
              type="text"
              icon={<UnderlineOutlined />}
              onClick={() => editor.chain().focus().toggleUnderline().run()}
              disabled={!editor.can().chain().focus().toggleUnderline().run()}
            />
            <Button
              type="text"
              onClick={() => editor.chain().focus().toggleStrike().run()}
              disabled={!editor.can().chain().focus().toggleStrike().run()}
              className={editor.isActive('strike') ? 'is-active' : ''}
              icon={<StrikethroughOutlined />}
            />
            <Button
              type="text"
              onClick={() => editor.chain().focus().setParagraph().run()}
              className={editor.isActive('paragraph') ? 'is-active' : ''}
            >
              Paragraph
            </Button>
            <Button
              type="text"
              onClick={() =>
                editor.chain().focus().toggleHeading({ level: 1 }).run()
              }
              className={
                editor.isActive('heading', { level: 1 }) ? 'is-active' : ''
              }
            >
              H1
            </Button>
            <Button
              type="text"
              onClick={() =>
                editor.chain().focus().toggleHeading({ level: 2 }).run()
              }
              className={
                editor.isActive('heading', { level: 2 }) ? 'is-active' : ''
              }
            >
              H2
            </Button>
            <Button
              type="text"
              onClick={() =>
                editor.chain().focus().toggleHeading({ level: 3 }).run()
              }
              className={
                editor.isActive('heading', { level: 3 }) ? 'is-active' : ''
              }
            >
              H3
            </Button>
            <Button
              type="text"
              onClick={() =>
                editor.chain().focus().toggleHeading({ level: 4 }).run()
              }
              className={
                editor.isActive('heading', { level: 4 }) ? 'is-active' : ''
              }
            >
              H4
            </Button>
            <Button
              type="text"
              onClick={() =>
                editor.chain().focus().toggleHeading({ level: 5 }).run()
              }
              className={
                editor.isActive('heading', { level: 5 }) ? 'is-active' : ''
              }
            >
              H5
            </Button>
            <Button
              type="text"
              onClick={() =>
                editor.chain().focus().toggleHeading({ level: 6 }).run()
              }
              className={
                editor.isActive('heading', { level: 6 }) ? 'is-active' : ''
              }
            >
              H6
            </Button>
            <Button
              type="text"
              icon={<OrderedListOutlined />}
              onClick={() => editor.chain().focus().toggleOrderedList().run()}
              className={editor.isActive('orderedList') ? 'is-active' : ''}
            />
            <Button
              type="text"
              icon={<AlignLeftOutlined />}
              onClick={() => editor.chain().focus().setTextAlign('left').run()}
              className={
                editor.isActive({ textAlign: 'left' }) ? 'is-active' : ''
              }
            />
            <Button
              type="text"
              icon={<AlignCenterOutlined />}
              onClick={() =>
                editor.chain().focus().setTextAlign('center').run()
              }
              className={
                editor.isActive({ textAlign: 'center' }) ? 'is-active' : ''
              }
            />
            <Button
              type="text"
              icon={<AlignRightOutlined />}
              onClick={() => editor.chain().focus().setTextAlign('right').run()}
              className={
                editor.isActive({ textAlign: 'right' }) ? 'is-active' : ''
              }
            />
            <Button
              type="text"
              icon={<LinkOutlined />}
              onClick={setLink}
              className={editor.isActive('link') ? 'is-active' : ''}
            />
            <Button
              type="text"
              icon={<FileImageOutlined />}
              onClick={addImage}
            />
            <Button
              type="text"
              icon={<UndoOutlined />}
              onClick={() => editor.chain().focus().undo().run()}
              disabled={!editor.can().chain().focus().undo().run()}
            />
            <Button
              type="text"
              icon={<RedoOutlined />}
              onClick={() => editor.chain().focus().redo().run()}
              disabled={!editor.can().chain().focus().redo().run()}
            />
            <Button onClick={addIframe} type="text">
              <Typography style={{ fontWeight: 500 }}>
                Добавить видео
              </Typography>
            </Button>
          </div>
        </div>

        <BubbleMenu
          pluginKey="bubbleMenuText"
          className="bubble-menu-dark"
          tippyOptions={{ duration: 150 }}
          editor={editor}
          shouldShow={({ from, to }) => {
            // only show if range is selected.
            return from !== to;
          }}
        >
          <Button
            className="menu-button"
            onClick={() => editor.chain().focus().undo().run()}
            disabled={!editor.can().undo()}
            icon={<UndoOutlined />}
          />
          <Button
            icon={<RedoOutlined />}
            className="menu-button"
            onClick={() => editor.chain().focus().redo().run()}
            disabled={!editor.can().redo()}
          />
          <Button
            icon={<LinkOutlined />}
            onClick={setLink}
            className={editor.isActive('link') ? 'is-active' : ''}
          />
          <Button
            type="primary"
            onClick={() => editor.chain().focus().toggleBold().run()}
            disabled={!editor.can().chain().focus().toggleBold().run()}
            className={editor.isActive('bold') ? 'is-active' : ''}
            icon={<BoldOutlined />}
          />
          <Button
            type="primary"
            icon={<UnderlineOutlined />}
            onClick={() => editor.chain().focus().toggleUnderline().run()}
            disabled={!editor.can().chain().focus().toggleUnderline().run()}
          />
          <Button
            type="primary"
            icon={<ItalicOutlined />}
            onClick={() => editor.chain().focus().toggleItalic().run()}
            disabled={!editor.can().chain().focus().toggleItalic().run()}
            className={editor.isActive('italic') ? 'is-active' : ''}
          />
          <Button
            type="primary"
            onClick={() => editor.chain().focus().toggleStrike().run()}
            disabled={!editor.can().chain().focus().toggleStrike().run()}
            className={editor.isActive('strike') ? 'is-active' : ''}
            icon={<StrikethroughOutlined />}
          />
          <Button
            icon={<AlignLeftOutlined />}
            onClick={() => editor.chain().focus().setTextAlign('left').run()}
            className={
              editor.isActive({ textAlign: 'left' }) ? 'is-active' : ''
            }
          />
          <Button
            icon={<AlignCenterOutlined />}
            onClick={() => editor.chain().focus().setTextAlign('center').run()}
            className={
              editor.isActive({ textAlign: 'center' }) ? 'is-active' : ''
            }
          />
          <Button
            icon={<AlignRightOutlined />}
            onClick={() => editor.chain().focus().setTextAlign('right').run()}
            className={
              editor.isActive({ textAlign: 'right' }) ? 'is-active' : ''
            }
          />
        </BubbleMenu>
        <EditorContent editor={editor} />
        {onSave ? (
          <Flex justify="end">
            <Button className="btn-save" onClick={handleSave}>
              Сохранить
            </Button>
          </Flex>
        ) : null}
      </div>
    );
  },
);
