import { Tag } from 'antd';

import { JsonRecord } from '@app/types';
import { TableRendererProps } from '@ui-kit/customTable/types';

const tagsStatuses = {
  Planing: {
    color: 'processing',
    text: 'Запланировано',
  },
};

export const renderTag = <RecordType extends JsonRecord>({
  record,
  customColumn,
}: TableRendererProps<RecordType>) => {
  const value = record[customColumn.dataIndex];
  const status = (tagsStatuses as any)[value];

  return (
    <Tag color={status?.color || 'error'}>{status?.text || 'Незивестно'}</Tag>
  );
};
