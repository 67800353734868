import { LocalStorage, LocalStorageKeyEnum } from '@libs/localStorage';

export interface AuthContextType {
  token: string | null;
  login: (token: string | null) => boolean;
  logout: () => void;
}

export interface LoginProps {
  username: string;
  password: string;
  remember: boolean;
}

export const auth: AuthContextType = {
  token: localStorage.getItem(LocalStorageKeyEnum.AccessToken),
  login: (token) => {
    if (token) {
      LocalStorage.setItem(LocalStorageKeyEnum.AccessToken, token);
      auth.token = token;
      return true;
    }
    return false;
  },
  logout: () => {
    auth.token = null;
    LocalStorage.removeItem(LocalStorageKeyEnum.AccessToken);
  },
};
