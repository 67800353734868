import { useEffect, useState } from 'react';

import { Button, Form, Input, Space, Upload } from 'antd';

import { UploadOutlined } from '@ant-design/icons';
import { IUsefulResource } from '@modules/usefulResources/domain/interface/interface';
import { UsefulResourcesStateSelector } from '@modules/usefulResources/domain/store/selector';
import { SubmitButton } from '@ui-kit/button';

import { useAppSelector } from '../../../store/hooks';

interface IUsefulResourcesFormProps {
  onSave: (value: any) => void;
}

type CreateUsefulResource = Omit<IUsefulResource, 'id'>;

export const UsefulResourcesEditForm: React.FC<IUsefulResourcesFormProps> = ({
  onSave,
}) => {
  const [imgFileList, setImgFileList] = useState<any[]>([]);
  const [logoFileList, setLogoFileList] = useState<any[]>([]);
  const { currentUsefulResource } = useAppSelector(
    UsefulResourcesStateSelector,
  );

  const [form] = Form.useForm();

  const onFinish = (value: CreateUsefulResource) => {
    onSave({ ...value, code: currentUsefulResource?.code });
    form.resetFields();
  };

  const handleLogoFileChange = ({ fileList }: any) => {
    setLogoFileList(fileList);
    return fileList;
  };

  const handleImgFileChange = ({ fileList }: any) => {
    setImgFileList(fileList);
    return fileList;
  };

  useEffect(() => {
    setLogoFileList([
      {
        uid: currentUsefulResource?.logo ?? '',
        name: currentUsefulResource?.logo,
      },
    ]);
    setImgFileList([
      {
        uid: currentUsefulResource?.imageUrl ?? '',
        name: currentUsefulResource?.imageUrl,
      },
    ]);

    form.setFieldsValue(currentUsefulResource);
  }, [currentUsefulResource]);

  return (
    <Form
      onFinish={onFinish}
      form={form}
      name="UsefulResourcesEditForm"
      layout="vertical"
    >
      <Form.Item name="title" label="Название" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item
        name="url"
        label="Ссылка на источник"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        getValueFromEvent={handleImgFileChange}
        name="imageUrl"
        label="Обложка"
        rules={[{ required: true }]}
      >
        <Upload
          beforeUpload={() => false}
          multiple={false}
          name="image"
          accept="image/*"
          maxCount={1}
          fileList={imgFileList}
        >
          <Button icon={<UploadOutlined />}>Загрузить обложку</Button>
        </Upload>
      </Form.Item>
      <Form.Item
        getValueFromEvent={handleLogoFileChange}
        name="logo"
        label="Логотип"
        rules={[{ required: true, message: 'Добавьте логотип' }]}
      >
        <Upload
          beforeUpload={() => false}
          multiple={false}
          name="image"
          maxCount={1}
          accept="image/*"
          fileList={logoFileList}
        >
          <Button icon={<UploadOutlined />}>Загрузить логотип</Button>
        </Upload>
      </Form.Item>
      <Form.Item>
        <Space>
          <SubmitButton form={form} />
        </Space>
      </Form.Item>
    </Form>
  );
};
