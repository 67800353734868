import { JsonRecord } from '@app/types';
import { TableRendererProps } from '@ui-kit/customTable/types';
import get from 'lodash/get';

export function renderString<RecordType extends JsonRecord>({
  record,
  customColumn,
}: TableRendererProps<RecordType>) {
  const value = get(record, customColumn.dataIndex);
  return <div>{value}</div>;
}
