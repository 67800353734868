import { NewsPage } from '@modules/news/ui/NewsPage';
import React from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';

import { AboutPage } from '@modules/about/ui/AboutPage';
import { AdminLayout } from '@modules/admin/ui/AdminLayout';
import { loginLoader, protectedLoader } from '@modules/auth/ui/loaders';
import { LoginForm } from '@modules/auth/ui/Login';
import { ContactsPage } from '@modules/contacts/ui/ConctactsPage';
import { InformationPage } from '@modules/information/ui/InformationPage';
import { LayersPage } from '@modules/layers/ui/LayersPage';
import { Materials } from '@modules/materials/ui/Materials';
import { Messages } from '@modules/messages/ui/Messages';
import { NaturalDisaster } from '@modules/naturalDisaster/ui/NaturalDisaster';
import { SupportPage } from '@modules/support/ui/SupportPage';
import { UsefulResourcesPage } from '@modules/usefulResources/ui/UsefulResourcesPage';

import { AppRoutes } from './appRoutes';

export const router = createBrowserRouter([
  {
    path: AppRoutes.default,
    element: <AdminLayout />,
    loader: protectedLoader,
    errorElement: <div>Error Page</div>,
    children: [
      {
        id: 'main',
        path: AppRoutes.default,
        element: <Navigate to={AppRoutes.materials} />,
        errorElement: <div>Error Page</div>,
      },
      {
        id: 'about',
        path: AppRoutes.about,
        element: <AboutPage />,
        errorElement: <div>Error Page</div>,
      },
      {
        id: 'layers',
        path: AppRoutes.layers,
        element: <LayersPage />,
        errorElement: <div>Error Page</div>,
      },
      {
        id: 'information',
        path: AppRoutes.information,
        element: <InformationPage />,
        errorElement: <div>Error Page</div>,
      },
      {
        id: 'news',
        path: AppRoutes.news,
        element: <NewsPage />,
        errorElement: <div>Error Page</div>,
      },
      {
        id: 'useful-resources',
        path: AppRoutes.usefulResources,
        element: <UsefulResourcesPage />,
        errorElement: <div>Error Page</div>,
      },
      {
        id: 'materials',
        path: AppRoutes.materials,
        element: <Materials />,
        errorElement: <div>Error Page</div>,
      },
      {
        id: 'proposals',
        path: AppRoutes.proposals,
        element: <SupportPage />,
        errorElement: <div>Error Page</div>,
      },
      {
        id: 'naturalDisaster',
        path: AppRoutes.naturalDisaster,
        element: <NaturalDisaster />,
        errorElement: <div>Error Page</div>,
      },
      {
        id: 'messages',
        path: AppRoutes.messages,
        element: <Messages />,
        errorElement: <div>Error Page</div>,
      },
      {
        id: 'contacts',
        path: AppRoutes.contacts,
        element: <ContactsPage />,
        errorElement: <div>Error Page</div>,
      },
    ],
  },
  {
    id: 'login',
    path: AppRoutes.login,
    loader: loginLoader,
    element: <LoginForm />,
    errorElement: <div>Error Page</div>,
  },
  {
    id: 'invalid',
    path: AppRoutes.invalid,
    element: <Navigate to={AppRoutes.default} />,
  },
]);
