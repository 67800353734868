import { axiosInstance, axiosMchsInstance } from '@libs/http';
import {
  FetchDictionary,
  GetLayersState,
  IDictionary,
  IGetLayersArgs,
  ILayers,
  InitialLayer,
  LayersState,
} from '@modules/layers/domain/interface/interface';
import { LayersApi } from '@modules/layers/domain/store/api';
import { LayersSliceConstants } from '@modules/layers/domain/store/sliceConstants';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useParams } from '@shared/hooks/useParams/useParams';

const initialState: LayersState = {
  layers: [],
  loading: false,
  currentLayer: null,
  region: [],
  district: [],
  layerQueryParams: {
    page: 1,
    perPage: 10,
  },
  totalLayer: 0,
};

export const getDistrict = createAsyncThunk<FetchDictionary[], number>(
  LayersSliceConstants.getDistrict,
  async function (parentId, { rejectWithValue }) {
    try {
      const { data } = await axiosMchsInstance.get(
        `/admin/dictionary/district/by-parent-id?parent=${parentId}`,
      );

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getRegions = createAsyncThunk<FetchDictionary[]>(
  LayersSliceConstants.getRegion,
  async function (_, { rejectWithValue }) {
    try {
      const { data } = await axiosMchsInstance.get('/admin/dictionary/region');

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getLayers = createAsyncThunk<GetLayersState, IGetLayersArgs>(
  LayersSliceConstants.GetLayers,
  async function (params, { rejectWithValue }) {
    try {
      const filteredParams = useParams(params);
      const { data } = await axiosInstance.get<Awaited<GetLayersState>>(
        `${LayersApi.layers}${filteredParams}`,
      );

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const createLayer = createAsyncThunk(
  LayersSliceConstants.CreateLayer,
  async function (
    { layer }: { layer: InitialLayer },
    { rejectWithValue, dispatch },
  ) {
    try {
      const { data } = await axiosInstance.post(LayersApi.createLayers, layer);

      dispatch(getLayers({}));

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

interface updateLayerType {
  id: number;
  layer: ILayers;
}

export const updateLayer = createAsyncThunk<void, updateLayerType>(
  LayersSliceConstants.UpdateLayer,
  async function (layer, { rejectWithValue, dispatch }) {
    try {
      const { data } = await axiosInstance.put(
        `${LayersApi.updateLayer}/${layer.id}`,
        layer.layer,
      );

      dispatch(getLayers({}));

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const deleteLayer = createAsyncThunk(
  LayersSliceConstants.DeleteLayer,
  async function ({ id }: { id: number }, { rejectWithValue, dispatch }) {
    try {
      const { data } = await axiosInstance.delete(
        `${LayersApi.deleteLayer}/${id}`,
      );

      dispatch(getLayers({}));

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

const LayerSlice = createSlice({
  name: 'LayerSlice',
  initialState,
  reducers: {
    changeCurrentLayer: (state, { payload }) => {
      state.currentLayer = payload;
    },
    resetDistrict: (state, { payload }) => {
      state.district = payload;
    },
    changeLayerQueryParams: (state, { payload }) => {
      state.layerQueryParams = {
        ...state.layerQueryParams,
        ...payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getLayers.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          console.log('payload', payload);
          return {
            ...state,
            layers: payload.content.map(
              (item: { district: IDictionary; region: IDictionary }) => ({
                ...item,
                district: item.district?.value,
                region: item.region?.value,
              }),
            ),
            totalLayer: payload.count,
            loading: false,
          };
        },
      )
      .addCase(getLayers.pending, (state, { payload }) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getLayers.rejected, (state) => {
        return {
          ...state,
          loading: false,
        };
      });
    builder
      .addCase(
        getRegions.fulfilled,
        (state, { payload: region }: PayloadAction<FetchDictionary[]>) => {
          if (!Array.isArray(region)) {
            state.region = [];
          } else {
            state.region = region.map((item) => ({
              value: item.id,
              label: item.value,
            }));
          }
        },
      )
      .addCase(getRegions.rejected, (state) => {
        state.region = [];
      });
    builder
      .addCase(
        getDistrict.fulfilled,
        (state, { payload: district }: PayloadAction<FetchDictionary[]>) => {
          if (!Array.isArray(district)) {
            state.district = [];
          } else {
            state.district = district.map((item) => ({
              value: item.id,
              label: item.value,
            }));
          }
        },
      )
      .addCase(getDistrict.rejected, (state) => {
        state.district = [];
      });
  },
});

export default LayerSlice.reducer;
export const { changeCurrentLayer, resetDistrict, changeLayerQueryParams } =
  LayerSlice.actions;
