import React, { useEffect } from 'react';

import { Pagination, Tabs } from 'antd';

import { SupportStateSelector } from '@modules/support/domain/store/selector';
import {
  changeCurrentPage,
  changeCurrentTab,
  getAllSupportMessages,
} from '@modules/support/domain/store/slice';
import { SupportBody } from '@modules/support/ui/SupportBody';
import { ModulesTitle } from '@shared/components/ModulesTitle/ModulesTitle';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';

const supportTabs = [
  { key: 'false', label: 'Актуальные' },
  { key: 'true', label: 'Архив' },
];

export const SupportPage: React.FC = () => {
  const { perPage, currentSupportTab, page, supportMessageCount } =
    useAppSelector(SupportStateSelector);
  const dispatch = useAppDispatch();

  const handleChangePage = (currentPage: number) => {
    dispatch(changeCurrentPage(currentPage));
  };

  const handleChangeTab = (key: string) => {
    dispatch(changeCurrentTab(key));
    dispatch(getAllSupportMessages({ archived: key, page, perPage }));
  };

  useEffect(() => {
    dispatch(
      getAllSupportMessages({ archived: currentSupportTab, page, perPage }),
    );
  }, [page]);

  return (
    <>
      <ModulesTitle>Обратная связь</ModulesTitle>
      <Tabs
        style={{ marginBottom: 32 }}
        defaultActiveKey=""
        items={supportTabs}
        onChange={handleChangeTab}
      />
      <SupportBody />
      {supportMessageCount ? (
        <Pagination
          onChange={handleChangePage}
          style={{ margin: 10 }}
          pageSize={perPage}
          total={supportMessageCount}
        />
      ) : null}
    </>
  );
};
