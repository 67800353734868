import { message } from 'antd';

import { NaturalDisasterStateSelector } from '@modules/naturalDisaster/domain/store/selector';
import {
  createGroup,
  updateGroup,
} from '@modules/naturalDisaster/domain/store/slice';
import { CreateDisasterForm } from '@modules/naturalDisaster/ui/CreateDisasterForm';
import { EditDisasterForm } from '@modules/naturalDisaster/ui/EditDisasterForm';
import { CustomModalWrapper } from '@shared/components/CustomModalWrapper/CustomModalWrapper';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';

interface DisasterFormActionsProps {
  closeDisasterCreate: () => void;
  closeDisasterEdit: () => void;
  openDisasterCreate: boolean;
  openDisasterEdit: boolean;
  handleChangeTab: (value: any) => void;
}

export const DisasterFormActions: React.FC<DisasterFormActionsProps> = ({
  closeDisasterCreate,
  closeDisasterEdit,
  openDisasterEdit,
  openDisasterCreate,
  handleChangeTab,
}) => {
  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useAppDispatch();
  const { currentTabLang, languages, currentDisaster } = useAppSelector(
    NaturalDisasterStateSelector,
  );

  const createDisasterMessageKey = 'createDisasterKey';
  const updateDisasterMessageKey = 'updateDisasterKey';

  const createDisasterFinish = (value: any) => {
    messageApi.open({
      key: createDisasterMessageKey,
      type: 'loading',
      content: 'Загрузка..',
    });

    const newLanguages: any = {};
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const languagesKey in languages) {
      newLanguages[languagesKey] = value;
    }

    dispatch(createGroup({ group: newLanguages, lang: currentTabLang })).then(
      ({ meta }) => {
        if (meta.requestStatus === 'fulfilled') {
          messageApi.open({
            key: createDisasterMessageKey,
            type: 'success',
            content: 'Данные успешно созданы.',
            duration: 1,
          });
        } else {
          messageApi.open({
            key: createDisasterMessageKey,
            type: 'error',
            content: 'Произошла ошибка при создании данных.',
            duration: 1,
          });
        }
      },
    );
    closeDisasterCreate();
  };

  const editDisasterFinish = (value: any) => {
    if (!currentDisaster) return;

    messageApi.open({
      key: updateDisasterMessageKey,
      type: 'loading',
      content: 'Загрузка..',
    });

    dispatch(
      updateGroup({
        group: value,
        lang: currentTabLang,
        id: currentDisaster.id,
      }),
    ).then(({ meta }) => {
      if (meta.requestStatus === 'fulfilled') {
        messageApi.open({
          key: updateDisasterMessageKey,
          type: 'success',
          content: 'Данные успешно обновлены.',
          duration: 1,
        });
      } else {
        messageApi.open({
          key: updateDisasterMessageKey,
          type: 'error',
          content: 'Произошла ошибка при обновлении данных.',
          duration: 1,
        });
      }
    });
    closeDisasterEdit();
  };

  return (
    <>
      {contextHolder}
      <CustomModalWrapper
        handleChangeTab={handleChangeTab}
        activeTabsKey={currentTabLang}
        modalTitle="Создание каталога"
        tabs
        open={openDisasterCreate}
        onCancel={closeDisasterCreate}
        footer={null}
      >
        <CreateDisasterForm onSave={createDisasterFinish} />
      </CustomModalWrapper>
      <CustomModalWrapper
        handleChangeTab={handleChangeTab}
        activeTabsKey={currentTabLang}
        modalTitle="Редактирование каталога"
        tabs
        open={openDisasterEdit}
        onCancel={closeDisasterEdit}
        footer={null}
      >
        <EditDisasterForm onSave={editDisasterFinish} />
      </CustomModalWrapper>
    </>
  );
};
