import {
  Button,
  Card,
  Col,
  Flex,
  message,
  Popconfirm,
  Row,
  Space,
  Typography,
} from 'antd';

import {
  DatabaseOutlined,
  FileImageOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { MapModal } from '@modules/map/ui/MapModal';
import { IMessages } from '@modules/messages/domain/interface/interface';
import { MessagesStateSelector } from '@modules/messages/domain/store/selector';
import {
  archiveMessage,
  changeCurrentMessage,
} from '@modules/messages/domain/store/slice';
import { useModal } from '@shared/hooks/useModal/useModal';
import dayjs from 'dayjs';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import styles from './MessagesCard.module.scss';

interface ISupportCardProps {
  item: IMessages;
  showPreview: () => void;
}

export const MessagesCard: React.FC<ISupportCardProps> = ({
  item,
  showPreview,
}) => {
  const { open, closeModal, showModal } = useModal();
  const [messageApi, contextHolder] = message.useMessage();
  const geolocation = item.geo.split(',').map((geo) => +geo);
  const dispatch = useAppDispatch();
  const { currentTab, page, perPage } = useAppSelector(MessagesStateSelector);

  const archiveMessageKey = 'archiveMessageKey';

  const handleArchive = (id: number) => {
    messageApi.open({
      key: archiveMessageKey,
      type: 'loading',
      content: 'Загрузка..',
    });

    dispatch(archiveMessage({ id, archived: currentTab, page, perPage })).then(
      ({ meta }) => {
        if (meta.requestStatus === 'fulfilled') {
          messageApi.open({
            key: archiveMessageKey,
            type: 'success',
            content: 'Данные успешно заархивированы.',
            duration: 2,
          });
        } else {
          messageApi.open({
            key: archiveMessageKey,
            type: 'error',
            content: 'Произошла ошибка при архивации данных.',
            duration: 2,
          });
        }
      },
    );
  };

  const handleOpenPreview = (record: IMessages) => {
    dispatch(changeCurrentMessage(record));
    showPreview();
  };

  return (
    <Card>
      <Row gutter={[0, 8]}>
        <Col span={24}>
          <Flex justify="space-between" className={styles.messageCardSection}>
            <Col>Тип происшествия : {item.type}</Col>
            <Space className={styles.messageCardBtns}>
              {item.photo.length && (
                <Button
                  onClick={() => handleOpenPreview(item)}
                  icon={<FileImageOutlined />}
                >
                  Посмотреть файлы
                </Button>
              )}
              {!item.isArchived && (
                <Popconfirm
                  title="Архивировать данные."
                  description="Вы уверены, что хотите архивировать эти данные?"
                  onConfirm={() => handleArchive(item.id)}
                  okText="Да"
                  cancelText="Нет"
                  icon={<QuestionCircleOutlined />}
                >
                  <Button icon={<DatabaseOutlined />}>Архивировать</Button>
                </Popconfirm>
              )}
              {!Number.isNaN(geolocation[0]) && (
                <Button onClick={showModal}>Посмотреть геолокацию</Button>
              )}
            </Space>
          </Flex>
        </Col>
        <MapModal
          type="Point"
          open={open}
          onCancel={closeModal}
          coords={geolocation}
        />
        <Col span={24}>
          <Typography>Сообщение : {item.message}</Typography>
        </Col>
        <Col span={24}>
          <Flex
            justify="space-between"
            align="center"
            className={styles.messageCardContactSection}
          >
            <Space size="large">
              <Typography className={styles.footerInfo}>
                ФИО : {item.name}
              </Typography>
              <Typography className={styles.footerInfo}>
                Телефон : {item.phone}
              </Typography>
            </Space>
            <Typography>
              {dayjs(item.created).locale('ru').format('D MMMM YYYY HH:mm')}
            </Typography>
          </Flex>
        </Col>
      </Row>
    </Card>
  );
};
