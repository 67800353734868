import { Button, ButtonProps } from 'antd';

import styles from './PrimaryButton.module.scss';

type PrimaryButtonPropTypes = ButtonProps;

export const PrimaryButton: React.FC<PrimaryButtonPropTypes> = ({
  children,
  ...rest
}) => (
  <Button type="primary" className={styles.button} {...rest}>
    {children}
  </Button>
);
