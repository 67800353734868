import { NaturalDisasterStateSelector } from '@modules/naturalDisaster/domain/store/selector';

import { useAppSelector } from '../../../store/hooks';

export const DisasterPreview: React.FC = () => {
  const { currentTypeDisaster } = useAppSelector(NaturalDisasterStateSelector);

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: currentTypeDisaster?.content ?? '',
      }}
    />
  );
};
