import { PropsWithChildren } from 'react';

import { Typography } from 'antd';

type TitleProps = {
  fontSize?: number;
} & PropsWithChildren;

export const ModulesTitle: React.FC<TitleProps> = ({
  children,
  fontSize = 24,
}) => {
  return (
    <Typography.Title style={{ fontSize, marginBottom: 32 }}>
      {children}
    </Typography.Title>
  );
};
