import { PropsWithChildren } from 'react';

import { ModalProps, Tabs, Typography } from 'antd';

import { useTabs } from '@shared/hooks/useTabs/useTabs';
import { CustomModal } from '@ui-kit/customModal';

type CustomModalWrapperProps = {
  modalTitle?: string;
  tabs?: boolean;
  activeTabsKey?: string;
  handleChangeTab?: (value: string) => void;
} & ModalProps &
  PropsWithChildren;

export const CustomModalWrapper: React.FC<CustomModalWrapperProps> = (
  props,
) => {
  const { modalTitle, activeTabsKey, tabs, handleChangeTab, children } = props;
  const tabItems = useTabs();

  return (
    <CustomModal {...props}>
      {modalTitle ? (
        <Typography style={{ fontWeight: 600, marginBottom: 32 }}>
          {modalTitle}
        </Typography>
      ) : null}
      {tabs ? (
        <Tabs
          activeKey={activeTabsKey}
          style={{ marginBottom: 16 }}
          defaultActiveKey="ru"
          items={tabItems}
          onChange={handleChangeTab}
        />
      ) : null}
      {children}
    </CustomModal>
  );
};
