import { Button, Flex, Form, Input } from 'antd';

import { ContactsStateSelector } from '@modules/contacts/domain/store/selector';

import { useAppSelector } from '../../../store/hooks';

interface ContactCreateFormProps {
  onSave: (value: any) => void;
  onCancel: () => void;
}

export const ContactCreateForm: React.FC<ContactCreateFormProps> = ({
  onSave,
  onCancel,
}) => {
  const [form] = Form.useForm();
  const { languages } = useAppSelector(ContactsStateSelector);

  const onFinish = (value: any) => {
    const newLanguages: any = {};
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const languagesKey in languages) {
      newLanguages[languagesKey] = {
        ...value,
        slug: 'contacts',
        metaTitle: '',
        metaDescription: '',
      };
    }

    onSave(newLanguages);
    form.resetFields();
  };

  return (
    <Form
      onFinish={onFinish}
      form={form}
      name="ContactCreateForm"
      layout="vertical"
    >
      <Form.Item name="title" label="Название" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item name="content" label="Содержание" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item>
        <Flex justify="space-between" align="center">
          <Button onClick={onCancel}>Отмена</Button>
          <Button type="primary" htmlType="submit">
            Отправить
          </Button>
        </Flex>
      </Form.Item>
    </Form>
  );
};
