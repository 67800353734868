import { Button, Collapse, Flex, Popconfirm, Space, Typography } from 'antd';

import {
  DeleteOutlined,
  EditOutlined,
  FileAddOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import {
  IDisasters,
  ITypeDisasters,
} from '@modules/naturalDisaster/domain/interface/interface';
import { NaturalDisasterStateSelector } from '@modules/naturalDisaster/domain/store/selector';
import {
  changeCurrentDisaster,
  changeCurrentTypeDisaster,
  deleteCatalog,
  deleteGroup,
} from '@modules/naturalDisaster/domain/store/slice';
import { useCatalogTableColumns } from '@modules/naturalDisaster/services';
import { ModulesTitle } from '@shared/components/ModulesTitle/ModulesTitle';
import { ModulesWrapper } from '@shared/components/ModulesWrapper/ModulesWrapper';
import { PrimaryButton } from '@ui-kit/button';
import { TableCustom } from '@ui-kit/customTable';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import styles from './naturalDisaster.module.scss';

interface IGroupsTableProps {
  showDisasterCreateModal: () => void;
  showDisasterEditModal: () => void;
  showTypeDisasterCreateModal: () => void;
  showTypeDisasterEditModal: () => void;
  showPreview: () => void;
}

export const CatalogBody: React.FC<IGroupsTableProps> = ({
  showDisasterCreateModal,
  showDisasterEditModal,
  showTypeDisasterEditModal,
  showTypeDisasterCreateModal,
  showPreview,
}) => {
  const dispatch = useAppDispatch();
  const columns = useCatalogTableColumns();
  const { disasters, languages, currentTabLang } = useAppSelector(
    NaturalDisasterStateSelector,
  );

  const handleCreateDisaster = () => {
    dispatch(changeCurrentDisaster(null));
    showDisasterCreateModal();
  };

  const handleUpdateDisaster = (item: IDisasters) => {
    dispatch(changeCurrentDisaster(item));
    showDisasterEditModal();
  };

  const handleDeleteDisaster = (id: number) => {
    dispatch(deleteGroup({ id, lang: currentTabLang }));
  };

  const handleCreateTypeDisaster = (item: IDisasters) => {
    dispatch(changeCurrentDisaster(item));
    showTypeDisasterCreateModal();
  };

  const handleUpdateTypeDisaster = (record: ITypeDisasters) => {
    dispatch(changeCurrentTypeDisaster(record));
    showTypeDisasterEditModal();
  };

  const handleDeleteTypeDisaster = (record: ITypeDisasters) => {
    dispatch(deleteCatalog({ id: record.id, lang: currentTabLang }));
  };

  const handlePreview = (record: ITypeDisasters) => {
    dispatch(changeCurrentTypeDisaster(record));
    showPreview();
  };

  return (
    <ModulesWrapper
      header={
        <Flex justify="space-between" style={{ flexWrap: 'wrap' }}>
          <ModulesTitle fontSize={20}>Каталог ОПП</ModulesTitle>
          <PrimaryButton
            icon={<FileAddOutlined />}
            onClick={handleCreateDisaster}
          >
            Создать каталог
          </PrimaryButton>
        </Flex>
      }
    >
      <Space direction="vertical" style={{ width: '100%' }} size="middle">
        {disasters?.map((item) => (
          <Collapse
            size="large"
            accordion
            key={item.id}
            collapsible="header"
            items={[
              {
                key: item.id,
                label: (
                  <Flex
                    justify="space-between"
                    align="center"
                    className={styles.naturalDisasterBody}
                  >
                    <Typography style={{ fontWeight: 600 }}>
                      {item.name}
                    </Typography>
                    <Space onClick={(e) => e.stopPropagation()}>
                      <Button
                        onClick={() => handleCreateTypeDisaster(item)}
                        type="primary"
                        icon={<FileAddOutlined />}
                      />
                      <Button
                        icon={<EditOutlined style={{ color: '#8C8C8C' }} />}
                        onClick={() => handleUpdateDisaster(item)}
                        type="default"
                      />
                      <Popconfirm
                        title="Удалить данные."
                        description="Вы уверены, что хотите удалить эти данные?"
                        onConfirm={() => handleDeleteDisaster(item.id)}
                        okText="Да"
                        cancelText="Нет"
                        icon={
                          <QuestionCircleOutlined style={{ color: 'red' }} />
                        }
                      >
                        <Button
                          danger
                          style={{ backgroundColor: '#FF4D4F' }}
                          icon={<DeleteOutlined style={{ color: '#FFF' }} />}
                        />
                      </Popconfirm>
                    </Space>
                  </Flex>
                ),
                children: (
                  <TableCustom
                    rowKey={(record) => record.id}
                    dataSource={item?.catalog}
                    columns={columns as any}
                    actionProps={{
                      onHandleEdit: handleUpdateTypeDisaster,
                      onHandleDelete: handleDeleteTypeDisaster,
                      onHandleWatch: handlePreview,
                    }}
                  />
                ),
              },
            ]}
          />
        ))}
      </Space>
    </ModulesWrapper>
  );
};
