import {
  Button,
  Card,
  Col,
  Flex,
  message,
  Popconfirm,
  Row,
  Space,
  Typography,
} from 'antd';

import { DatabaseOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { ISupportMessage } from '@modules/support/domain/interface/interface';
import { SupportStateSelector } from '@modules/support/domain/store/selector';
import { archiveSupport } from '@modules/support/domain/store/slice';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import styles from './SupportCard.module.scss';

interface ISupportCardProps {
  item: ISupportMessage;
}

export const SupportCard: React.FC<ISupportCardProps> = ({ item }) => {
  const [messageApi, contextHolder] = message.useMessage();
  const { page, perPage } = useAppSelector(SupportStateSelector);
  const dispatch = useAppDispatch();
  const archiveMessageKey = 'archiveMessageKey';

  const handleArchiveSupport = (record: ISupportMessage) => {
    messageApi.open({
      key: archiveMessageKey,
      type: 'loading',
      content: 'Загрузка..',
    });

    dispatch(
      archiveSupport({
        id: record.id,
        archived: `${record.isArchived}`,
        perPage,
        page,
      }),
    ).then(({ meta }) => {
      if (meta.requestStatus === 'fulfilled') {
        messageApi.open({
          key: archiveMessageKey,
          type: 'success',
          content: 'Данные успешно заархивированы.',
          duration: 2,
        });
      } else {
        messageApi.open({
          key: archiveMessageKey,
          type: 'error',
          content: 'Произошла ошибка при архивации данных.',
          duration: 2,
        });
      }
    });
  };

  return (
    <Card>
      {contextHolder}
      <Row gutter={[0, 8]}>
        <Col span={24}>
          <Flex justify="space-between">
            <Col>
              <Typography>Тема : {item.theme}</Typography>
            </Col>
            {!item.isArchived && (
              <Popconfirm
                title="Архивировать данные."
                description="Вы уверены, что хотите архивировать эти данные?"
                onConfirm={() => handleArchiveSupport(item)}
                okText="Да"
                cancelText="Нет"
                icon={<QuestionCircleOutlined />}
              >
                <Button icon={<DatabaseOutlined />}>Архивировать</Button>
              </Popconfirm>
            )}
          </Flex>
        </Col>
        <Col span={24}>
          <Typography>Содержание : {item.message}</Typography>
        </Col>
        <Col span={24}>
          <Flex
            justify="space-between"
            align="center"
            className={styles.supportCardContactSection}
          >
            <Space size="large" className={styles.supportCardContact}>
              <Typography className={styles.footerInfo}>
                ФИО : {item.name}
              </Typography>
              <Typography className={styles.footerInfo}>
                Почта : {item.email}
              </Typography>
              <Typography className={styles.footerInfo}>
                Телефон : {item.phone}
              </Typography>
            </Space>
            <Typography>{item.created}</Typography>
          </Flex>
        </Col>
      </Row>
    </Card>
  );
};
