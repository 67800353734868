import React from 'react';

import { Modal, ModalProps } from 'antd';

type PropsType = ModalProps & { subtitle?: string };

export const CustomModal: React.FC<PropsType> = (props) => {
  const { children } = props;
  return <Modal {...props}>{children}</Modal>;
};
