import { message, Pagination } from 'antd';

import { LayersStateSelector } from '@modules/layers/domain/store/selector';
import {
  changeLayerQueryParams,
  createLayer,
  updateLayer,
} from '@modules/layers/domain/store/slice';
import { LayerFilter } from '@modules/layers/ui/LayerFilter';
import { LayersBody } from '@modules/layers/ui/LayersBody';
import { LayersCreateForm } from '@modules/layers/ui/LayersCreatForm';
import { LayersEditForm } from '@modules/layers/ui/LayersEditForm';
import { ModulesTitle } from '@shared/components/ModulesTitle/ModulesTitle';
import { useModal } from '@shared/hooks/useModal/useModal';
import { CustomModal } from '@ui-kit/customModal';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';

export const LayersPage: React.FC = () => {
  const [messageApi, contextHolder] = message.useMessage();

  const dispatch = useAppDispatch();
  const createLayerMessageKey = 'createLayer';
  const updateLayerMessageKey = 'updateLayer';
  const { layerQueryParams, totalLayer } = useAppSelector(LayersStateSelector);

  const {
    open: openCreateModal,
    showModal: showCreateModal,
    closeModal: closeCreateModal,
  } = useModal();
  const {
    open: openEditModal,
    showModal: showEditModal,
    closeModal: closeEditModal,
  } = useModal();

  const createLayerFinish = (values: any) => {
    messageApi.open({
      key: createLayerMessageKey,
      type: 'loading',
      content: 'Загрузка..',
    });
    dispatch(createLayer({ layer: values })).then(({ meta }) => {
      if (meta.requestStatus === 'fulfilled') {
        messageApi.open({
          key: createLayerMessageKey,
          type: 'success',
          content: 'Данные успешно созданы.',
          duration: 1,
        });
      } else {
        messageApi.open({
          key: createLayerMessageKey,
          type: 'error',
          content: 'Произошла ошибка при создании данных.',
          duration: 1,
        });
      }
    });
    closeCreateModal();
  };

  const editLayerFinish = (values: any, id: number) => {
    messageApi.open({
      key: updateLayerMessageKey,
      type: 'loading',
      content: 'Загрузка..',
    });

    dispatch(updateLayer({ layer: values, id })).then(({ meta }) => {
      if (meta.requestStatus === 'fulfilled') {
        messageApi.open({
          key: updateLayerMessageKey,
          type: 'success',
          content: 'Данные успешно обновлены.',
          duration: 1,
        });
      } else {
        messageApi.open({
          key: updateLayerMessageKey,
          type: 'error',
          content: 'Произошла ошибка при обновлении данных.',
          duration: 1,
        });
      }
    });
    closeEditModal();
  };

  const handleChangePage = (page: number) => {
    dispatch(
      changeLayerQueryParams({
        page,
      }),
    );
  };

  return (
    <>
      {contextHolder}
      <ModulesTitle>Управление картами</ModulesTitle>
      <LayerFilter />
      <LayersBody
        showCreateModal={showCreateModal}
        showEditModal={showEditModal}
      />
      <CustomModal
        open={openCreateModal}
        onCancel={closeCreateModal}
        footer={null}
        destroyOnClose
      >
        <LayersCreateForm
          onSave={createLayerFinish}
          onCancel={closeCreateModal}
        />
      </CustomModal>
      <CustomModal
        destroyOnClose
        open={openEditModal}
        onCancel={closeEditModal}
        footer={null}
      >
        <LayersEditForm onSave={editLayerFinish} onCancel={closeEditModal} />
      </CustomModal>
      <Pagination
        onChange={handleChangePage}
        style={{ margin: 10 }}
        pageSize={layerQueryParams.perPage}
        total={totalLayer}
      />
    </>
  );
};
