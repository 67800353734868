import { axiosInstance } from '@libs/http';

export const useUploadFiles = async (images: any[], url: string) => {
  const results = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const image of images) {
    try {
      // eslint-disable-next-line no-await-in-loop
      const result = await axiosInstance.get(`${url}?path=${image.path}`, {
        responseType: 'blob',
      });

      results.push(result);
    } catch (error: any) {
      throw new Error(error);
    }
  }
  return results;
};
