import { TableColumn } from '@ui-kit/customTable/types';

type PropsType = {
  customColumns: TableColumn[];
  columnsOrder?: string[];
};

export function filterCutomTableColumns({
  customColumns,
  columnsOrder,
}: PropsType): TableColumn[] {
  if (columnsOrder) {
    return customColumns.filter((customColumn) => {
      return columnsOrder.includes(customColumn.dataIndex);
    });
  }

  return customColumns;
}
