import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import ReactPlayer from 'react-player';

import { Empty } from 'antd';

import { MessagesApi } from '@modules/messages/domain/store/api';
import { MessagesStateSelector } from '@modules/messages/domain/store/selector';
import { useUploadFiles } from '@shared/hooks/useUploadFiles/useUploadFilies';

import { useAppSelector } from '../../../store/hooks';

export const MessagesVideoPreview: React.FC = () => {
  const [files, setFiles] = useState<any[]>([]);

  const { currentMessage } = useAppSelector(MessagesStateSelector);

  const getImages = (videos: any[]) => {
    useUploadFiles(videos, MessagesApi.imagesMessage).then((response) => {
      const urlFromBlob = response.map((item) =>
        URL.createObjectURL(item.data),
      );

      setFiles(urlFromBlob);
    });
  };

  useEffect(() => {
    if (!currentMessage) return;
    if (!currentMessage.photo.length) return;

    getImages(
      currentMessage.photo.filter((item) => item.type.includes('video')),
    );
  }, [currentMessage]);

  return (
    <div>
      {files.length ? (
        files.map((item, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <ReactPlayer key={idx} url={item} width={300} height={300} controls />
        ))
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </div>
  );
};
