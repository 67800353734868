import { Modal } from 'antd';

import { Map } from '@modules/map/ui/Map';

interface IMapModalPageProps {
  type: string;
  coords?: any;
  setCoords?: React.Dispatch<React.SetStateAction<any>>;
  open: boolean;
  onCancel: () => void;
}

export const MapModal: React.FC<IMapModalPageProps> = ({
  type,
  setCoords,
  coords,
  open,
  onCancel,
}) => {
  return (
    <Modal
      onCancel={onCancel}
      open={open}
      destroyOnClose
      width="70%"
      closeIcon={null}
      footer={null}
    >
      <Map
        coords={coords}
        onCancel={onCancel}
        type={type}
        setCoords={setCoords}
      />
    </Modal>
  );
};
