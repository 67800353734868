import { useCallback, useEffect, useState } from 'react';

import { Button, Col, Form, Input, Row, Space, Typography, Upload } from 'antd';
import { UploadChangeParam } from 'antd/es/upload/interface';

import { UploadOutlined } from '@ant-design/icons';
import { NaturalDisasterStateSelector } from '@modules/naturalDisaster/domain/store/selector';
import { TiptapEditor } from '@shared/components/TiptapEditor/TiptapEditor';
import { SubmitButton } from '@ui-kit/button';

import { useAppSelector } from '../../../store/hooks';

interface INaturalDisasterFormProps {
  onSave: (value: any) => void;
}

export const EditTypeDisasterForm: React.FC<INaturalDisasterFormProps> = ({
  onSave,
}) => {
  const [currentFileList, setCurrentFileList] = useState<
    { uid: string; name: string; idDisaster: number }[]
  >([]);
  const [isDisaster, setIsDisaster] = useState(false);
  const [editorValue, setEditorValue] = useState('');
  const [descriptionValue, setDescriptionValue] = useState('');
  const { currentTypeDisaster } = useAppSelector(NaturalDisasterStateSelector);

  const [form] = Form.useForm();

  const getEditorValue = useCallback((value: any) => {
    setEditorValue(value);
  }, []);

  const onChangeDescription = useCallback((value: string) => {
    setDescriptionValue(value);
  }, []);

  const handleFileChange = (file: UploadChangeParam) => {
    setCurrentFileList(
      file.fileList.map((item) => ({
        uid: item.uid,
        name: item.name,
        idDisaster: 0,
      })),
    );
    setIsDisaster(true);
    return file;
  };

  const onFinish = (value: any) => {
    onSave({ ...value, content: editorValue, description: descriptionValue });
    form.resetFields();
  };

  useEffect(() => {
    if (!currentTypeDisaster) return;

    if (currentTypeDisaster.photo) {
      setIsDisaster(false);
      setCurrentFileList([
        {
          uid: currentTypeDisaster.photo,
          name: currentTypeDisaster.photo,
          idDisaster: currentTypeDisaster.id,
        },
      ]);
    }
    form.setFieldsValue(currentTypeDisaster);
    setEditorValue(currentTypeDisaster.content);
  }, [currentTypeDisaster]);

  const currentFileListMutation = !isDisaster
    ? currentFileList.filter(
        (item) => item.idDisaster === currentTypeDisaster?.id,
      )
    : currentFileList;
  return (
    <Form
      onFinish={onFinish}
      form={form}
      name="EditTypeDisasterForm"
      layout="vertical"
    >
      <Form.Item name="name" label="Название" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Row>
        <Typography>Описание</Typography>
        <Col span={24}>
          <TiptapEditor
            initialValue={currentTypeDisaster?.description ?? ''}
            onChange={onChangeDescription}
          />
        </Col>
      </Row>
      <Form.Item name="iframe" label="iframe">
        <Input />
      </Form.Item>
      <Form.Item name="title" label="title">
        <Input />
      </Form.Item>
      <Form.Item name="iframe2" label="iframe 2">
        <Input />
      </Form.Item>
      <Form.Item name="title2" label="title 2">
        <Input />
      </Form.Item>
      <Form.Item name="iframe3" label="iframe 3">
        <Input />
      </Form.Item>
      <Form.Item name="title3" label="title 3">
        <Input />
      </Form.Item>
      <Form.Item name="iframe4" label="iframe 4">
        <Input />
      </Form.Item>
      <Form.Item name="title4" label="title 4">
        <Input />
      </Form.Item>
      <Form.Item name="iframe5" label="iframe 5">
        <Input />
      </Form.Item>
      <Form.Item name="title5" label="title 5">
        <Input />
      </Form.Item>
      <Form.Item
        getValueFromEvent={handleFileChange}
        name="photo"
        label="Изображение"
      >
        <Upload
          beforeUpload={() => false}
          multiple={false}
          fileList={currentFileListMutation}
          name="photo"
          accept="image/*"
          maxCount={1}
        >
          <Button icon={<UploadOutlined />}>Загрузить изображение</Button>
        </Upload>
      </Form.Item>
      <Row>
        <Col>
          <Typography>Содержание</Typography>
          <TiptapEditor
            onChange={getEditorValue}
            initialValue={currentTypeDisaster?.content ?? ''}
          />
        </Col>
      </Row>
      <Form.Item style={{ marginTop: 20 }}>
        <Space>
          <SubmitButton form={form} />
        </Space>
      </Form.Item>
    </Form>
  );
};
