import { useEffect } from 'react';

import { Tabs } from 'antd';

import { AboutStateSelector } from '@modules/about/domain/store/selector';
import {
  changeCurrentLanguage,
  getAllCompany,
} from '@modules/about/domain/store/slice';
import { Tab } from '@modules/about/ui/Tab';
import { ModulesTitle } from '@shared/components/ModulesTitle/ModulesTitle';
import { useTabs } from '@shared/hooks/useTabs/useTabs';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';

export const AboutPage: React.FC = () => {
  const { language } = useAppSelector(AboutStateSelector);
  const dispatch = useAppDispatch();

  const tabItems = useTabs();

  const handleTabChange = (newLanguage: string) => {
    dispatch(changeCurrentLanguage(newLanguage));
    dispatch(getAllCompany(newLanguage));
  };

  useEffect(() => {
    dispatch(getAllCompany(language));
  }, []);

  return (
    <>
      <ModulesTitle>О ЕСКМП</ModulesTitle>
      <Tabs
        style={{ marginBottom: 32 }}
        defaultActiveKey="1"
        items={tabItems}
        onChange={handleTabChange}
      />
      <Tab />
    </>
  );
};
