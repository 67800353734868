export const NaturalDisasterSliceConstants = {
  CreateGroup: 'createGroup',
  GetAllGroups: 'getAllGroups',
  UpdateGroup: 'updateGroup',
  DeleteGroup: 'deleteGroup',
  getAllCatalogs: 'getAllCatalogs',
  UpdateCatalog: 'updateCatalog',
  CreateCatalog: 'createCatalog',
  DeleteCatalog: 'deleteCatalog',
  GetGroup: 'getGroup',
  ConvertCatalogImageToPath: 'convertCatalogImageToPath',
};
