import React, { useEffect } from 'react';

import { Pagination, Tabs } from 'antd';

import { MessagesStateSelector } from '@modules/messages/domain/store/selector';
import {
  changeCurrentPage,
  changeCurrentTab,
  getAllMessages,
} from '@modules/messages/domain/store/slice';
import { MessagesBody } from '@modules/messages/ui/MessagesBody';
import { ModulesTitle } from '@shared/components/ModulesTitle/ModulesTitle';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';

const messagesTabs = [
  { key: 'false', label: 'Актуальные' },
  { key: 'true', label: 'Архив' },
];

export const Messages: React.FC = () => {
  const dispatch = useAppDispatch();
  const { messageCount, perPage, page, currentTab } = useAppSelector(
    MessagesStateSelector,
  );

  const handleChangeTab = (key: string) => {
    dispatch(changeCurrentTab(key));
    dispatch(getAllMessages({ archived: key, page, perPage }));
  };

  const handleChangePage = (currentPage: number) => {
    dispatch(changeCurrentPage(currentPage));
  };

  useEffect(() => {
    dispatch(getAllMessages({ archived: currentTab, page, perPage }));
  }, [page]);

  return (
    <>
      <ModulesTitle>Сообщения об ОПП</ModulesTitle>
      <Tabs
        style={{ marginBottom: 32 }}
        defaultActiveKey=""
        items={messagesTabs}
        onChange={handleChangeTab}
      />
      <MessagesBody />
      <Pagination
        pageSize={perPage}
        onChange={handleChangePage}
        style={{ margin: 10 }}
        total={messageCount}
      />
    </>
  );
};
