import { Button, Form, Input, Space, Upload } from 'antd';

import { UploadOutlined } from '@ant-design/icons';
import { SubmitButton } from '@ui-kit/button';

interface IUsefulResourcesFormProps {
  onSave: (value: any) => void;
}

export const UsefulResourcesCreateForm: React.FC<IUsefulResourcesFormProps> = ({
  onSave,
}) => {
  const [form] = Form.useForm();

  const onFinish = (value: any) => {
    onSave(value);
    form.resetFields();
  };

  return (
    <Form
      onFinish={onFinish}
      form={form}
      name="UsefulResourcesCreateForm"
      layout="vertical"
    >
      <Form.Item name="title" label="Название" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item
        name="url"
        label="Ссылка на источник"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="imageUrl" label="Обложка" rules={[{ required: true }]}>
        <Upload
          beforeUpload={() => false}
          multiple={false}
          name="image"
          accept="image/*"
          maxCount={1}
        >
          <Button icon={<UploadOutlined />}>Загрузить обложку</Button>
        </Upload>
      </Form.Item>
      <Form.Item name="logo" label="Логотип" rules={[{ required: true }]}>
        <Upload
          beforeUpload={() => false}
          multiple={false}
          name="image"
          accept="image/*"
          maxCount={1}
        >
          <Button icon={<UploadOutlined />}>Загрузить логотип</Button>
        </Upload>
      </Form.Item>
      <Form.Item>
        <Space>
          <SubmitButton form={form} />
        </Space>
      </Form.Item>
    </Form>
  );
};
