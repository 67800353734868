// eslint-disable-next-line import/no-extraneous-dependencies
import { Color } from '@tiptap/extension-color';
// eslint-disable-next-line import/no-extraneous-dependencies
import Image from '@tiptap/extension-image';
// eslint-disable-next-line import/no-extraneous-dependencies
import Link from '@tiptap/extension-link';
// eslint-disable-next-line import/no-extraneous-dependencies
import ListItem from '@tiptap/extension-list-item';
// eslint-disable-next-line import/no-extraneous-dependencies
import TextAlign from '@tiptap/extension-text-align';
// eslint-disable-next-line import/no-extraneous-dependencies
import TextStyle from '@tiptap/extension-text-style';
// eslint-disable-next-line import/no-extraneous-dependencies
import Underline from '@tiptap/extension-underline';
// eslint-disable-next-line import/no-extraneous-dependencies
import StarterKit from '@tiptap/starter-kit';

// eslint-disable-next-line import/no-extraneous-dependencies, import/no-named-as-default
import Iframe from '../extensions/Iframe';
import { ImageAligment } from '../extensions/ImageAlignment';

export const extensions = [
  Color.configure({ types: [TextStyle.name, ListItem.name] }),
  TextStyle.configure({ types: [ListItem.name] } as any),
  StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false,
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false,
    },
  }),
  Image,
  Iframe,
  Underline,
  TextAlign.configure({
    types: ['heading', 'paragraph', 'image'],
  }),
  Link.configure({
    openOnClick: false,
    autolink: true,
  }),
  ImageAligment,
];
