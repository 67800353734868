export const MaterialsApi = {
  groups: '/admin/material',
  materials: '/admin/material/all',
  materialsInfo: '/admin/material-info',
  createGroup: '/admin/material/save',
  uploadImage: '/admin/material/upload',
  createMaterial: '/admin/material/create',
  deleteGroup: '/admin/material/delete-group',
  updateGroup: '/admin/material/update-group',
  updateMaterial: '/admin/material/update',
  deleteMaterial: '/admin/material/delete',
  createMaterialInfo: '/admin/material-info/create',
  updateMaterialInfo: '/admin/material-info/update',
  deleteMaterialInfo: '/admin/material-info/delete',
  activeMaterialInfo: '/admin/material-info/isActive',
  uploadMaterial: '/admin/material/file',
  getGroup: '/admin/material/group',
};
