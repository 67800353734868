import { useEffect } from 'react';

import { Button, Flex, Form, Input } from 'antd';

import { ContactsStateSelector } from '@modules/contacts/domain/store/selector';

import { useAppSelector } from '../../../store/hooks';

interface ContactEditFormProps {
  onSave: (value: any) => void;
  onCancel: () => void;
}

export const EditCreateForm: React.FC<ContactEditFormProps> = ({
  onSave,
  onCancel,
}) => {
  const [form] = Form.useForm();

  const { currentContact } = useAppSelector(ContactsStateSelector);

  const onFinish = (value: any) => {
    if (!currentContact) return;

    onSave({ ...value, code: currentContact.code });
    form.resetFields();
  };

  useEffect(() => {
    if (!currentContact) return;

    form.setFieldsValue(currentContact);
  }, [currentContact]);

  return (
    <Form
      onFinish={onFinish}
      form={form}
      name="ContactEditForm"
      layout="vertical"
    >
      <Form.Item name="title" label="Название" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item name="content" label="Содержание" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item>
        <Flex justify="space-between" align="center">
          <Button onClick={onCancel}>Отмена</Button>
          <Button type="primary" htmlType="submit">
            Отправить
          </Button>
        </Flex>
      </Form.Item>
    </Form>
  );
};
