import { useEffect } from 'react';

import { Flex, message, Tabs } from 'antd';

import { MaterialsStateSelector } from '@modules/materials/domain/store/selector';
import {
  changeCurrentLanguage,
  createGroup,
  createMaterial,
  getAllGroups,
  getAllMaterials,
  getAllMaterialsInfo,
  getGroup,
  updateGroup,
  updateMaterial,
} from '@modules/materials/domain/store/slice';
import { CreateGroupForm } from '@modules/materials/ui/CreateGroupForm';
import { CreateMaterialForm } from '@modules/materials/ui/CreateMaterialForm';
import { EditGroupForm } from '@modules/materials/ui/EditGroupForm';
import { EditMaterialForm } from '@modules/materials/ui/EditMaterialForm';
import { MaterialBody } from '@modules/materials/ui/MaterialBody';
import { MaterialPreview } from '@modules/materials/ui/MaterialPreview';
import { MaterialsDescription } from '@modules/materials/ui/MaterialsDescription';
import { CustomModalWrapper } from '@shared/components/CustomModalWrapper/CustomModalWrapper';
import { ModulesTitle } from '@shared/components/ModulesTitle/ModulesTitle';
import { useModal } from '@shared/hooks/useModal/useModal';
import { useTabs } from '@shared/hooks/useTabs/useTabs';
import { CustomModal } from '@ui-kit/customModal';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';

export const Materials: React.FC = () => {
  const dispatch = useAppDispatch();
  const { currentTabLang, currentGroup, languages } = useAppSelector(
    MaterialsStateSelector,
  );
  const tabItems = useTabs();
  const [messageApi, contextHolder] = message.useMessage();

  const createMaterialGroupMessageKey = 'createMaterialGroup';
  const updateMaterialGroupMessageKey = 'updateMaterialGroup';
  const createMaterialMessageKey = 'createMaterial';
  const updateMaterialMessageKey = 'updateMaterial';

  const {
    showModal: showPreview,
    closeModal: closePreview,
    open: openPreview,
  } = useModal();

  const {
    showModal: showCreateGroupModal,
    closeModal: closeCreateGroupModal,
    open: openCreateGroupModal,
  } = useModal();

  const {
    showModal: showEditGroupModal,
    closeModal: closeEditGroupModal,
    open: openEditGroupModal,
  } = useModal();

  const {
    showModal: showCreateMaterialModal,
    closeModal: closeCreateMaterialModal,
    open: openCreateMaterialModal,
  } = useModal();

  const {
    showModal: showEditMaterialModal,
    closeModal: closeEditMaterialModal,
    open: openEditMaterialModal,
  } = useModal();

  const createGroupFinish = (value: any) => {
    messageApi.open({
      key: createMaterialGroupMessageKey,
      type: 'loading',
      content: 'Загрузка..',
    });

    const newLanguages: any = {};
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const languagesKey in languages) {
      newLanguages[languagesKey] = value;
    }

    dispatch(
      createGroup({
        group: newLanguages,
        lang: currentTabLang,
      }),
    ).then(({ meta }) => {
      if (meta.requestStatus === 'fulfilled') {
        messageApi.open({
          key: createMaterialGroupMessageKey,
          type: 'success',
          content: 'Данные успешно созданы.',
          duration: 1,
        });
      } else {
        messageApi.open({
          key: createMaterialGroupMessageKey,
          type: 'error',
          content: 'Произошла ошибка при создании данных.',
          duration: 1,
        });
      }
    });
    closeCreateGroupModal();
  };

  const editGroupFinish = (value: any) => {
    if (!currentGroup) return;

    messageApi.open({
      key: updateMaterialGroupMessageKey,
      type: 'loading',
      content: 'Загрузка..',
    });

    dispatch(
      updateGroup({
        group: value,
        id: currentGroup.id,
        lang: currentTabLang,
      }),
    ).then(({ meta }) => {
      if (meta.requestStatus === 'fulfilled') {
        messageApi.open({
          key: updateMaterialGroupMessageKey,
          type: 'success',
          content: 'Данные успешно обновлены.',
          duration: 1,
        });
      } else {
        messageApi.open({
          key: updateMaterialGroupMessageKey,
          type: 'error',
          content: 'Произошла ошибка при обновлении данных.',
          duration: 1,
        });
      }
    });
    closeEditGroupModal();
  };

  const createMaterialFinish = (value: any) => {
    messageApi.open({
      key: createMaterialMessageKey,
      type: 'loading',
      content: 'Загрузка..',
    });

    dispatch(
      createMaterial({
        material: value,
        lang: currentTabLang,
        currentGroup,
      }),
    ).then(({ meta }) => {
      if (meta.requestStatus === 'fulfilled') {
        messageApi.open({
          key: createMaterialMessageKey,
          type: 'success',
          content: 'Данные успешно добавлены.',
          duration: 1,
        });
      } else {
        messageApi.open({
          key: createMaterialMessageKey,
          type: 'error',
          content: 'Произошла ошибка при обновлении данных.',
          duration: 1,
        });
      }
    });
    closeCreateMaterialModal();
  };

  const editMaterialFinish = (value: any) => {
    messageApi.open({
      key: updateMaterialMessageKey,
      type: 'loading',
      content: 'Загрузка..',
    });

    dispatch(
      updateMaterial({
        lang: currentTabLang,
        material: value,
      }),
    ).then(({ meta }) => {
      if (meta.requestStatus === 'fulfilled') {
        messageApi.open({
          key: updateMaterialMessageKey,
          type: 'success',
          content: 'Данные успешно обновлены.',
          duration: 1,
        });
      } else {
        messageApi.open({
          key: updateMaterialMessageKey,
          type: 'error',
          content: 'Произошла ошибка при обновлении данных.',
          duration: 1,
        });
      }
    });
    closeEditMaterialModal();
  };

  const handleChangeTab = (key: string) => {
    dispatch(changeCurrentLanguage(key));
    dispatch(getAllMaterials(key));
    dispatch(getAllGroups(key));
    dispatch(getAllMaterialsInfo(key));
    if (currentGroup)
      dispatch(getGroup({ lang: key, code: currentGroup.code }));
  };

  useEffect(() => {
    dispatch(getAllMaterials(currentTabLang));
    dispatch(getAllGroups(currentTabLang));
    dispatch(getAllMaterialsInfo(currentTabLang));
  }, []);

  return (
    <>
      {contextHolder}
      <ModulesTitle>Прогнозные материалы</ModulesTitle>
      <Tabs
        activeKey={currentTabLang}
        style={{ marginBottom: 32 }}
        defaultActiveKey="ru"
        items={tabItems}
        onChange={handleChangeTab}
      />
      <Flex vertical gap={50}>
        <MaterialsDescription />
        <MaterialBody
          showEditGroupModal={showEditGroupModal}
          showCreateGroupModal={showCreateGroupModal}
          showCreateMaterialModal={showCreateMaterialModal}
          showEditMaterialModal={showEditMaterialModal}
          showPreview={showPreview}
        />
      </Flex>
      <CustomModalWrapper
        modalTitle="Создание материала"
        open={openCreateGroupModal}
        tabs
        handleChangeTab={handleChangeTab}
        onCancel={closeCreateGroupModal}
        footer={null}
      >
        <CreateGroupForm onSave={createGroupFinish} />
      </CustomModalWrapper>
      <CustomModalWrapper
        open={openEditGroupModal}
        onCancel={closeEditGroupModal}
        footer={null}
        modalTitle="Редактирование новости"
        tabs
        activeTabsKey={currentTabLang}
        handleChangeTab={handleChangeTab}
      >
        <EditGroupForm onSave={editGroupFinish} />
      </CustomModalWrapper>
      <CustomModal
        open={openCreateMaterialModal}
        onCancel={closeCreateMaterialModal}
        footer={null}
      >
        <CreateMaterialForm onSave={createMaterialFinish} />
      </CustomModal>
      <CustomModal
        open={openEditMaterialModal}
        onCancel={closeEditMaterialModal}
        footer={null}
      >
        <EditMaterialForm onSave={editMaterialFinish} />
      </CustomModal>
      <CustomModal
        width="1000px"
        destroyOnClose
        open={openPreview}
        onCancel={closePreview}
        footer={null}
      >
        <MaterialPreview />
      </CustomModal>
    </>
  );
};
