import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';

import { Button, Card, Checkbox, Col, Form, Input, message, Row } from 'antd';

import Logo from '@assets/images/logo.png';
import { authLogin } from '@modules/auth/domain/store/slice';
import { auth, LoginProps } from '@modules/auth/ui/auth';

import { useAppDispatch } from '../../../store/hooks';
import styles from './Login.module.css';

export const LoginForm = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const onFinish = async (values: LoginProps) => {
    setLoading(true);
    try {
      const {
        payload: { token },
      } = await dispatch(authLogin(values));
      const success = auth.login(token);

      if (success) {
        const from = location.state?.from?.pathname || '/';
        navigate(from, { replace: true });
      }
    } catch (e) {
      message.error('Что-то пошло не так!');
    }
    setLoading(false);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className={styles.login_form_wrapper}>
      <Row justify="center" align="middle">
        <Col xs={24} sm={12} md={8} lg={6}>
          <Card
            title={
              <Row justify="center" style={{ padding: '10px 0' }}>
                <Col>
                  <img src={Logo} width={128} height={128} alt="logo" />
                </Col>
              </Row>
            }
            bordered={false}
          >
            <Form
              name="basic"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label="Логин"
                name="username"
                rules={[
                  { required: true, message: 'Please input your username!' },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Пароль"
                name="password"
                rules={[
                  { required: true, message: 'Please input your password!' },
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item name="remember" valuePropName="checked">
                <Checkbox>Запомнить</Checkbox>
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading}>
                  Авторизация
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
