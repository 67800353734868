import { combineReducers } from 'redux';

import AboutSlice from '@modules/about/domain/store/slice';
import AuthSlice from '@modules/auth/domain/store/slice';
import ContactsSlice from '@modules/contacts/domain/store/slice';
import InformationSlice from '@modules/information/domain/store/slice';
import NewsSlice from '@modules/news/domain/store/slice';
import LayerSlice from '@modules/layers/domain/store/slice';
import MaterialsSlice from '@modules/materials/domain/store/slice';
import MessagesSlice from '@modules/messages/domain/store/slice';
import NaturalDisasterSlice from '@modules/naturalDisaster/domain/store/slice';
import RegionsSlice from '@modules/regions/domain/store/slice';
import SupportMessageSlice from '@modules/support/domain/store/slice';
import UsefulResourcesSlice from '@modules/usefulResources/domain/store/slice';
import { configureStore } from '@reduxjs/toolkit';

const rootReducer = combineReducers({
  AuthSlice,
  AboutSlice,
  InformationSlice,
  NewsSlice,
  UsefulResourcesSlice,
  MaterialsSlice,
  MessagesSlice,
  SupportMessageSlice,
  NaturalDisasterSlice,
  RegionsSlice,
  LayerSlice,
  ContactsSlice,
});

export const store = configureStore({
  reducer: rootReducer,
  devTools: true,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(),
});
