import { useEffect } from 'react';

import { Form, Input, Space } from 'antd';

import { MaterialsStateSelector } from '@modules/materials/domain/store/selector';
import { SubmitButton } from '@ui-kit/button';

import { useAppSelector } from '../../../store/hooks';

interface IMaterialFormProps {
  onSave: (value: any) => void;
}

export const EditGroupForm: React.FC<IMaterialFormProps> = ({ onSave }) => {
  const { currentGroup } = useAppSelector(MaterialsStateSelector);

  const [form] = Form.useForm();
  const onFinish = async (value: any) => {
    if (!currentGroup) return;

    onSave({
      ...value,
      materials: currentGroup.materials.map((item) => item.id),
      code: currentGroup.code,
    });
    form.resetFields();
  };

  useEffect(() => {
    if (!currentGroup) return;
    form.setFieldsValue(currentGroup);
  }, [currentGroup]);

  return (
    <Form
      onFinish={onFinish}
      form={form}
      name="EditGroupForm"
      layout="vertical"
    >
      <Form.Item name="title" label="Название" rules={[{ required: true }]}>
        <Input placeholder="Название" />
      </Form.Item>
      <Form.Item>
        <Space>
          <SubmitButton form={form} />
        </Space>
      </Form.Item>
    </Form>
  );
};
