import { useCallback } from 'react';

import { Col, message, Spin } from 'antd';

import { AboutStateSelector } from '@modules/about/domain/store/selector';
import { updateCompany } from '@modules/about/domain/store/slice';
import { ModulesWrapper } from '@shared/components/ModulesWrapper/ModulesWrapper';
import { TiptapEditor } from '@shared/components/TiptapEditor/TiptapEditor';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';

const messageKey = 'about';

export const Tab: React.FC = () => {
  const { company, language, loading } = useAppSelector(AboutStateSelector);
  const [messageApi, contextHolder] = message.useMessage();

  const dispatch = useAppDispatch();

  const handleEditorUpdateFinish = useCallback(
    (value: string) => {
      messageApi.open({
        key: messageKey,
        type: 'loading',
        content: 'Загрузка..',
      });

      const updatedCompany: any = { ...company, content: value };

      dispatch(
        updateCompany({
          company: updatedCompany,
          lang: language,
        }),
      ).then(({ meta }) => {
        if (meta.requestStatus === 'fulfilled') {
          messageApi.open({
            key: messageKey,
            type: 'success',
            content: 'Данные успешно обновлены.',
            duration: 1,
          });
        } else {
          messageApi.open({
            key: messageKey,
            type: 'error',
            content: 'Произошла ошибка при обновлении данных.',
            duration: 1,
          });
        }
      });
    },
    [company],
  );

  return (
    <ModulesWrapper title="Описание">
      {contextHolder}
      <Col span={24} style={{ width: '100%' }}>
        <TiptapEditor
          initialValue={company?.content ?? ''}
          onSave={handleEditorUpdateFinish}
        />
      </Col>
      <Spin spinning={loading} fullscreen />
    </ModulesWrapper>
  );
};
