import {
  Button,
  Collapse,
  Flex,
  message,
  Popconfirm,
  Space,
  Typography,
} from 'antd';

import {
  DeleteOutlined,
  EditOutlined,
  FileAddOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { IInformation } from '@modules/information/domain/interface/interface';
import { InformationStateSelector } from '@modules/information/domain/store/selector';
import {
  changeCurrentInformation,
  deleteInformation,
} from '@modules/information/domain/store/slice';
import { useInformationTableColumns } from '@modules/information/services';
import { ModulesTitle } from '@shared/components/ModulesTitle/ModulesTitle';
import { ModulesWrapper } from '@shared/components/ModulesWrapper/ModulesWrapper';
import { PrimaryButton } from '@ui-kit/button';
import { TableCustom } from '@ui-kit/customTable';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import styles from './information.module.scss';

interface InformationBodyProps {
  showCreateModal: () => void;
  showEditModal: () => void;
  showPreviewModal: () => void;
}

export const InformationBody: React.FC<InformationBodyProps> = ({
  showCreateModal,
  showEditModal,
  showPreviewModal,
}) => {
  const { information, language } = useAppSelector(InformationStateSelector);
  const columns = useInformationTableColumns();

  const dispatch = useAppDispatch();

  const handleCreateButton = () => {
    dispatch(changeCurrentInformation(null));
    showCreateModal();
  };

  const handleUpdateButton = (info: IInformation) => {
    dispatch(changeCurrentInformation(info));
    showEditModal();
  };

  const handleDelete = async (id: number) => {
    dispatch(deleteInformation({ id, lang: language }));
  };

  const handlePreview = (record: IInformation) => {
    if (!record?.photo?.length) {
      message.info('Нет материала для данной новости.');
      return;
    }
    dispatch(changeCurrentInformation(record));
    showPreviewModal();
  };

  return (
    <ModulesWrapper
      header={
        <Flex justify="space-between" style={{ flexWrap: 'wrap' }}>
          <ModulesTitle fontSize={20}>Новости</ModulesTitle>
          <PrimaryButton
            icon={<FileAddOutlined />}
            onClick={handleCreateButton}
          >
            Создать новость
          </PrimaryButton>
        </Flex>
      }
    >
      <Space direction="vertical" style={{ width: '100%' }} size="middle">
        {information.map((item) => (
          <Collapse
            size="large"
            accordion
            key={item.id}
            collapsible="header"
            items={[
              {
                key: item.id,
                label: (
                  <Flex
                    justify="space-between"
                    align="center"
                    className={styles.informationBody}
                  >
                    <Typography style={{ fontWeight: 600 }}>
                      {item.title} - {item.disaster}
                    </Typography>
                    <Space onClick={(e) => e.stopPropagation()}>
                      <Button
                        icon={<EditOutlined style={{ color: '#8C8C8C' }} />}
                        onClick={(e) => handleUpdateButton(item)}
                        type="default"
                      />
                      <Popconfirm
                        title="Удалить данные."
                        description="Вы уверены, что хотите удалить эти данные?"
                        onConfirm={() => handleDelete(item.id)}
                        okText="Да"
                        cancelText="Нет"
                        icon={
                          <QuestionCircleOutlined style={{ color: 'red' }} />
                        }
                      >
                        <Button
                          danger
                          style={{ backgroundColor: '#FF4D4F' }}
                          icon={<DeleteOutlined style={{ color: '#FFF' }} />}
                        />
                      </Popconfirm>
                    </Space>
                  </Flex>
                ),
                children: (
                  <TableCustom
                    rowKey={(record) => record.id}
                    dataSource={[item]}
                    columns={columns as any}
                    actionProps={{
                      onHandleWatch: handlePreview,
                    }}
                  />
                ),
              },
            ]}
          />
        ))}
      </Space>
    </ModulesWrapper>
  );
};
