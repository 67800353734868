import { useEffect, useState } from 'react';

import { Image, Spin } from 'antd';

import { NewsApi } from '@modules/news/domain/store/api';
import { NewsStateSelector } from '@modules/news/domain/store/selector';
import { useUploadFiles } from '@shared/hooks/useUploadFiles/useUploadFilies';

import { useAppSelector } from '../../../store/hooks';

export const NewsImagePreview: React.FC = () => {
  const [files, setFiles] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const { currentNews } = useAppSelector(NewsStateSelector);

  const getImages = (images: any[]) => {
    setLoading(true);
    useUploadFiles(images, NewsApi.newsGetImage)
      .then((response) => {
        const urlFromBlob = response.map((item) =>
          URL.createObjectURL(item.data),
        );

        setFiles(urlFromBlob);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!currentNews) return;
    if (!currentNews.photo.length) return;

    getImages(currentNews.photo);
  }, [currentNews]);

  if (loading) {
    return <Spin spinning={loading} />;
  }

  return (
    <Image.PreviewGroup>
      {files.map((item) => (
        <Image key={item} src={item} width={200} height={200} />
      ))}
    </Image.PreviewGroup>
  );
};
