import { LoaderFunctionArgs, redirect } from 'react-router-dom';

import { auth } from './auth';

export const protectedLoader = ({ request }: LoaderFunctionArgs) => {
  if (!auth.token) {
    const params = new URLSearchParams();
    params.set('from', new URL(request.url).pathname);
    return redirect(`/login?${params.toString()}`);
  }

  return null;
};

export const loginLoader = () => {
  if (auth.token) {
    return redirect('/');
  }
  return null;
};
