import { useEffect, useState } from 'react';

import { Button, Flex, Form, Input, Select, Spin, Upload } from 'antd';

import { UploadOutlined } from '@ant-design/icons';
import { LayersStateSelector } from '@modules/layers/domain/store/selector';
import { convertCatalogImageToPath } from '@modules/naturalDisaster/domain/store/slice';
import { SubmitButton } from '@ui-kit/button';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getDistrict, getRegions } from '../domain/store/slice';

interface IInformationCreateFormProps {
  onSave: (value: any, id: number) => void;
  onCancel: () => void;
}

export const LayersEditForm: React.FC<IInformationCreateFormProps> = ({
  onSave,
  onCancel,
}) => {
  const [photoFileList, setPhotoFileList] = useState<any[]>([]);

  const { currentLayer, region, district, loading } =
    useAppSelector(LayersStateSelector);
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const valueRegion: number = Form.useWatch('region', form);

  const handlePhotoFileChange = ({ fileList }: any) => {
    setPhotoFileList(fileList);
    return fileList;
  };

  const onFinish = async (value: any) => {
    if (typeof value.file === 'string' && currentLayer) {
      onSave(value, currentLayer.id);
      form.resetFields();
      return;
    }

    const { payload } = await dispatch(
      convertCatalogImageToPath({ image: value.photo.file }),
    );

    if (currentLayer) {
      onSave({ ...value, file: payload }, currentLayer.id);
      form.resetFields();
    }
  };

  useEffect(() => {
    if (!currentLayer) return;
    if (currentLayer?.file) {
      setPhotoFileList([
        {
          uid: currentLayer.file,
          name: currentLayer.file,
        },
      ]);
    }
    form.setFieldsValue(currentLayer);
  }, [currentLayer]);

  useEffect(() => {
    if (valueRegion) {
      dispatch(getDistrict(valueRegion));
    }
  }, [valueRegion]);

  return (
    <Form
      onFinish={onFinish}
      form={form}
      name="LayersEditForm"
      layout="vertical"
    >
      <Form.Item name="region" label="Область" rules={[{ required: true }]}>
        <Select options={region} />
      </Form.Item>
      <Form.Item name="district" label="Район" rules={[{ required: true }]}>
        <Select options={district} />
      </Form.Item>
      <Form.Item name="title" label="Название" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item
        getValueFromEvent={handlePhotoFileChange}
        name="file"
        label="Обложка"
        rules={[{ required: true }]}
      >
        <Upload
          beforeUpload={() => false}
          multiple={false}
          name="file"
          fileList={photoFileList}
          accept="image/*"
          maxCount={1}
        >
          <Button icon={<UploadOutlined />}>Загрузить изображение</Button>
        </Upload>
      </Form.Item>
      <Form.Item name="value" label="Ссылка" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item>
        <Flex justify="space-between" align="center">
          <Button onClick={onCancel}>Отмена</Button>
          <div>
            <SubmitButton form={form} />
            {loading && <Spin style={{ marginLeft: 10 }} />}
          </div>
        </Flex>
      </Form.Item>
    </Form>
  );
};
