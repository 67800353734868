import { axiosInstance } from '@libs/http';
import { createCompany } from '@modules/about/domain/store/slice';
import {
  IDeleteInformationArgs,
  IGetAllInformation,
  IGetAllInformationArgs,
  IInformationState,
  IUpdateInformationArgs,
} from '@modules/information/domain/interface/interface';
import { InformationApi } from '@modules/information/domain/store/api';
import { InformationSliceConstants } from '@modules/information/domain/store/sliceConstants';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { useParams } from '@shared/hooks/useParams/useParams';
import dayjs from 'dayjs';

const initialState: IInformationState = {
  information: [],
  language: 'ru',
  districts: [],
  currentInformation: null,
  informationQueryParams: {
    page: 1,
    perPage: 10,
  },
  totalInformation: 0,
  languages: {
    ru: {},
    en: {},
    kg: {},
  },
};

export const getDistricts = createAsyncThunk(
  InformationSliceConstants.GetDistricts,
  async function (_, { rejectWithValue }) {
    try {
      const { data } = await axiosInstance.get<Awaited<string[]>>(
        InformationApi.districts,
      );

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getAllInformation = createAsyncThunk(
  InformationSliceConstants.GetAllInformation,
  async function (params: IGetAllInformationArgs, { rejectWithValue }) {
    try {
      const filteredParams = useParams(params);
      const { data } = await axiosInstance.get<Awaited<IGetAllInformation>>(
        `${InformationApi.information}${filteredParams}`,
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getInformation = createAsyncThunk(
  InformationSliceConstants.GetInformation,
  async function (
    { lang, code }: { lang: string; code: string },
    { rejectWithValue },
  ) {
    try {
      const { data } = await axiosInstance.get(
        `${InformationApi.information}/${code}?lang=${lang}&code=${code}`,
      );

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const convertImageToPath = createAsyncThunk(
  InformationSliceConstants.ConvertImageToPath,
  async ({ image }: { image: any }, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append('file', image);

      const { data } = await axiosInstance.post(
        InformationApi.informationUploadImages,
        formData,
      );

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const createInformation = createAsyncThunk(
  InformationSliceConstants.CreateInformation,
  async function (
    { information, lang }: { information: any; lang: string },
    { rejectWithValue, dispatch },
  ) {
    try {
      if (!information.ru.photo.file) {
        // eslint-disable-next-line guard-for-in,no-restricted-syntax
        for (const informationKey in information) {
          information[informationKey].photo = [];
        }

        const response = await axiosInstance.post(
          InformationApi.information,
          information,
        );

        dispatch(getAllInformation({ lang }));

        return response;
      }

      const images = information.ru.photo.fileList.map(
        (item: any) => item.originFileObj,
      );

      const responses = [];

      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const image in images) {
        // eslint-disable-next-line no-await-in-loop
        const { payload } = await dispatch(
          convertImageToPath({ image: images[image] }),
        );
        responses.push(payload.id);
      }

      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const infoKey in information) {
        information[infoKey].photo = responses;
      }
      const response = await axiosInstance.post(
        InformationApi.information,
        information,
      );

      dispatch(getAllInformation({ lang }));

      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const deleteInformation = createAsyncThunk(
  InformationSliceConstants.DeleteInformation,
  async function (
    { id, lang }: IDeleteInformationArgs,
    { rejectWithValue, dispatch, getState },
  ) {
    try {
      const { data } = await axiosInstance.delete(
        `${InformationApi.information}/${id}`,
      );

      dispatch(
        getAllInformation({
          lang,
        }),
      );

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const updateInformation = createAsyncThunk(
  InformationSliceConstants.UpdateInformation,
  async function (
    { information, lang }: IUpdateInformationArgs,
    { rejectWithValue, dispatch },
  ) {
    try {
      const { code, photo, ...withoutCode } = information;
      photo.forEach((item) => {
        if (!item.uid && item.id) {
          item.uid = item.id;
        }
      });

      const oldImages = photo.filter((item) => typeof item.uid === 'number');
      const newImages = photo.filter((item) => typeof item.uid !== 'number');

      const responseNewImage = await Promise.all(
        newImages.map((item) => {
          const formData = new FormData();

          formData.append('file', item.originFileObj);
          return axiosInstance.post(
            `${InformationApi.informationUploadImages}`,
            formData,
          );
        }),
      );

      const images = [
        ...oldImages.map((item) => item.uid),
        ...responseNewImage.map((item) => item.data.id),
      ];

      const data = await axiosInstance.patch(
        `${InformationApi.information}/${code}?code=${code}&lang=${lang}`,
        { ...withoutCode, photo: images },
      );
      dispatch(getAllInformation({ lang }));

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

const InformationSlice = createSlice({
  name: 'InformationSlice',
  initialState,
  reducers: {
    changeCurrentLanguage: (state, { payload }) => {
      state.language = payload;
    },
    changeCurrentInformation: (state, { payload }) => {
      state.currentInformation = payload;
    },
    changeInformationQueryParams: (state, { payload }) => {
      state.informationQueryParams = {
        ...state.informationQueryParams,
        ...payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getAllInformation.fulfilled,
      (state: IInformationState, { payload }) => {
        return {
          ...state,
          information: payload.items.map((item) => ({
            ...item,
            created: dayjs(item.created).format('YY-MM-DD  HH:mm'),
          })),
          totalInformation: payload.count,
        };
      },
    );
    builder.addCase(deleteInformation.fulfilled, (state: IInformationState) => {
      return {
        ...state,
      };
    });
    builder.addCase(createCompany.fulfilled, (state: IInformationState) => {
      return {
        ...state,
      };
    });
    builder.addCase(updateInformation.fulfilled, (state: IInformationState) => {
      return {
        ...state,
      };
    });
    builder.addCase(getDistricts.fulfilled, (state, { payload }) => {
      return {
        ...state,
        districts: payload,
      };
    });
    builder.addCase(getInformation.fulfilled, (state, { payload }) => {
      return {
        ...state,
        currentInformation: payload,
      };
    });
  },
});

export default InformationSlice.reducer;
export const {
  changeCurrentLanguage,
  changeCurrentInformation,
  changeInformationQueryParams,
} = InformationSlice.actions;
