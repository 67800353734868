import { useEffect } from 'react';

import { Form, Input } from 'antd';

import { NaturalDisasterStateSelector } from '@modules/naturalDisaster/domain/store/selector';
import { SubmitButton } from '@ui-kit/button';

import { useAppSelector } from '../../../store/hooks';

interface INaturalDisasterFormProps {
  onSave: (value: any) => void;
}

export const EditDisasterForm: React.FC<INaturalDisasterFormProps> = ({
  onSave,
}) => {
  const { currentDisaster } = useAppSelector(NaturalDisasterStateSelector);

  const [form] = Form.useForm();
  const onFinish = async (value: any) => {
    if (!currentDisaster) return;

    onSave({
      ...value,
      id: currentDisaster.id,
      photo: ' ',
    });

    form.resetFields();
  };

  useEffect(() => {
    if (!currentDisaster) return;

    form.setFieldsValue(currentDisaster);
  }, [currentDisaster]);

  return (
    <Form
      onFinish={onFinish}
      form={form}
      name="EditDisasterForm"
      layout="vertical"
    >
      <Form.Item name="name" label="Название" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item style={{ marginTop: 20 }}>
        <SubmitButton form={form} />
      </Form.Item>
    </Form>
  );
};
