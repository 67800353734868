import { useEffect } from 'react';

import { message, Pagination, Tabs } from 'antd';

import { NewsStateSelector } from '@modules/news/domain/store/selector';
import {
  changeCurrentLanguage,
  changeNewsQueryParams,
  createNews,
  getAllNews,
  getDistricts,
  updateNews,
} from '@modules/news/domain/store/slice';
import { NewsBody } from '@modules/news/ui/NewsBody';
import { NewsCreateForm } from '@modules/news/ui/NewsCreateForm';
import { NewsEditForm } from '@modules/news/ui/NewsEditForm';
import { NewsHeader } from '@modules/news/ui/NewsHeader';
import { NewsImagePreview } from '@modules/news/ui/NewsImagePreview';
import { CustomModalWrapper } from '@shared/components/CustomModalWrapper/CustomModalWrapper';
import { ModulesTitle } from '@shared/components/ModulesTitle/ModulesTitle';
import { useModal } from '@shared/hooks/useModal/useModal';
import { useTabs } from '@shared/hooks/useTabs/useTabs';
import { CustomModal } from '@ui-kit/customModal';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';

const createNewsMessageKey = 'createNews';
const updateNewsMessageKey = 'updateNews';

export const NewsPage: React.FC = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const { newsQueryParams: infoParams } = useAppSelector(NewsStateSelector);
  const tabItems = useTabs();

  const dispatch = useAppDispatch();
  const { language, districts, totalNews, newsQueryParams, languages } =
    useAppSelector(NewsStateSelector);

  const {
    open: openCreateNews,
    showModal: showCreateNews,
    closeModal: closeCreateNews,
  } = useModal();

  const {
    open: openUpdateNews,
    showModal: showUpdateNews,
    closeModal: closeUpdateNews,
  } = useModal();

  const {
    open: openPreviewNews,
    showModal: showPreviewNews,
    closeModal: closePreviewNews,
  } = useModal();

  const handleCreateFinish = (value: any) => {
    messageApi.open({
      key: createNewsMessageKey,
      type: 'loading',
      content: 'Загрузка..',
    });

    const newLanguages: any = {};
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const languagesKey in languages) {
      newLanguages[languagesKey] = value;
    }

    dispatch(createNews({ news: newLanguages, lang: language })).then(
      ({ meta }) => {
        if (meta.requestStatus === 'fulfilled') {
          messageApi.open({
            key: createNewsMessageKey,
            type: 'success',
            content: 'Данные успешно созданы.',
            duration: 1,
          });
        } else {
          messageApi.open({
            key: createNewsMessageKey,
            type: 'error',
            content: 'Ошибка при создании данных.',
            duration: 1,
          });
        }
      },
    );
    closeCreateNews();
  };

  const handleEditFinish = (value: any) => {
    messageApi.open({
      key: updateNewsMessageKey,
      type: 'loading',
      content: 'Загрузка..',
    });

    dispatch(updateNews({ news: value, lang: language })).then(({ meta }) => {
      if (meta.requestStatus === 'fulfilled') {
        messageApi.open({
          key: updateNewsMessageKey,
          type: 'success',
          content: 'Данные успешно обновлены.',
          duration: 1,
        });
      } else {
        messageApi.open({
          key: updateNewsMessageKey,
          type: 'error',
          content: 'Ошибка при обновлений данных.',
          duration: 1,
        });
      }
    });
    closeUpdateNews();
  };

  const handleChangeTab = (newLanguage: string) => {
    const params = { ...newsQueryParams, page: 1 };

    dispatch(changeCurrentLanguage(newLanguage));
    dispatch(
      getAllNews({
        lang: newLanguage,
        ...params,
      }),
    );
  };

  const handleChangePage = (page: number) => {
    dispatch(
      changeNewsQueryParams({
        page,
      }),
    );
  };

  useEffect(() => {
    if (!districts.length) {
      dispatch(getDistricts());
    }
    dispatch(getAllNews({ lang: language, ...newsQueryParams }));
  }, [newsQueryParams]);

  return (
    <>
      {contextHolder}
      <ModulesTitle>Новостная лента</ModulesTitle>
      <Tabs
        style={{ marginBottom: 32 }}
        activeKey={language}
        defaultActiveKey="ru"
        items={tabItems}
        onChange={handleChangeTab}
      />
      <NewsHeader />
      <NewsBody
        showPreviewModal={showPreviewNews}
        showCreateModal={showCreateNews}
        showEditModal={showUpdateNews}
      />
      <CustomModalWrapper
        handleChangeTab={handleChangeTab}
        modalTitle="Создание новости"
        tabs
        open={openCreateNews}
        onCancel={closeCreateNews}
        footer={null}
      >
        <NewsCreateForm
          onCancel={closeCreateNews}
          onSave={handleCreateFinish}
        />
      </CustomModalWrapper>
      <CustomModalWrapper
        modalTitle="Редактирование новости"
        open={openUpdateNews}
        tabs
        handleChangeTab={handleChangeTab}
        onCancel={closeUpdateNews}
        footer={null}
      >
        <NewsEditForm onCancel={closeUpdateNews} onSave={handleEditFinish} />
      </CustomModalWrapper>
      <CustomModal
        open={openPreviewNews}
        onCancel={closePreviewNews}
        footer={null}
      >
        <NewsImagePreview />
      </CustomModal>
      <Pagination
        onChange={handleChangePage}
        style={{ margin: 10 }}
        pageSize={infoParams.perPage}
        total={totalNews}
      />
    </>
  );
};
