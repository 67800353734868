import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';

import { Button, Col, Flex, Layout, Menu, Typography } from 'antd';

import { LogoutOutlined } from '@ant-design/icons';
import Logo from '@assets/images/logo.png';
import { auth } from '@modules/auth/ui/auth';
import { useWindowDimensions } from '@shared/hooks/useWindowDimesions/useWindowDimesions';

import { menu } from '../../../config/router/menu';

const { Sider, Content } = Layout;

export const AdminLayout: React.FC = () => {
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const currentActive = location.pathname;
  const { width } = useWindowDimensions();

  const items = menu.map((item) => ({
    key: item.path,
    label: item.title,
    icon: item.icon,
  }));

  const onClickMenu = (item: any) => {
    navigate(item.key);
  };

  const logoutHandler = () => {
    auth.logout();
    navigate('/login');
  };

  useEffect(() => {
    if (width <= 1200) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  }, [width]);

  return (
    <Layout>
      <Layout>
        <Sider trigger={null} width={250} collapsible collapsed={collapsed}>
          <Flex
            justify="space-between"
            vertical
            style={{ height: '100%', padding: '32px 10px 32px 10px' }}
          >
            <Col>
              {!collapsed && (
                <Flex
                  gap={8}
                  style={{ marginLeft: 24, marginBottom: 10 }}
                  align="center"
                >
                  <img src={Logo} width={32} height={32} alt="logo" />
                  <Typography
                    style={{
                      color: '#3181F8',
                      fontSize: 20,
                      fontWeight: '800',
                    }}
                  >
                    ЕСКМП
                  </Typography>
                </Flex>
              )}
              <Menu
                theme="dark"
                mode="inline"
                onClick={onClickMenu}
                selectedKeys={[currentActive]}
                defaultSelectedKeys={[currentActive]}
                items={items}
              />
            </Col>

            <div
              style={{
                paddingLeft: 24,
              }}
            >
              <Button
                type="text"
                style={{ color: 'rgb(255, 255, 255, 0.65)' }}
                icon={<LogoutOutlined />}
                onClick={logoutHandler}
              >
                {!collapsed && 'Выход'}
              </Button>
            </div>
          </Flex>
        </Sider>
        <Content
          style={{
            padding: 24,
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};
