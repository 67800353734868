import { useEffect, useState } from 'react';

import { Button, Col, Form, Input, Row, Space, Upload } from 'antd';

import { UploadOutlined } from '@ant-design/icons';
import { MaterialsStateSelector } from '@modules/materials/domain/store/selector';
import { SubmitButton } from '@ui-kit/button';

import { useAppSelector } from '../../../store/hooks';

interface IMaterialFormProps {
  onSave: (value: any) => void;
}

export const EditMaterialForm: React.FC<IMaterialFormProps> = ({ onSave }) => {
  const [currentFileList, setCurrentFileList] = useState<any[]>([]);
  const { currentMaterial } = useAppSelector(MaterialsStateSelector);
  const [form] = Form.useForm();

  const handleFileChange = ({ fileList }: any) => {
    setCurrentFileList(fileList);
    return fileList;
  };

  const onFinish = async (value: any) => {
    if (!currentMaterial) return;

    onSave({ ...value, code: currentMaterial.code });
    form.resetFields();
  };

  useEffect(() => {
    if (!currentMaterial) return;

    setCurrentFileList([
      {
        uid: currentMaterial.image,
        name: currentMaterial.image,
      },
    ]);
    form.setFieldsValue(currentMaterial);
  }, [currentMaterial]);

  return (
    <Form
      onFinish={onFinish}
      form={form}
      name="CreateMaterialForm"
      layout="vertical"
    >
      <Form.Item name="name" label="Название" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Row>
        <Col span={24}>
          <Form.Item
            name="link"
            label="Ссылка на документ"
            rules={[
              { required: true },
              { type: 'url', message: 'Пожалуйста, введите Ссылку' },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            getValueFromEvent={handleFileChange}
            name="image"
            label="Обложка"
            rules={[
              { required: true, message: 'Пожалуйста загрузите обложку.' },
            ]}
          >
            <Upload
              maxCount={1}
              beforeUpload={() => false}
              multiple={false}
              fileList={currentFileList}
              name="image"
              accept="image/*"
            >
              <Button icon={<UploadOutlined />}>Загрузить обложку</Button>
            </Upload>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Space>
          <SubmitButton form={form} />
        </Space>
      </Form.Item>
    </Form>
  );
};
