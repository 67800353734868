import { GetRowKey } from 'antd/es/table/interface';

import { JsonRecord } from '@app/types';

export function getKeyValue<RecordType extends JsonRecord>(
  record: RecordType,
  rowKey: string | GetRowKey<RecordType> | symbol | undefined | number,
): React.Key {
  let key;
  if (typeof rowKey === 'string') {
    key = record[rowKey];
  }
  if (typeof rowKey === 'function') {
    key = rowKey(record);
  }

  return key;
}
