import React, { useEffect, useState } from 'react';

import { Button, DatePicker, Flex, Form, Input, Select, Upload } from 'antd';

import { IInformation } from '@modules/information/domain/interface/interface';
import { InformationStateSelector } from '@modules/information/domain/store/selector';
import {
  getDistricts,
  getInformation,
} from '@modules/information/domain/store/slice';
import { useSelect } from '@modules/information/services/useSelect/useSelect';
import { SubmitButton } from '@ui-kit/button';
import dayjs from 'dayjs';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';

interface IInformationCreateFormProps {
  onSave: (value: any) => void;
  onCancel: () => void;
}

const { TextArea } = Input;
const { Dragger } = Upload;

export const InformationEditForm: React.FC<IInformationCreateFormProps> = ({
  onSave,
  onCancel,
}) => {
  const dispatch = useAppDispatch();
  const { currentInformation, language } = useAppSelector(
    InformationStateSelector,
  );
  const [fileList, setFileList] = useState<any[]>([]);

  const { filterDistricts, selectMapper } = useSelect();

  const [form] = Form.useForm();

  const onFinish = (value: any) => {
    if (!currentInformation) return;

    onSave({ ...value, code: currentInformation.code });
    form.resetFields();
  };

  const onChangeCurrentInformation = (information: IInformation) => {
    const files = information.photo.map((item) => ({
      uid: item.id,
      name: item.name,
    }));

    form.setFieldsValue(information);
    setFileList(files);
  };

  const handleFileChange = ({ fileList: files }: any) => {
    setFileList(files);
    return files;
  };

  useEffect(() => {
    dispatch(getDistricts());
  }, []);

  useEffect(() => {
    if (!currentInformation) return;

    dispatch(getInformation({ lang: language, code: currentInformation.code }));
  }, [language]);

  useEffect(() => {
    if (!currentInformation) return;

    onChangeCurrentInformation({
      ...currentInformation,
      date:
        currentInformation.date !== null
          ? dayjs(currentInformation.date)
          : null,
    });
  }, [currentInformation]);

  return (
    <Form
      onFinish={onFinish}
      form={form}
      name="informationEditForm"
      layout="vertical"
    >
      <Form.Item name="date" label="Дата">
        <DatePicker style={{ width: '100%' }} />
      </Form.Item>
      <Form.Item name="title" label="Название" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item name="content" label="Содержание" rules={[{ required: true }]}>
        <TextArea rows={4} />
      </Form.Item>
      <Form.Item name="district" label="Район" rules={[{ required: true }]}>
        <Select
          showSearch
          placeholder="Выберите район"
          optionFilterProp="children"
          allowClear
          filterOption={filterDistricts}
          options={selectMapper}
        />
      </Form.Item>
      <Form.Item
        name="disaster"
        label="Происшествие"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="photo"
        getValueFromEvent={handleFileChange}
        valuePropName="photo"
      >
        <Dragger
          {...{
            name: 'file',
            multiple: true,
            beforeUpload: () => false,
            fileList: [...fileList],
          }}
        >
          <div className="flex justify-center items-center gap-[11px] h-[88px]">
            <p className="ant-upload-hint">
              Перетащите файлы, чтобы прикрепить их или выберите
            </p>
          </div>
        </Dragger>
      </Form.Item>
      <Form.Item>
        <Flex justify="space-between" align="center">
          <Button onClick={onCancel}>Отмена</Button>
          <SubmitButton form={form} />
        </Flex>
      </Form.Item>
    </Form>
  );
};
