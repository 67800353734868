import { JsonRecord } from '@app/types';
import { TableRendererProps } from '@ui-kit/customTable/types';
import dayjs from 'dayjs';
import get from 'lodash/get';

export function renderDate<RecordType extends JsonRecord>({
  record,
  customColumn,
}: TableRendererProps<RecordType>) {
  const value = get(record, customColumn.dataIndex);
  if (value) {
    return <div>{dayjs(value).format('YYYY-MM-DD')}</div>;
  }
  return <div />;
}
