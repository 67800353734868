import { Form, Input, Space } from 'antd';

import { SubmitButton } from '@ui-kit/button';

interface INaturalDisasterFormProps {
  onSave: (value: any) => void;
}

export const CreateDisasterForm: React.FC<INaturalDisasterFormProps> = ({
  onSave,
}) => {
  const [form] = Form.useForm();

  const onFinish = async (value: any) => {
    onSave({ name: value.name, photo: ' ' });
    form.resetFields();
  };

  return (
    <Form
      onFinish={onFinish}
      form={form}
      name="CreateDisasterForm"
      layout="vertical"
    >
      <Form.Item name="name" label="Название" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item style={{ marginTop: 20 }}>
        <Space>
          <SubmitButton form={form} />
        </Space>
      </Form.Item>
    </Form>
  );
};
