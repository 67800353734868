import React from 'react';

import { Layout, Spin } from 'antd';

import styles from './Loading.module.css';

export const Loading: React.FC = () => {
  return (
    <Layout className={styles.layout}>
      <Spin size="large" />
    </Layout>
  );
};
