export const InformationSliceConstants = {
  GetAllInformation: 'getAllInformation',
  GetInformationImages: 'getInformationImages',
  CreateInformation: 'createInformation',
  DeleteInformation: 'deleteInformation',
  UpdateInformation: 'updateInformation',
  GetDistricts: 'getDistricts',
  GetInformation: 'getInformation',
  ConvertImageToPath: 'ConvertImageToPath',
};
