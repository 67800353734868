export const NewsSliceConstants = {
  GetAllNews: 'getAllNews',
  GetNewsImages: 'getNewsImages',
  CreateNews: 'createNews',
  DeleteNews: 'deleteNews',
  UpdateNews: 'updateNews',
  GetDistricts: 'getDistricts',
  GetNews: 'getNews',
  ConvertImageToPath: 'ConvertImageToPath',
};
