import { PropsWithChildren } from 'react';

import { Button, ButtonProps } from 'antd';

import styles from './Button.module.scss';

type PrimaryButtonProps = PropsWithChildren & ButtonProps;

export const PrimaryButton: React.FC<PrimaryButtonProps> = ({
  children,
  ...otherProps
}) => {
  return (
    <Button {...otherProps} className={styles.primaryButton}>
      {children}
    </Button>
  );
};
