import React from 'react';

import { JsonRecord } from '@app/types';
import { renderDate } from '@ui-kit/customTable/cell/renderer/renderDate';
import { renderTag } from '@ui-kit/customTable/cell/renderer/renderTag';
import {
  CustomTableColumnType,
  TableRendererProps,
} from '@ui-kit/customTable/types';

import { renderAction } from './renderAction';
import { renderString } from './renderString';

export class RendererFactory {
  static createRenderer<RecordType extends JsonRecord>(
    props: TableRendererProps<RecordType>,
  ): React.ReactNode {
    const { xtype } = props.customColumn;
    let renderer = renderString;

    switch (xtype) {
      case CustomTableColumnType.ACTION_COLUMN:
        renderer = renderAction;
        break;
      case CustomTableColumnType.STRING:
        renderer = renderString;
        break;
      case CustomTableColumnType.DATE:
        renderer = renderDate;
        break;
      case CustomTableColumnType.TAG:
        renderer = renderTag;
        break;
      default:
        break;
    }

    return renderer(props);
  }
}
