import {
  CustomTableAction,
  CustomTableColumnType,
} from '@ui-kit/customTable/types';

export const layersTableColumns = [
  {
    title: 'Область',
    dataIndex: 'region',
    key: 'region',
    align: 'center',
    xtype: CustomTableColumnType.STRING,
  },
  {
    title: 'Район',
    dataIndex: 'district',
    key: 'district',
    align: 'center',
    xtype: CustomTableColumnType.STRING,
  },
  {
    title: 'Название',
    dataIndex: 'title',
    key: 'title',
    align: 'center',
    xtype: CustomTableColumnType.STRING,
  },
  {
    title: 'Ссылка',
    dataIndex: 'value',
    key: 'value',
    align: 'center',
    xtype: CustomTableColumnType.STRING,
  },
  {
    title: 'Действие',
    key: 'action',
    align: 'center',
    xtype: CustomTableColumnType.ACTION_COLUMN,
    actions: [CustomTableAction.DELETE, CustomTableAction.EDIT],
  },
];
