import { useEffect } from 'react';

import { Button, Flex, message, Tabs } from 'antd';

import { FileAddOutlined } from '@ant-design/icons';
import { UsefulResourcesStateSelector } from '@modules/usefulResources/domain/store/selector';
import {
  changeCurrentLanguage,
  changeCurrentUsefulResource,
  createUsefulResource,
  getAllUnpopularUsefulResources,
  getUsefulResource,
  updateResource,
} from '@modules/usefulResources/domain/store/slice';
import { UsefulResourcesBody } from '@modules/usefulResources/ui/UsefulResourcesBody';
import { UsefulResourcesCreateForm } from '@modules/usefulResources/ui/UsefulResourcesCreateForm';
import { UsefulResourcesEditForm } from '@modules/usefulResources/ui/UsefulResourcesEditForm';
import { CustomModalWrapper } from '@shared/components/CustomModalWrapper/CustomModalWrapper';
import { ModulesTitle } from '@shared/components/ModulesTitle/ModulesTitle';
import { ModulesWrapper } from '@shared/components/ModulesWrapper/ModulesWrapper';
import { useModal } from '@shared/hooks/useModal/useModal';
import { useTabs } from '@shared/hooks/useTabs/useTabs';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';

export const UsefulResourcesPage: React.FC = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useAppDispatch();

  const usefulResourceCreateMessageKey = 'createResource';
  const usefulResourceUpdateMessageKey = 'updateResource';

  const { language, currentUsefulResource, languages } = useAppSelector(
    UsefulResourcesStateSelector,
  );

  const {
    open: openCreateModal,
    closeModal: closeCreateModal,
    showModal: showCreateModal,
  } = useModal();

  const {
    open: openEditModal,
    closeModal: closeEditModal,
    showModal: showEditModal,
  } = useModal();

  const tabItems = useTabs();

  const handleCreateButton = () => {
    dispatch(changeCurrentUsefulResource(null));
    showCreateModal();
  };

  const handleCreateFinish = (value: any) => {
    messageApi.open({
      key: usefulResourceCreateMessageKey,
      type: 'loading',
      content: 'Загрузка..',
    });

    const newLanguages: any = {};
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const languagesKey in languages) {
      newLanguages[languagesKey] = value;
    }

    dispatch(
      createUsefulResource({ lang: language, resource: newLanguages }),
    ).then(({ meta }) => {
      if (meta.requestStatus === 'fulfilled') {
        messageApi.open({
          key: usefulResourceCreateMessageKey,
          type: 'success',
          content: 'Данные успешно созданы.',
          duration: 1,
        });
      } else {
        messageApi.open({
          key: usefulResourceCreateMessageKey,
          type: 'error',
          content: 'Ошибка при создании данных.',
          duration: 1,
        });
      }
    });
    closeCreateModal();
  };

  const handleEditFinish = (value: any) => {
    messageApi.open({
      key: usefulResourceUpdateMessageKey,
      type: 'loading',
      content: 'Загрузка..',
    });

    dispatch(
      updateResource({
        resource: { ...value, id: currentUsefulResource?.id },
        lang: language,
      }),
    ).then(({ meta }) => {
      if (meta.requestStatus === 'fulfilled') {
        messageApi.open({
          key: usefulResourceUpdateMessageKey,
          type: 'success',
          content: 'Данные успешно обновлены.',
          duration: 1,
        });
      } else {
        messageApi.open({
          key: usefulResourceUpdateMessageKey,
          type: 'error',
          content: 'Ошибка при обновлении данных.',
          duration: 1,
        });
      }
    });
    closeEditModal();
  };

  const handleChangeTab = (key: string) => {
    dispatch(changeCurrentLanguage(key));
    dispatch(getAllUnpopularUsefulResources(key));
    if (currentUsefulResource)
      dispatch(
        getUsefulResource({ lang: key, code: currentUsefulResource.code }),
      );
  };

  useEffect(() => {
    dispatch(getAllUnpopularUsefulResources(language));
  }, []);

  return (
    <>
      {contextHolder}
      <ModulesTitle>Ссылки</ModulesTitle>
      <Tabs
        style={{ marginBottom: 32 }}
        defaultActiveKey="ru"
        activeKey={language}
        items={tabItems}
        onChange={handleChangeTab}
      />
      <ModulesWrapper
        header={
          <Flex justify="space-between" style={{ flexWrap: 'wrap' }}>
            <ModulesTitle fontSize={20}>Ссылки</ModulesTitle>
            <Button
              icon={<FileAddOutlined />}
              onClick={handleCreateButton}
              type="primary"
            >
              Создать ресурс
            </Button>
          </Flex>
        }
      >
        <UsefulResourcesBody showModal={showEditModal} />
      </ModulesWrapper>
      <CustomModalWrapper
        handleChangeTab={handleChangeTab}
        activeTabsKey={language}
        modalTitle="Создание ресурса"
        tabs
        open={openCreateModal}
        onCancel={closeCreateModal}
        footer={null}
      >
        <UsefulResourcesCreateForm onSave={handleCreateFinish} />
      </CustomModalWrapper>
      <CustomModalWrapper
        destroyOnClose
        modalTitle="Редактирование ресурса"
        open={openEditModal}
        tabs
        handleChangeTab={handleChangeTab}
        activeTabsKey={language}
        onCancel={closeEditModal}
        footer={null}
      >
        <UsefulResourcesEditForm onSave={handleEditFinish} />
      </CustomModalWrapper>
    </>
  );
};
