import { SyntheticEvent } from 'react';
import { Link } from 'react-router-dom';

import { Button, Col, Popconfirm, Row } from 'antd';

import {
  DeleteOutlined,
  EditOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { JsonRecord } from '@app/types';
import { DefaultButton, PrimaryButton } from '@ui-kit/buttons';
import {
  CustomTableAction,
  TableRendererProps,
} from '@ui-kit/customTable/types';

export function renderAction<RecordType extends JsonRecord>({
  customColumn,
  record,
  actionProps: {
    onHandleEdit,
    onHandleDelete,
    onHandleDownload,
    onHandleWatch,
  },
}: TableRendererProps<RecordType>) {
  const { actions = [] } = customColumn;

  const onClickStopPropagation = (callback?: () => void) => {
    return (e: SyntheticEvent) => {
      e.stopPropagation();
      callback?.();
    };
  };

  return (
    <Row
      className="actions-wrapper"
      justify="center"
      align="middle"
      wrap={false}
      gutter={8}
    >
      {actions.map((action) => {
        if (action === CustomTableAction.ISREADONLY) {
          if (!record.isView) {
            return (
              <Col key={action}>
                <Popconfirm
                  title="Удалить данные."
                  description="Вы уверены, что хотите эти данные?"
                  onConfirm={() => onHandleDelete && onHandleDelete(record)}
                  okText="Да"
                  cancelText="Нет"
                  icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                >
                  <Button
                    danger
                    style={{ backgroundColor: '#FF4D4F' }}
                    icon={<DeleteOutlined style={{ color: '#FFF' }} />}
                  />
                </Popconfirm>
              </Col>
            );
          }

          return null;
        }
        if (action === CustomTableAction.EDIT) {
          return (
            <Col key={action}>
              <DefaultButton
                icon={<EditOutlined />}
                onClick={() =>
                  onHandleEdit && onClickStopPropagation(onHandleEdit(record)!)
                }
              />
            </Col>
          );
        }
        if (action === CustomTableAction.LINK) {
          return (
            <Col key={action}>
              <Link to={record.link} target="_blank">
                {record.name}
              </Link>
            </Col>
          );
        }
        if (action === CustomTableAction.WATCH) {
          return (
            <Col key={action}>
              <DefaultButton
                onClick={() =>
                  onHandleWatch &&
                  onClickStopPropagation(onHandleWatch(record)!)
                }
              >
                Предпросмотр
              </DefaultButton>
            </Col>
          );
        }
        if (action === CustomTableAction.DELETE) {
          return (
            <Col key={action}>
              <Popconfirm
                title="Удалить данные."
                description="Вы уверены, что хотите эти данные?"
                onConfirm={() => onHandleDelete && onHandleDelete(record)}
                okText="Да"
                cancelText="Нет"
                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              >
                <Button
                  danger
                  style={{ backgroundColor: '#FF4D4F' }}
                  icon={<DeleteOutlined style={{ color: '#FFF' }} />}
                />
              </Popconfirm>
            </Col>
          );
        }
        if (action === CustomTableAction.DOWNLOAD) {
          return (
            <Col key={action}>
              {record?.[customColumn.dataIndex] && (
                <PrimaryButton
                  onClick={() =>
                    onHandleDownload &&
                    onClickStopPropagation(
                      onHandleDownload(record, customColumn.dataIndex)!,
                    )
                  }
                >
                  {record?.[customColumn.dataIndex]?.originalname}
                </PrimaryButton>
              )}
            </Col>
          );
        }
        return null;
      })}
    </Row>
  );
}
