import { useEffect } from 'react';

import { Tabs } from 'antd';

import { NaturalDisasterStateSelector } from '@modules/naturalDisaster/domain/store/selector';
import {
  changeCurrentLanguage,
  getAllCatalogs,
  getAllGroups,
  getGroup,
} from '@modules/naturalDisaster/domain/store/slice';
import { CatalogBody } from '@modules/naturalDisaster/ui/CatalogBody';
import { DisasterPreview } from '@modules/naturalDisaster/ui/CatalogPreview';
import { DisasterFormActions } from '@modules/naturalDisaster/ui/DisasterFormActions';
import { TypeDisasterFormActions } from '@modules/naturalDisaster/ui/TypeDisasterFormActions';
import { ModulesTitle } from '@shared/components/ModulesTitle/ModulesTitle';
import { useModal } from '@shared/hooks/useModal/useModal';
import { useTabs } from '@shared/hooks/useTabs/useTabs';
import { CustomModal } from '@ui-kit/customModal';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';

export const NaturalDisaster: React.FC = () => {
  const dispatch = useAppDispatch();

  const { currentTabLang, currentDisaster } = useAppSelector(
    NaturalDisasterStateSelector,
  );
  const tabItems = useTabs();

  const {
    open: openDisasterPreview,
    closeModal: closeDisasterPreview,
    showModal: showDisasterPreview,
  } = useModal();

  const {
    open: openDisasterCreate,
    closeModal: closeDisasterCreate,
    showModal: showDisasterCreate,
  } = useModal();

  const {
    open: openDisasterEdit,
    closeModal: closeDisasterEdit,
    showModal: showDisasterEdit,
  } = useModal();

  const {
    open: openTypeDisasterCreate,
    closeModal: closeTypeDisasterCreate,
    showModal: showTypeDisasterCreate,
  } = useModal();

  const {
    open: openTypeDisasterEdit,
    closeModal: closeTypeDisasterEdit,
    showModal: showTypeDisasterEdit,
  } = useModal();

  const handleChangeTab = (key: string) => {
    dispatch(changeCurrentLanguage(key));
    dispatch(getAllGroups(key));
    dispatch(getAllCatalogs(key));
    if (currentDisaster)
      dispatch(getGroup({ lang: key, code: currentDisaster.code }));
  };

  useEffect(() => {
    dispatch(getAllGroups(currentTabLang));
    dispatch(getAllCatalogs(currentTabLang));
  }, []);

  return (
    <>
      <ModulesTitle>Стихийные бедствия</ModulesTitle>
      <Tabs
        style={{ marginBottom: 32 }}
        activeKey={currentTabLang}
        defaultActiveKey="ru"
        items={tabItems}
        onChange={handleChangeTab}
      />
      <CatalogBody
        showDisasterCreateModal={showDisasterCreate}
        showDisasterEditModal={showDisasterEdit}
        showTypeDisasterCreateModal={showTypeDisasterCreate}
        showTypeDisasterEditModal={showTypeDisasterEdit}
        showPreview={showDisasterPreview}
      />
      <DisasterFormActions
        handleChangeTab={handleChangeTab}
        closeDisasterCreate={closeDisasterCreate}
        closeDisasterEdit={closeDisasterEdit}
        openDisasterCreate={openDisasterCreate}
        openDisasterEdit={openDisasterEdit}
      />
      <TypeDisasterFormActions
        closeTypeDisasterCreate={closeTypeDisasterCreate}
        closeTypeDisasterEdit={closeTypeDisasterEdit}
        openTypeDisasterCreate={openTypeDisasterCreate}
        openTypeDisasterEdit={openTypeDisasterEdit}
      />
      <CustomModal
        open={openDisasterPreview}
        onCancel={closeDisasterPreview}
        footer={null}
        width="80%"
      >
        <DisasterPreview />
      </CustomModal>
    </>
  );
};
