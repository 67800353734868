import { axiosInstance } from '@libs/http/axiosInstance';
import { AuthState, ILogin } from '@modules/auth/domain/interface/authState';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { AuthApi } from './authApi';
import { AuthSliceConstants } from './authSliceConstants';

const initialState: AuthState = {
  token: null,
};

export const authLogin = createAsyncThunk(
  AuthSliceConstants.Login,
  async function ({ username, password }: ILogin, { rejectWithValue }) {
    try {
      const { data } = await axiosInstance.post(AuthApi.login, {
        username,
        password,
      });

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

const AuthSlice = createSlice({
  name: 'AuthSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      authLogin.fulfilled,
      (state: AuthState, { payload: { token } }) => {
        return {
          ...state,
          token,
        };
      },
    );
    builder.addCase(authLogin.rejected, (state: AuthState) => {
      return {
        ...state,
        token: null,
      };
    });
  },
});

export default AuthSlice.reducer;
