import {
  CustomTableAction,
  CustomTableColumnType,
} from '@ui-kit/customTable/types';

export const useContactsTableColumns = () => {
  const columns = [
    {
      title: 'Данные',
      dataIndex: 'title',
      key: 'title',
      align: 'center',
      xtype: CustomTableColumnType.STRING,
    },
    {
      title: 'Значение',
      dataIndex: 'content',
      key: 'content',
      align: 'center',
      xtype: CustomTableColumnType.STRING,
    },
    {
      title: 'Действия',
      key: 'action',
      align: 'center',
      xtype: CustomTableColumnType.ACTION_COLUMN,
      actions: [CustomTableAction.DELETE, CustomTableAction.EDIT],
    },
  ];

  return columns;
};
