import { axiosInstance } from '@libs/http';
import { createCompany } from '@modules/about/domain/store/slice';
import {
  IDeleteNewsArgs,
  IGetAllNews,
  IGetAllNewsArgs,
  INewsState,
  IUpdateNewsArgs,
} from '@modules/news/domain/interface/interface';
import { NewsApi } from '@modules/news/domain/store/api';
import { NewsSliceConstants } from '@modules/news/domain/store/sliceConstants';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { useParams } from '@shared/hooks/useParams/useParams';
import dayjs from 'dayjs';

const initialState: INewsState = {
  news: [],
  language: 'ru',
  districts: [],
  currentNews: null,
  newsQueryParams: {
    page: 1,
    perPage: 10,
  },
  totalNews: 0,
  languages: {
    ru: {},
    en: {},
    kg: {},
  },
};

export const getDistricts = createAsyncThunk(
  NewsSliceConstants.GetDistricts,
  async function (_, { rejectWithValue }) {
    try {
      const { data } = await axiosInstance.get<Awaited<string[]>>(
        NewsApi.districts,
      );

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getAllNews = createAsyncThunk(
  NewsSliceConstants.GetAllNews,
  async function (params: IGetAllNewsArgs, { rejectWithValue }) {
    try {
      const filteredParams = useParams(params);
      const { data } = await axiosInstance.get<Awaited<IGetAllNews>>(
        `${NewsApi.news}${filteredParams}`,
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getNews = createAsyncThunk(
  NewsSliceConstants.GetNews,
  async function (
    { lang, code }: { lang: string; code: string },
    { rejectWithValue },
  ) {
    try {
      const { data } = await axiosInstance.get(
        `${NewsApi.news}/${code}?lang=${lang}&code=${code}`,
      );

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const convertImageToPath = createAsyncThunk(
  NewsSliceConstants.ConvertImageToPath,
  async ({ image }: { image: any }, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append('file', image);

      const { data } = await axiosInstance.post(
        NewsApi.newsUploadImages,
        formData,
      );

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const createNews = createAsyncThunk(
  NewsSliceConstants.CreateNews,
  async function (
    { news, lang }: { news: any; lang: string },
    { rejectWithValue, dispatch },
  ) {
    try {
      if (!news.ru.photo.file) {
        // eslint-disable-next-line guard-for-in,no-restricted-syntax
        for (const newsKey in news) {
          news[newsKey].photo = [];
        }

        const response = await axiosInstance.post(
          NewsApi.news,
          news,
        );

        dispatch(getAllNews({ lang }));

        return response;
      }

      const images = news.ru.photo.fileList.map(
        (item: any) => item.originFileObj,
      );

      const responses = [];

      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const image in images) {
        // eslint-disable-next-line no-await-in-loop
        const { payload } = await dispatch(
          convertImageToPath({ image: images[image] }),
        );
        responses.push(payload.id);
      }

      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const infoKey in news) {
        news[infoKey].photo = responses;
      }
      const response = await axiosInstance.post(
        NewsApi.news,
        news,
      );

      dispatch(getAllNews({ lang }));

      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const deleteNews = createAsyncThunk(
  NewsSliceConstants.DeleteNews,
  async function (
    { id, lang }: IDeleteNewsArgs,
    { rejectWithValue, dispatch, getState },
  ) {
    try {
      const { data } = await axiosInstance.delete(
        `${NewsApi.news}/${id}`,
      );

      dispatch(
        getAllNews({
          lang,
        }),
      );

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const updateNews = createAsyncThunk(
  NewsSliceConstants.UpdateNews,
  async function (
    { news, lang }: IUpdateNewsArgs,
    { rejectWithValue, dispatch },
  ) {
    try {
      const { code, photo, ...withoutCode } = news;
      photo.forEach((item) => {
        if (!item.uid && item.id) {
          item.uid = item.id;
        }
      });

      const oldImages = photo.filter((item) => typeof item.uid === 'number');
      const newImages = photo.filter((item) => typeof item.uid !== 'number');

      const responseNewImage = await Promise.all(
        newImages.map((item) => {
          const formData = new FormData();

          formData.append('file', item.originFileObj);
          return axiosInstance.post(
            `${NewsApi.newsUploadImages}`,
            formData,
          );
        }),
      );

      const images = [
        ...oldImages.map((item) => item.uid),
        ...responseNewImage.map((item) => item.data.id),
      ];

      const data = await axiosInstance.patch(
        `${NewsApi.news}/${code}?code=${code}&lang=${lang}`,
        { ...withoutCode, photo: images },
      );
      dispatch(getAllNews({ lang }));

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

const NewsSlice = createSlice({
  name: 'NewsSlice',
  initialState,
  reducers: {
    changeCurrentLanguage: (state, { payload }) => {
      state.language = payload;
    },
    changeCurrentNews: (state, { payload }) => {
      state.currentNews = payload;
    },
    changeNewsQueryParams: (state, { payload }) => {
      state.newsQueryParams = {
        ...state.newsQueryParams,
        ...payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getAllNews.fulfilled,
      (state: INewsState, { payload }) => {
        return {
          ...state,
          news: payload.items.map((item) => ({
            ...item,
            created: dayjs(item.created).format('YY-MM-DD  HH:mm'),
          })),
          totalNews: payload.count,
        };
      },
    );
    builder.addCase(deleteNews.fulfilled, (state: INewsState) => {
      return {
        ...state,
      };
    });
    builder.addCase(createCompany.fulfilled, (state: INewsState) => {
      return {
        ...state,
      };
    });
    builder.addCase(updateNews.fulfilled, (state: INewsState) => {
      return {
        ...state,
      };
    });
    builder.addCase(getDistricts.fulfilled, (state, { payload }) => {
      return {
        ...state,
        districts: payload,
      };
    });
    builder.addCase(getNews.fulfilled, (state, { payload }) => {
      return {
        ...state,
        currentNews: payload,
      };
    });
  },
});

export default NewsSlice.reducer;
export const {
  changeCurrentLanguage,
  changeCurrentNews,
  changeNewsQueryParams,
} = NewsSlice.actions;
