import { TabsProps } from 'antd';

export const useTabs = () => {
  const items: TabsProps['items'] = [
    {
      key: 'ru',
      label: 'Русский',
    },
    {
      key: 'en',
      label: 'English',
    },
    {
      key: 'kg',
      label: 'Кыргызча',
    },
  ];

  return items;
};
