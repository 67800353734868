import { axiosInstance } from '@libs/http';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { useParams } from '@shared/hooks/useParams/useParams';
import dayjs from 'dayjs';

import {
  ISupportMessage,
  ISupportMessageRequest,
  ISupportMessageState,
} from '../interface/interface';
import { SupportMessageApi } from './api';
import { SupportSliceConstants } from './sliceConstants';

const initialState: ISupportMessageState = {
  supportMessage: [],
  loading: false,
  supportMessageCount: 0,
  currentSupportTab: 'false',
  error: null,
  perPage: 5,
  page: 1,
};

export const getAllSupportMessages = createAsyncThunk(
  SupportSliceConstants.GetAllSupportMessages,
  async (
    messagesParams: {
      archived: string;
      page: number;
      perPage: number;
    },
    { rejectWithValue },
  ) => {
    try {
      const params = useParams({
        ...messagesParams,
        isArchived: messagesParams.archived,
      });

      const { data } = await axiosInstance.get<Awaited<ISupportMessageRequest>>(
        `${SupportMessageApi.support}${params}`,
      );

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const archiveSupport = createAsyncThunk(
  SupportSliceConstants.ArchiveSupport,
  async function (
    {
      id,
      archived,
      perPage,
      page,
    }: { id: number; archived: string; perPage: number; page: number },
    { rejectWithValue, dispatch },
  ) {
    try {
      const { data } = await axiosInstance.patch(
        `${SupportMessageApi.archiveSupport}/${id}`,
        { archived: true },
      );

      dispatch(getAllSupportMessages({ archived, perPage, page }));

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

const SupportMessageSlice = createSlice({
  name: 'SupportMessageSlice',
  initialState,
  reducers: {
    changeCurrentPage: (state, { payload }) => {
      state.page = payload;
    },
    changeCurrentTab: (state, { payload }) => {
      state.currentSupportTab = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getAllSupportMessages.fulfilled,
      (state: ISupportMessageState, { payload }): ISupportMessageState => {
        return {
          ...state,
          supportMessage: payload?.content?.map((item: ISupportMessage) => ({
            ...item,
            created: dayjs(item.created)
              .locale('ru')
              .format('D MMMM YYYY HH:mm'),
          })),
          loading: false,
          supportMessageCount: payload.count,
          error: null,
        };
      },
    );
    builder.addCase(
      getAllSupportMessages.pending,
      (state: ISupportMessageState): ISupportMessageState => {
        return {
          ...state,
          loading: true,
          error: null,
        };
      },
    );
    builder.addCase(
      getAllSupportMessages.rejected,
      (state: ISupportMessageState, action: any) => {
        return {
          ...state,
          loading: false,
          supportMessage: [],
          supportMessageCount: 0,
          error: action.payload,
        };
      },
    );
  },
});

export const { changeCurrentPage, changeCurrentTab } =
  SupportMessageSlice.actions;
export default SupportMessageSlice.reducer;
