import React from 'react';

import {
  CustomTableColumnType,
  TableEditorProps,
} from '@ui-kit/customTable/types';

import { EditString } from './EditString';

export class EditorFactory {
  static createEditor({
    customColumn,
    record,
    editValidator,
    actionProps,
  }: TableEditorProps): React.ReactNode {
    const { xtype } = customColumn;
    let Editor: React.FC<TableEditorProps> = EditString;

    switch (xtype) {
      case CustomTableColumnType.STRING:
        Editor = EditString;
        break;
      default:
        break;
    }

    return (
      <Editor
        customColumn={customColumn}
        record={record}
        editValidator={editValidator}
        actionProps={actionProps}
      />
    );
  }
}
