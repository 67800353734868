import { useMemo } from 'react';

import { InformationStateSelector } from '@modules/information/domain/store/selector';

import { useAppSelector } from '../../../../store/hooks';

export const useSelect = () => {
  const { districts } = useAppSelector(InformationStateSelector);

  const filterDistricts = (
    input: string,
    option?: { label: string; value: string },
  ) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const selectItems = useMemo(() => {
    return districts.map((item) => ({
      value: item,
      label: item,
    }));
  }, [districts]);

  return {
    selectMapper: selectItems,
    filterDistricts,
    selectMapperWithoutAll: selectItems,
  };
};
