export const MaterialsSliceConstants = {
  GetAllMaterials: 'getAllMaterials',
  GetAllMaterialsInfo: 'getAllMaterialsInfo',
  GetAllMaterialsGroup: 'getAllMaterialsGroup',
  CreateMaterial: 'createMaterial',
  DeleteMaterial: 'deleteMaterial',
  UpdateMaterial: 'updateMaterial',
  CreateGroup: 'createGroup',
  DeleteGroup: 'deleteGroup',
  UpdateGroup: 'updateGroup',
  CreateMaterialInfo: 'createMaterialInfo',
  DeleteMaterialInfo: 'deleteMaterialInfo',
  UpdateMaterialInfo: 'updateMaterialInfo',
  ActivateMaterialInfo: 'activateMaterialInfo',
  UploadMaterialImage: 'uploadMaterialImage',
  GetGroup: 'getGroup',
};
