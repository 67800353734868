// eslint-disable-next-line import/no-extraneous-dependencies
import { Node, mergeAttributes } from '@tiptap/core';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    iframe: {
      setIframe: (options: {
        src: string;
        width: string;
        height: string;
      }) => ReturnType;
    };
  }
}

export const Iframe = Node.create({
  name: 'iframe',

  group: 'block',

  atom: true,

  addAttributes() {
    return {
      src: {
        default: null,
      },
      width: {
        default: '300px',
      },
      height: {
        default: '300px',
      },
      frameborder: {
        default: '0',
      },
      allow: {
        default:
          'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture',
      },
      allowfullscreen: {
        default: 'true',
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'iframe',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'iframe',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
    ];
  },

  addCommands() {
    return {
      setIframe:
        (options) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: options,
          });
        },
    };
  },
});

export default Iframe;
