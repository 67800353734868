import React, { Suspense } from 'react';
import { RouterProvider } from 'react-router-dom';

import { ConfigProvider, theme } from 'antd';
import locale from 'antd/locale/ru_RU';

import { router } from '@config/router';
import { Loading } from '@modules/loading';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import 'dayjs/locale/ru';
import 'leaflet/dist/leaflet.css';

dayjs.extend(utc);
export const App: React.FC = React.memo(() => {
  return (
    <ConfigProvider
      locale={locale}
      theme={{ algorithm: theme.defaultAlgorithm }}
    >
      <Suspense fallback={<Loading />}>
        <RouterProvider
          router={router}
          fallbackElement={<p>Initial Load...</p>}
        />
      </Suspense>
    </ConfigProvider>
  );
});
