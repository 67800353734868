import { useEffect } from 'react';

import { Flex } from 'antd';

import { FileAddOutlined } from '@ant-design/icons';
import { IContact } from '@modules/contacts/domain/interface/interface';
import { ContactsStateSelector } from '@modules/contacts/domain/store/selector';
import {
  changeCurrentContact,
  deleteContact,
  getContacts,
} from '@modules/contacts/domain/store/slice';
import { useContactsTableColumns } from '@modules/contacts/services/useContactsTableColumns/useContactsTableColumns';
import { ModulesTitle } from '@shared/components/ModulesTitle/ModulesTitle';
import { ModulesWrapper } from '@shared/components/ModulesWrapper/ModulesWrapper';
import { PrimaryButton } from '@ui-kit/button';
import { TableCustom } from '@ui-kit/customTable';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';

interface ContactsBodyProps {
  showCreateContactModal: () => void;
  showEditContactModal: () => void;
}

export const ContactsBody: React.FC<ContactsBodyProps> = ({
  showCreateContactModal,
  showEditContactModal,
}) => {
  const { language, contacts } = useAppSelector(ContactsStateSelector);
  const dispatch = useAppDispatch();
  const columns = useContactsTableColumns();

  const handleEdit = (record: IContact) => {
    dispatch(changeCurrentContact(record));
    showEditContactModal();
  };

  const handleDelete = (record: any) => {
    dispatch(deleteContact({ id: record.id, language }));
  };

  useEffect(() => {
    dispatch(getContacts({ lang: language }));
  }, []);

  return (
    <ModulesWrapper
      header={
        <Flex justify="space-between" style={{ flexWrap: 'wrap' }}>
          <ModulesTitle fontSize={20}>Данные</ModulesTitle>
          <PrimaryButton
            icon={<FileAddOutlined />}
            onClick={showCreateContactModal}
          >
            Создать данные
          </PrimaryButton>
        </Flex>
      }
    >
      <TableCustom
        rowKey={(record) => record.id}
        dataSource={contacts}
        columns={columns as any}
        actionProps={{
          onHandleEdit: handleEdit,
          onHandleDelete: handleDelete,
        }}
      />
    </ModulesWrapper>
  );
};
