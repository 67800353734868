import { axiosInstance } from '@libs/http';
import {
  IGetAllUsefulResources,
  IUsefulResource,
  IUsefulResourcesState,
} from '@modules/usefulResources/domain/interface/interface';
import { UsefulResourcesApi } from '@modules/usefulResources/domain/store/api';
import { UsefulResourcesSliceConstants } from '@modules/usefulResources/domain/store/sliceConstants';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialState: IUsefulResourcesState = {
  usefulResources: [],
  language: 'ru',
  currentUsefulResource: null,
  languages: {
    ru: {},
    en: {},
    kg: {},
  },
};

const uploadFile = async (file: any) => {
  const formData = new FormData();
  formData.append('file', file.fileList[0].originFileObj);

  const { data: imageId } = await axiosInstance.post(
    UsefulResourcesApi.usefulResourcesUpload,
    formData,
  );

  return imageId;
};

export const getAllUnpopularUsefulResources = createAsyncThunk(
  UsefulResourcesSliceConstants.GetAllUnpopularUsefulResources,
  async function (lang: string, { rejectWithValue }) {
    try {
      const { data } = await axiosInstance.get<Awaited<IGetAllUsefulResources>>(
        `${UsefulResourcesApi.usefulResources}?lang=${lang}`,
      );

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getUsefulResource = createAsyncThunk(
  UsefulResourcesSliceConstants.GetUsefulResource,
  async function (
    { lang, code }: { lang: string; code: string },
    { rejectWithValue },
  ) {
    try {
      const { data } = await axiosInstance.get<Awaited<IUsefulResource>>(
        `${UsefulResourcesApi.usefulResources}/${code}?lang=${lang}&code=${code}`,
      );

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const createUsefulResource = createAsyncThunk(
  UsefulResourcesSliceConstants.CreateUsefulResources,
  async function (
    {
      lang,
      resource,
    }: {
      resource: any;
      lang: string;
    },
    { rejectWithValue, dispatch },
  ) {
    try {
      const logoId = await uploadFile(resource.ru.logo);
      const imageUrl = await uploadFile(resource.ru.imageUrl);

      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const resourceKey in resource) {
        resource[resourceKey] = {
          ...resource[resourceKey],
          logo: logoId,
          imageUrl,
        };
      }

      const { data } = await axiosInstance.post<Awaited<IUsefulResource>>(
        UsefulResourcesApi.usefulResources,
        resource,
      );

      dispatch(getAllUnpopularUsefulResources(lang));

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const deleteResource = createAsyncThunk(
  UsefulResourcesSliceConstants.DeleteUsefulResources,
  async function (
    { id, lang }: { id: number; lang: string },
    { rejectWithValue, dispatch },
  ) {
    try {
      const { data } = await axiosInstance.delete(
        `${UsefulResourcesApi.usefulResources}/${id}`,
      );

      dispatch(getAllUnpopularUsefulResources(lang));

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const updateResource = createAsyncThunk(
  UsefulResourcesSliceConstants.UpdateUsefulResources,
  async function (
    {
      resource: { code, id, logo, imageUrl, ...usefulResource },
      lang,
    }: { resource: IUsefulResource; lang: string },
    { rejectWithValue, dispatch },
  ) {
    try {
      const imgs = [logo, imageUrl];
      const isOldImage = typeof imageUrl === 'string';
      const isOldLogo = typeof logo === 'string';

      if (!isOldImage) {
        const formData = new FormData();
        formData.append('file', imageUrl[0].originFileObj);

        const { data: imageId } = await axiosInstance.post(
          UsefulResourcesApi.usefulResourcesUpload,
          formData,
        );

        imgs[1] = imageId;
      }
      if (!isOldLogo) {
        const formData = new FormData();
        formData.append('file', logo[0].originFileObj);

        const { data: imageId } = await axiosInstance.post(
          UsefulResourcesApi.usefulResourcesUpload,
          formData,
        );

        imgs[0] = imageId;
      }

      const data = await axiosInstance.patch(
        `${UsefulResourcesApi.usefulResources}/${code}?lang=${lang}&code=${code}`,
        { ...usefulResource, logo: imgs[0], imageUrl: imgs[1] },
      );

      dispatch(getAllUnpopularUsefulResources(lang));

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const updatePopularResourcePosition = createAsyncThunk(
  UsefulResourcesSliceConstants.UpdatePopularResourcePosition,
  async function ({ payload }: { payload: number[] }, { rejectWithValue }) {
    try {
      const data = await axiosInstance.patch(
        UsefulResourcesApi.usefulPopularResourcesPosition,
        payload,
      );

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const updatePopularResource = createAsyncThunk(
  UsefulResourcesSliceConstants.UpdatePopularUsefulResources,
  async function (
    {
      resource,
      lang,
    }: { resource: IUsefulResource; lang: string; popular?: string },
    { rejectWithValue, dispatch },
  ) {
    try {
      const data = await axiosInstance.patch(
        `${UsefulResourcesApi.usefulPopularResources}/${resource.id}`,
        { popular: !resource.popular },
      );

      dispatch(getAllUnpopularUsefulResources(lang));

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

const UsefulResourcesSlice = createSlice({
  name: 'UsefulResourcesSlice',
  initialState,
  reducers: {
    changeCurrentLanguage: (state, { payload }) => {
      state.language = payload;
    },
    changeCurrentUsefulResource: (state, { payload }) => {
      state.currentUsefulResource = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getAllUnpopularUsefulResources.fulfilled,
      (state: IUsefulResourcesState, { payload }): IUsefulResourcesState => {
        return {
          ...state,
          usefulResources: payload.items.sort((a, b) => {
            const firstItem = a.position ?? 999;
            const secondItem = b.position ?? 999;
            return firstItem - secondItem;
          }),
        };
      },
    );
    builder.addCase(
      updateResource.fulfilled,
      (state: IUsefulResourcesState, { payload }): IUsefulResourcesState => {
        return {
          ...state,
        };
      },
    );
    builder.addCase(
      deleteResource.fulfilled,
      (state: IUsefulResourcesState, { payload }): IUsefulResourcesState => {
        return {
          ...state,
        };
      },
    );
    builder.addCase(
      createUsefulResource.fulfilled,
      (state: IUsefulResourcesState, { payload }): IUsefulResourcesState => {
        return {
          ...state,
        };
      },
    );
    builder.addCase(
      getUsefulResource.fulfilled,
      (state: IUsefulResourcesState, { payload }): IUsefulResourcesState => {
        return {
          ...state,
          currentUsefulResource: payload,
        };
      },
    );
  },
});

export default UsefulResourcesSlice.reducer;
export const { changeCurrentLanguage, changeCurrentUsefulResource } =
  UsefulResourcesSlice.actions;
