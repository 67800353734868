import { axiosInstance } from '@libs/http';
import {
  AboutState,
  ICompany,
} from '@modules/about/domain/interface/aboutState';
import { AboutAPI } from '@modules/about/domain/store/api';
import { AboutSliceConstants } from '@modules/about/domain/store/sliceConstants';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialState: AboutState = {
  company: null,
  loading: false,
  error: null,
  language: 'ru',
  editor: null,
};

export const getAllCompany = createAsyncThunk(
  AboutSliceConstants.GetAllCompany,
  async function (language: string, { rejectWithValue }) {
    try {
      const { data } = await axiosInstance.get<Awaited<ICompany>>(
        `${AboutAPI.company}?lang=${language}`,
      );

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const createCompany = createAsyncThunk(
  AboutSliceConstants.CreateCompany,
  async function (
    {
      company,
      lang,
    }: { company: { content: string; title: string }; lang: string },
    { rejectWithValue, dispatch },
  ) {
    try {
      const { data } = await axiosInstance.post(AboutAPI.createCompany, {
        [lang]: {
          ...company,
          slug: 'Company',
          metaTitle: '',
          metaDescription: '',
        },
      });

      dispatch(getAllCompany(lang));

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const deleteCompany = createAsyncThunk(
  AboutSliceConstants.DeleteCompany,
  async function (
    { id, lang }: { id: number; lang: string },
    { rejectWithValue, dispatch },
  ) {
    try {
      const { data } = await axiosInstance.delete(
        `${AboutAPI.deleteCompany}/${id}`,
      );

      dispatch(getAllCompany(lang));

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const updateCompany = createAsyncThunk(
  AboutSliceConstants.UpdateCompany,
  async function (
    { company, lang }: { company: ICompany; lang: string },
    { rejectWithValue, dispatch },
  ) {
    try {
      const data = await axiosInstance.patch(
        `${AboutAPI.updateCompany}/${company.code}?lang=${lang}`,
        company,
      );

      dispatch(getAllCompany(lang));

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

const AboutSlice = createSlice({
  name: 'AboutSlice',
  initialState,
  reducers: {
    changeCurrentLanguage: (state, { payload }) => {
      state.language = payload;
    },
    changeEditor: (state, { payload }) => {
      state.editor = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllCompany.fulfilled, (state: AboutState, { payload }) => {
        return {
          ...state,
          company: payload,
          loading: false,
          error: null,
        };
      })
      .addCase(getAllCompany.pending, (state: AboutState) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getAllCompany.rejected, (state) => {
        return {
          ...state,
          loading: false,
          company: null,
        };
      });
    builder
      .addCase(createCompany.fulfilled, (state: AboutState, { payload }) => {
        return {
          ...state,
          loading: false,
          error: null,
        };
      })
      .addCase(createCompany.pending, (state: AboutState) => {
        return {
          ...state,
          loading: true,
        };
      });

    builder
      .addCase(deleteCompany.fulfilled, (state: AboutState, { payload }) => {
        return {
          ...state,
          loading: false,
          error: null,
        };
      })
      .addCase(deleteCompany.pending, (state: AboutState) => {
        return {
          ...state,
          loading: true,
        };
      });

    builder
      .addCase(updateCompany.fulfilled, (state: AboutState, { payload }) => {
        return {
          ...state,
          loading: false,
          error: null,
        };
      })
      .addCase(updateCompany.pending, (state: AboutState) => {
        return {
          ...state,
          loading: true,
        };
      });
  },
});

export const { changeCurrentLanguage, changeEditor } = AboutSlice.actions;
export default AboutSlice.reducer;
