import { axiosInstance } from '@libs/http';
import {
  IRegions,
  IRegionsState,
} from '@modules/regions/domain/interface/interface';
import { RegionsApi } from '@modules/regions/domain/store/api';
import { RegionsSliceConstants } from '@modules/regions/domain/store/sliceConstants';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialState: IRegionsState = {
  regions: [],
  loading: false,
};

export const getRegions = createAsyncThunk(
  RegionsSliceConstants.GetRegions,
  async function (_, { rejectWithValue }) {
    try {
      const { data } = await axiosInstance.get<Awaited<IRegions[]>>(
        RegionsApi.regions,
      );

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

const ProcessesSlice = createSlice({
  name: 'ProcessesSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRegions.fulfilled, (state, { payload }) => {
        return {
          ...state,
          regions: payload,
          loading: false,
        };
      })
      .addCase(getRegions.pending, (state, { payload }) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getRegions.rejected, (state) => {
        return { ...state, loading: true };
      });
  },
});

// export const {} = ProcessesSlice.actions;
export default ProcessesSlice.reducer;
