import React, { useEffect, useState } from 'react';

import { Col, Row, Select, Typography } from 'antd';

import { LayersStateSelector } from '@modules/layers/domain/store/selector';
import {
  changeLayerQueryParams,
  getDistrict,
  getRegions,
} from '@modules/layers/domain/store/slice';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';

export const LayerFilter: React.FC = () => {
  const dispatch = useAppDispatch();
  const [regionValue, setRegionValue] = useState<number | null>(null);
  const { region, district } = useAppSelector(LayersStateSelector);

  useEffect(() => {
    if (regionValue) {
      dispatch(getDistrict(regionValue));
    } else {
      dispatch(getRegions());
    }
  }, [regionValue]);

  const onChangeRegion = (value: number) => {
    setRegionValue(value);
    dispatch(changeLayerQueryParams({ region: value }));
  };

  const onChangeDistrict = (value: number) => {
    dispatch(changeLayerQueryParams({ district: value }));
  };

  return (
    <Row gutter={[40, 0]} style={{ marginBottom: 32 }}>
      <Col span={24} style={{ marginBottom: 8 }}>
        <Typography>Фильтрация :</Typography>
      </Col>
      <Col xs={24} xl={6}>
        <Select
          style={{ width: '100%' }}
          showSearch
          placeholder="Выберите область"
          optionFilterProp="children"
          onChange={onChangeRegion}
          allowClear
          options={region}
        />
      </Col>
      <Col xs={24} xl={6}>
        <Select
          style={{ width: '100%' }}
          showSearch
          placeholder="Выберите район"
          optionFilterProp="children"
          onChange={onChangeDistrict}
          allowClear
          options={district}
        />
      </Col>
    </Row>
  );
};
