export const UsefulResourcesSliceConstants = {
  GetAllUsefulResources: 'getAllUsefulResources',
  GetUsefulResource: 'getUsefulResource',
  CreateUsefulResources: 'createUsefulResources',
  DeleteUsefulResources: 'deleteUsefulResources',
  UpdateUsefulResources: 'updateUsefulResources',
  UpdatePopularUsefulResources: 'updatePopularUsefulResources',
  UpdatePopularResourcePosition: 'updatePopularResourcePosition',
  GetAllPopularUsefulResources: 'getAllPopularUsefulResources',
  GetAllUnpopularUsefulResources: 'getAllUnpopularUsefulResources',
};
