import React, { useContext, useEffect, useMemo } from 'react';

import { Button, Popconfirm, Space, Switch, Table } from 'antd';

import {
  DeleteOutlined,
  EditOutlined,
  HolderOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import type { DragEndEvent } from '@dnd-kit/core';
// eslint-disable-next-line import/no-extraneous-dependencies
import { DndContext } from '@dnd-kit/core';
import type { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
// eslint-disable-next-line import/no-extraneous-dependencies
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
// eslint-disable-next-line import/no-extraneous-dependencies
import { CSS } from '@dnd-kit/utilities';
import { IUsefulResource } from '@modules/usefulResources/domain/interface/interface';
import { UsefulResourcesStateSelector } from '@modules/usefulResources/domain/store/selector';
import {
  changeCurrentUsefulResource,
  deleteResource,
  updatePopularResource,
  updatePopularResourcePosition,
} from '@modules/usefulResources/domain/store/slice';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';

interface RowContextProps {
  setActivatorNodeRef?: (element: HTMLElement | null) => void;
  listeners?: SyntheticListenerMap;
}

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

const RowContext = React.createContext<RowContextProps>({});

const { Column } = Table;

interface UsefulResourcesBodyProps {
  showModal: () => void;
}

const DragHandle: React.FC = () => {
  const { setActivatorNodeRef, listeners } = useContext(RowContext);
  return (
    <Button
      type="text"
      size="small"
      icon={<HolderOutlined />}
      style={{ cursor: 'move' }}
      ref={setActivatorNodeRef}
      {...listeners}
    />
  );
};

const Row: React.FC<RowProps> = (props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: props['data-row-key'] });

  const style: React.CSSProperties = {
    ...props?.style,
    transform: CSS.Translate.toString(transform),
    transition,
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  };

  const contextValue = useMemo<RowContextProps>(
    () => ({ setActivatorNodeRef, listeners }),
    [setActivatorNodeRef, listeners],
  );

  return (
    <RowContext.Provider value={contextValue}>
      <tr {...props} ref={setNodeRef} style={style} {...attributes} />
    </RowContext.Provider>
  );
};

export const UsefulResourcesBody: React.FC<UsefulResourcesBodyProps> = ({
  showModal,
}) => {
  const [dataSource, setDataSource] = React.useState<IUsefulResource[]>([]);

  const dispatch = useAppDispatch();
  const { usefulResources, language } = useAppSelector(
    UsefulResourcesStateSelector,
  );

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setDataSource((prevState) => {
        const activeIndex = prevState.findIndex(
          (record) => record.id === active?.id,
        );
        const overIndex = prevState.findIndex(
          (record) => record.id === over?.id,
        );

        const newSortedState = arrayMove(prevState, activeIndex, overIndex);
        const sortedStateId = newSortedState.map((item) => item.id);

        dispatch(
          updatePopularResourcePosition({
            payload: sortedStateId,
          }),
        );

        return newSortedState;
      });
    }
  };

  const handleUpdateButton = (resource: IUsefulResource) => {
    dispatch(changeCurrentUsefulResource(resource));
    showModal();
  };

  const handlePopular = (resource: IUsefulResource) => {
    dispatch(updatePopularResource({ resource, lang: language }));
  };

  const handleDelete = (id: number) => {
    dispatch(deleteResource({ id, lang: language }));
  };

  useEffect(() => {
    setDataSource(usefulResources);
  }, [usefulResources]);

  return (
    <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
      <SortableContext
        items={dataSource.map((i) => i.id)}
        strategy={verticalListSortingStrategy}
      >
        <Table
          scroll={{ x: 'max-content' }}
          pagination={false}
          dataSource={dataSource}
          components={{ body: { row: Row } }}
          rowKey={(record) => record.id}
        >
          <Column key="sort" render={() => <DragHandle />} />
          <Column dataIndex="id" title="ID" key="id" />
          <Column dataIndex="title" title="Описание" key="title" />
          <Column dataIndex="url" title="URL" key="url" />
          <Column
            title="Партнёры"
            key="actionPopular"
            render={(_: any, record: IUsefulResource) => (
              <Switch
                key={2}
                value={record.popular}
                onClick={() => handlePopular(record)}
              />
            )}
          />
          <Column
            title="Действия"
            align="right"
            key="action"
            render={(_: any, record: IUsefulResource) => (
              <Space onClick={(e) => e.stopPropagation()}>
                <Button
                  icon={<EditOutlined style={{ color: '#8C8C8C' }} />}
                  onClick={(e) => handleUpdateButton(record)}
                  type="default"
                />
                <Popconfirm
                  title="Удалить данные."
                  description="Вы уверены, что хотите удалить эти данные?"
                  onConfirm={() => handleDelete(record.id)}
                  okText="Да"
                  cancelText="Нет"
                  icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                >
                  <Button
                    danger
                    style={{ backgroundColor: '#FF4D4F' }}
                    icon={<DeleteOutlined style={{ color: '#FFF' }} />}
                  />
                </Popconfirm>
              </Space>
            )}
          />
        </Table>
      </SortableContext>
    </DndContext>
  );
};
