import {
  CustomTableAction,
  CustomTableColumnType,
} from '@ui-kit/customTable/types';

export const useMaterialTableColumns = () => {
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      xtype: CustomTableColumnType.STRING,
    },
    {
      title: 'Ссылка',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      xtype: CustomTableColumnType.ACTION_COLUMN,
      actions: [CustomTableAction.LINK],
    },
    {
      title: 'Предпросмотр',
      dataIndex: 'role',
      key: 'role',
      align: 'center',
      xtype: CustomTableColumnType.ACTION_COLUMN,
      actions: [CustomTableAction.WATCH],
    },
    {
      title: 'Действия',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      xtype: CustomTableColumnType.ACTION_COLUMN,
      actions: [CustomTableAction.EDIT, CustomTableAction.DELETE],
    },
  ];

  return columns;
};
