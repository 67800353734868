import React, { useCallback, useMemo, useState } from 'react';

import { Form, Table, TableProps } from 'antd';

import { JsonRecord } from '@app/types';
import { TableColumn, TableCustomActionProps } from '@ui-kit/customTable/types';
import { FieldData } from 'rc-field-form/es/interface';

import { EditableCell } from './cell';
import { filterCutomTableColumns } from './filterCutomTableColumns';
import { getKeyValue } from './getKeyValue';
import { mapCutomTableColumns } from './mapCutomTableColumns';
import styles from './TableCustom.module.scss';

export type GetCellKey<RecordType> = (
  record: RecordType,
  column: TableColumn,
  index?: number,
) => React.Key;

interface TableCustomProps<RecordType> extends TableProps<RecordType> {
  columns: TableColumn[];
  columnsOrder?: string[];
  totalCount?: number;
  highlightedRowKeys?: (React.Key | undefined)[];
  customPagination?: Partial<RecordType>;
  cellKey?: string | keyof RecordType | GetCellKey<RecordType>;
  actionProps?: TableCustomActionProps<RecordType>;
}

export function TableCustom<RecordType extends JsonRecord>({
  columns,
  columnsOrder,
  dataSource = [],
  onChange,
  highlightedRowKeys = [],
  rowClassName,
  rowKey,
  cellKey,
  customPagination,
  totalCount = 10,
  actionProps: {
    editingRowKey: editingRowKeyParent,
    editingCellKey: editingCellKeyParent,
    getEditingRow: getEditingRowParent,
    ...otherActionProps
  } = {},
  ...other
}: TableCustomProps<RecordType>) {
  const [form] = Form.useForm();

  const [editingRowKey, setEditingRowKey] = useState<React.Key | null>(
    editingRowKeyParent || null,
  );
  const [isFormValid, setFormValid] = useState(false);

  const isPaginationDisplayed = useMemo(
    () => totalCount && totalCount > 10000,
    [totalCount],
  );

  const getEditingRow = useCallback(
    (record: RecordType): boolean => {
      const keyValue = getKeyValue(record, rowKey);
      if (getEditingRowParent) {
        return getEditingRowParent(record);
      }

      return keyValue === editingRowKey;
    },
    [editingRowKey, getEditingRowParent],
  );

  const onEditRowClick = useCallback(
    (record: RecordType) => {
      form.setFieldsValue({ ...record });
      const keyValue = getKeyValue(record, rowKey);

      setEditingRowKey(keyValue);
    },
    [rowKey],
  );

  const onEditCancelClick = useCallback(() => {
    setEditingRowKey(null);
  }, []);

  const onFieldsChange = useCallback(
    (_: FieldData[], allFields: FieldData[]) => {
      const isValid = allFields.reduce((prev, field) => {
        return prev && !(field.value === undefined || !!field.errors?.length);
      }, true);
      // form.setFieldValue();
      setFormValid(isValid);
    },
    [setFormValid],
  );

  const canEditCell = useCallback((record: RecordType) => record.disalbed, []);

  const mapedColumns = mapCutomTableColumns({
    customColumns: filterCutomTableColumns({
      customColumns: columns,
      columnsOrder,
    }),
    actionProps: {
      editingRowKey,
      getEditingRow,
      onEditCancelClick,
      onEditRowClick,
      isFormValid,
      canEditCell,
      ...otherActionProps,
    },
  });

  const getRowClassName = useCallback(
    (record: RecordType, index: number, indent: number) => {
      const classNames: string[] = [];

      if (typeof rowClassName === 'string') {
        classNames.push(rowClassName);
      }
      if (typeof rowClassName === 'function') {
        classNames.push(rowClassName(record, index, indent));
      }

      const keyValue = getKeyValue(record, rowKey);
      if (highlightedRowKeys.indexOf(keyValue) > -1) {
        classNames.push(styles.highlightedRow);
      }
      return classNames.join(' ');
    },
    [highlightedRowKeys, rowClassName, rowKey],
  );

  const handleTableChange: TableProps<any>['onChange'] = (
    newPagination,
    newFilters,
    newSorter,
    extra,
  ) => {
    if (onChange) {
      onChange(newPagination, newFilters, newSorter, extra);
    }
  };

  return (
    <Form
      form={form}
      onFieldsChange={onFieldsChange}
      style={{ width: '100%', height: '100%' }}
    >
      <Table<RecordType>
        scroll={{ x: 'max-content' }}
        className={styles.table}
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        columns={mapedColumns}
        dataSource={dataSource}
        onChange={handleTableChange}
        rowKey={rowKey}
        rowClassName={getRowClassName}
        pagination={
          isPaginationDisplayed && customPagination
            ? {
                total: customPagination?.totalPages,
                current: customPagination?.number || 1,
                pageSize: customPagination?.size || 10,
                showSizeChanger: false,
                showTotal: () => {
                  if (customPagination?.totalPages && customPagination?.size) {
                    return `Страница ${
                      customPagination?.number || 1
                    } из ${Math.ceil(
                      customPagination.totalPages / customPagination.size,
                    )}`;
                  }
                  return '';
                },
              }
            : false
        }
        {...other}
      />
    </Form>
  );
}
