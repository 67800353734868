import React, { useEffect, useState } from 'react';

import { Empty, Image } from 'antd';

import { MessagesApi } from '@modules/messages/domain/store/api';
import { MessagesStateSelector } from '@modules/messages/domain/store/selector';
import { useUploadFiles } from '@shared/hooks/useUploadFiles/useUploadFilies';

import { useAppSelector } from '../../../store/hooks';

export const MessagesImagePreview: React.FC = () => {
  const [files, setFiles] = useState<any[]>([]);
  const { currentMessage } = useAppSelector(MessagesStateSelector);

  const getImages = (images: any[]) => {
    useUploadFiles(images, MessagesApi.imagesMessage).then((response) => {
      const urlFromBlob = response.map((item) =>
        URL.createObjectURL(item.data),
      );

      setFiles(urlFromBlob);
    });
  };

  useEffect(() => {
    if (!currentMessage) return;
    if (!currentMessage.photo.length) return;

    getImages(
      currentMessage.photo.filter((item) => !item.type.includes('video')),
    );
  }, [currentMessage]);

  return (
    <Image.PreviewGroup>
      {files.length ? (
        files.map((item) => (
          <Image key={item} src={item} width={200} height={200} />
        ))
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </Image.PreviewGroup>
  );
};
