import { axiosInstance } from '@libs/http';
import {
  IDisasters,
  INaturalDisasterState,
  ITypeDisasters,
} from '@modules/naturalDisaster/domain/interface/interface';
import { NaturalDisasterApi } from '@modules/naturalDisaster/domain/store/api';
import { NaturalDisasterSliceConstants } from '@modules/naturalDisaster/domain/store/sliceConstants';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialState: INaturalDisasterState = {
  currentDisaster: null,
  currentTypeDisaster: null,
  currentTabLang: 'ru',
  disasters: [],
  typeDisasters: [],
  totalDisaster: 0,
  totalTypeDisasters: 0,
  loading: false,
  languages: {
    ru: {},
    en: {},
    kg: {},
  },
};

export const convertCatalogImageToPath = createAsyncThunk(
  NaturalDisasterSliceConstants.ConvertCatalogImageToPath,
  async ({ image }: { image: any }, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append('file', image);

      const { data } = await axiosInstance.post(
        NaturalDisasterApi.uploadCatalog,
        formData,
      );

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getAllGroups = createAsyncThunk(
  NaturalDisasterSliceConstants.GetAllGroups,
  async function (lang: string, { rejectWithValue }) {
    try {
      const { data } = await axiosInstance.get<Awaited<IDisasters[]>>(
        `${NaturalDisasterApi.getDisasters}?lang=${lang}`,
      );

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getGroup = createAsyncThunk(
  NaturalDisasterSliceConstants.GetGroup,
  async function (
    { lang, code }: { lang: string; code: string },
    { rejectWithValue },
  ) {
    try {
      const { data } = await axiosInstance.get<Awaited<IDisasters>>(
        `${NaturalDisasterApi.getDisasters}/${code}?lang=${lang}&code=${code}`,
      );

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getAllCatalogs = createAsyncThunk(
  NaturalDisasterSliceConstants.getAllCatalogs,
  async function (lang: string, { rejectWithValue }) {
    try {
      const { data } = await axiosInstance.get<Awaited<ITypeDisasters[]>>(
        `${NaturalDisasterApi.catalogApi}?lang=${lang}`,
      );

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const createGroup = createAsyncThunk(
  NaturalDisasterSliceConstants.CreateGroup,
  async function (
    { group, lang }: { group: any; lang: string },
    { rejectWithValue, dispatch },
  ) {
    try {
      const response = await axiosInstance.post(
        NaturalDisasterApi.createDisasters,
        group,
      );

      dispatch(getAllGroups(lang));

      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const updateGroup = createAsyncThunk(
  NaturalDisasterSliceConstants.UpdateGroup,
  async function (
    { group, lang, id }: { group: any; lang: string; id: number },
    { rejectWithValue, dispatch },
  ) {
    try {
      const data = await axiosInstance.patch(
        `${NaturalDisasterApi.updateDisasters}/${id}`,
        {
          name: group.name,
          photo: group.photo,
        },
      );

      dispatch(getAllGroups(lang));

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const createCatalog = createAsyncThunk(
  NaturalDisasterSliceConstants.CreateCatalog,
  async function (
    {
      catalog,
      lang,
    }: {
      catalog: { name: string; content: string };
      lang: string;
    },
    { rejectWithValue, dispatch },
  ) {
    try {
      const response = await axiosInstance.post(
        NaturalDisasterApi.catalogApi,
        catalog,
      );

      dispatch(getAllGroups(lang));

      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const deleteGroup = createAsyncThunk(
  NaturalDisasterSliceConstants.DeleteGroup,
  async function (
    { id, lang }: { id: number; lang: string },
    { rejectWithValue, dispatch },
  ) {
    try {
      const { data } = await axiosInstance.delete<number>(
        `${NaturalDisasterApi.deleteDisasters}/${id}`,
      );

      dispatch(getAllGroups(lang));

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const deleteCatalog = createAsyncThunk(
  NaturalDisasterSliceConstants.DeleteCatalog,
  async function (
    { id, lang }: { id: number; lang: string },
    { rejectWithValue, dispatch },
  ) {
    try {
      const { data } = await axiosInstance.delete<number>(
        `${NaturalDisasterApi.catalogApi}/${id}`,
      );

      dispatch(getAllGroups(lang));
      dispatch(getAllCatalogs(lang));

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const updateCatalog = createAsyncThunk(
  NaturalDisasterSliceConstants.UpdateCatalog,
  async function (
    {
      catalog,
      lang,
      code,
    }: {
      catalog: {
        name: string;
        content: string;
        photo: {
          file: any;
          fileList: any[];
        };
      };
      lang: string;
      code: string;
    },
    { rejectWithValue, dispatch },
  ) {
    try {
      const data = await axiosInstance.patch(
        `${NaturalDisasterApi.catalogApi}/${code}?code=${code}&lang=${lang}`,
        catalog,
      );

      dispatch(getAllGroups(lang));
      dispatch(getAllCatalogs(lang));

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

const NaturalDisasterSlice = createSlice({
  name: 'NaturalDisasterSlice',
  initialState,
  reducers: {
    changeCurrentDisaster: (
      state,
      { payload }: { payload: null | IDisasters },
    ) => {
      state.currentDisaster = payload;
    },
    changeCurrentTypeDisaster: (
      state,
      { payload }: { payload: null | ITypeDisasters },
    ) => {
      state.currentTypeDisaster = payload;
    },
    changeCurrentLanguage: (state, { payload }) => {
      state.currentTabLang = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllGroups.fulfilled, (state, { payload }) => {
        return {
          ...state,
          disasters: payload,
          loading: false,
        };
      })
      .addCase(getAllGroups.pending, (state, { payload }) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getAllGroups.rejected, (state) => {
        return { ...state, loading: true };
      });
    builder
      .addCase(getAllCatalogs.fulfilled, (state, { payload }) => {
        return {
          ...state,
          typeDisasters: payload,
          loading: false,
        };
      })
      .addCase(getAllCatalogs.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getAllCatalogs.rejected, (state) => {
        return { ...state, loading: false };
      });
    builder.addCase(getGroup.fulfilled, (state, { payload }) => {
      return {
        ...state,
        currentItem: payload,
      };
    });
  },
});

export const {
  changeCurrentDisaster,
  changeCurrentTypeDisaster,
  changeCurrentLanguage,
} = NaturalDisasterSlice.actions;
export default NaturalDisasterSlice.reducer;
