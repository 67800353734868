import { useEffect } from 'react';

import { Flex, Spin } from 'antd';

import { FileAddOutlined } from '@ant-design/icons';
import { ILayers } from '@modules/layers/domain/interface/interface';
import { LayersStateSelector } from '@modules/layers/domain/store/selector';
import {
  changeCurrentLayer,
  deleteLayer,
  getDistrict,
  getLayers,
  getRegions,
} from '@modules/layers/domain/store/slice';
import { ModulesTitle } from '@shared/components/ModulesTitle/ModulesTitle';
import { ModulesWrapper } from '@shared/components/ModulesWrapper/ModulesWrapper';
import { PrimaryButton } from '@ui-kit/button';
import { TableCustom } from '@ui-kit/customTable';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { layersTableColumns } from '../domain/services/layersColumn';

interface LayersBodyProps {
  showCreateModal: () => void;
  showEditModal: () => void;
}

export const LayersBody: React.FC<LayersBodyProps> = ({
  showCreateModal,
  showEditModal,
}) => {
  const dispatch = useAppDispatch();
  const { layers, layerQueryParams, loading } =
    useAppSelector(LayersStateSelector);

  const handleCreateLayer = () => {
    dispatch(changeCurrentLayer(null));
    showCreateModal();
  };

  const handleUpdateLayer = async (record: ILayers) => {
    showEditModal();

    const regionsData = await dispatch(getRegions());
    const regionId = (
      regionsData.payload as { value: string; id: number }[]
    ).find((item) => item.value === record.region);

    if (!regionId) return;

    const districtsData = await dispatch(getDistrict(regionId.id));
    const districtId = (
      districtsData.payload as { value: string; id: number }[]
    ).find((item) => item.value === record.district);

    if (!districtId) return;

    dispatch(
      changeCurrentLayer({
        ...record,
        district: districtId.id,
        region: regionId.id,
      }),
    );
  };

  const handleDeleteLayer = (record: ILayers) => {
    dispatch(deleteLayer({ id: record.id }));
  };

  useEffect(() => {
    dispatch(getLayers({ ...layerQueryParams }));
  }, [layerQueryParams]);

  console.log('layers', layers);
  return (
    <ModulesWrapper
      header={
        <Flex justify="space-between" style={{ flexWrap: 'wrap' }}>
          <ModulesTitle fontSize={20}>Карты</ModulesTitle>
          <PrimaryButton icon={<FileAddOutlined />} onClick={handleCreateLayer}>
            Создать карту
          </PrimaryButton>
        </Flex>
      }
    >
      {!loading ? (
        <TableCustom
          dataSource={layers}
          columns={layersTableColumns as any}
          rowKey={(record) => record.id}
          actionProps={{
            onHandleEdit: handleUpdateLayer,
            onHandleDelete: handleDeleteLayer,
          }}
        />
      ) : (
        <Spin />
      )}
    </ModulesWrapper>
  );
};
