import { Form, Input, Space } from 'antd';

import { SubmitButton } from '@ui-kit/button';

interface IMaterialFormProps {
  onSave: (value: any) => void;
}

export const CreateGroupForm: React.FC<IMaterialFormProps> = ({ onSave }) => {
  const [form] = Form.useForm();

  const onFinish = async (value: any) => {
    onSave({ ...value, materials: [] });
    form.resetFields();
  };

  return (
    <Form
      onFinish={onFinish}
      form={form}
      name="CreateGroupForm"
      layout="vertical"
    >
      <Form.Item name="title" label="Название" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item>
        <Space>
          <SubmitButton form={form} />
        </Space>
      </Form.Item>
    </Form>
  );
};
