import {
  AlertOutlined,
  CodepenOutlined,
  ContainerOutlined,
  DesktopOutlined,
  InfoCircleOutlined,
  MailOutlined,
  MessageOutlined,
  PhoneOutlined,
  ReconciliationOutlined,
  SolutionOutlined,
} from '@ant-design/icons';

import { AppRoutes } from './appRoutes';

export const menu = [
  {
    title: 'О ЕСКМП',
    path: AppRoutes.about,
    icon: <CodepenOutlined />,
  },
  {
    title: 'Штормовые предупреждения',
    path: AppRoutes.information,
    icon: <InfoCircleOutlined />,
  },
  {
    title: 'Новостная лента',
    path: AppRoutes.news,
    icon: <SolutionOutlined />,
  },
  {
    title: 'Ссылки',
    path: AppRoutes.usefulResources,
    icon: <ContainerOutlined />,
  },
  {
    title: 'Прогнозные материалы',
    path: AppRoutes.materials,
    icon: <ReconciliationOutlined />,
  },
  {
    title: 'Управление картами',
    path: AppRoutes.layers,
    icon: <DesktopOutlined />,
  },
  {
    title: 'Стихийные бедствия',
    path: AppRoutes.naturalDisaster,
    icon: <AlertOutlined />,
  },
  {
    title: 'Сообщения об ОПП',
    path: AppRoutes.messages,
    icon: <MailOutlined />,
  },
  {
    title: 'Обратная связь',
    path: AppRoutes.proposals,
    icon: <MessageOutlined />,
  },
  {
    title: 'Контакты',
    path: AppRoutes.contacts,
    icon: <PhoneOutlined />,
  },
];
