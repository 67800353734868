import React from 'react';

import {
  Col,
  DatePicker,
  DatePickerProps,
  Row,
  Select,
  Typography,
} from 'antd';

import { changeNewsQueryParams } from '@modules/news/domain/store/slice';
import { useSelect } from '@modules/news/services/useSelect/useSelect';

import { useAppDispatch } from '../../../store/hooks';

export const NewsHeader: React.FC = () => {
  const dispatch = useAppDispatch();

  const { filterDistricts, selectMapper } = useSelect();

  const onChangeData: DatePickerProps['onChange'] = (date, dateString) => {
    dispatch(changeNewsQueryParams({ created: dateString }));
  };

  const onChangeSelect = (value: string) => {
    dispatch(changeNewsQueryParams({ district: value }));
  };

  return (
    <Row gutter={[40, 0]} style={{ marginBottom: 32 }}>
      <Col span={24} style={{ marginBottom: 8 }}>
        <Typography>Фильтрация :</Typography>
      </Col>
      <Col xs={24} xl={6}>
        <Select
          style={{ width: '100%' }}
          showSearch
          placeholder="Выберите район"
          optionFilterProp="children"
          onChange={onChangeSelect}
          allowClear
          filterOption={filterDistricts}
          options={selectMapper}
        />
      </Col>
      <Col xs={24} xl={6}>
        <DatePicker style={{ width: '100%' }} onChange={onChangeData} />
      </Col>
    </Row>
  );
};
