import React, { Key } from 'react';

import { Rule } from 'antd/es/form';
import { CompareFn, FilterValue, SortOrder } from 'antd/es/table/interface';

export type JsonRecord = Record<string, any>;

export enum CustomTableAction {
  EDIT = 'edit',
  DELETE = 'delete',
  WATCH = 'watch',
  DOWNLOAD = 'download',
  LINK = 'link',
  ISREADONLY = 'isReadOnly',
}
export enum CustomTableColumnType {
  EXPAND = 'expand',
  SELECT = 'select',
  STRING = 'string',
  DATE = 'date',
  ACTION_COLUMN = 'actioncolumn',
  SELECT_TAG = 'selecttag',
  TAG = 'tag',
  RENDER_CUSTOM = 'rendercustom',
}
export interface FilterDropdownProps {
  setSelectedKeys: (selectedKeys: string[]) => void;
  confirm: () => void;
  clearFilters: () => void;
}

export interface TableFilters {
  text: string;
  value: boolean | Key;
}

interface TableColumnFiltrable {
  filtersKey?: string;
  filters?: TableFilters[];
}

interface TableColumnSearchable {
  searchable?: boolean;
  searchKey?: string;
}

interface TableColumnSortable {
  sortKey?: string;
  sortable?: boolean;
}

interface RenderFilters {
  renderCustom?: <RecordType extends JsonRecord>(
    props: RecordType,
  ) => React.ReactNode;
  renderCustomEditor?: (props: any) => React.ReactNode;
}

export interface TableColumnCommon
  extends TableColumnFiltrable,
    TableColumnSearchable,
    TableColumnSortable,
    RenderFilters {
  title: string | JSX.Element;
  dataIndex: string;
  parentDataIndex?: string;
  className?: string;
  key?: string;
  xtype: CustomTableColumnType;
  render?: (value: any, record: any, index: number) => React.ReactNode;
  actions?: CustomTableAction[];
  disableResetEditing?: boolean;
  filters?: TableFilters[];
  id?: string;
  sorter?:
    | boolean
    | CompareFn<any>
    | {
        compare?: CompareFn<any>;
        multiple?: number;
      };
  rowSpan?: number;
  colSpan?: number;
  align?: 'left' | 'center' | 'right';
  editableRow?: boolean;
  editableCell?: ((record: any) => boolean) | boolean;
  dynamicClassName?: (record: any) => string;
  hideable?: boolean;
  decadeIndex?: Array<number | string>;
  hidden?: boolean;
  fixed?: 'left' | 'right';
  width?: string | number;
  filterSearch?: boolean;
  filterDropdown?: (props: any) => React.ReactNode;
  filterIcon?: (props: any) => JSX.Element;
  filterDropdownClassName?: string;
  defaultFilteredValue?: FilterValue | null;
  filteredValue?: FilterValue | null;
  defaultSortOrder?: SortOrder;
  onFilter?: (value: number | Key | boolean, record: any) => boolean;
  cannotEditRowColSpan?: number;
  children?: TableColumn[];
  ellipsis?: boolean;
  status?: string;
  deadlineTime?: 'start' | 'end';
  userColor?: 'green' | 'turquoise';
  day?: number;
  decadeType?: 'plan' | 'fact' | 'remains';
  options?: Array<{
    label: string;
    value: boolean | Key;
    color?: string;
  }>;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onCell?: (record: any, rowIndex?: number) => any;
  onHeaderCell?: any;
}

export type TableColumnSelect = TableColumnCommon & {
  xtype: CustomTableColumnType.SELECT;
};

export type TableColumnSelectTag = TableColumnCommon & {
  xtype: CustomTableColumnType.SELECT_TAG;
};

export type TableColumn =
  | TableColumnCommon
  | TableColumnSelect
  | TableColumnSelectTag;

export type TableRendererProps<RecordType> = {
  customColumn: TableColumn;
  record: RecordType;

  isEditPossible?: boolean;
  isEditing?: boolean;

  actionProps: TableCustomActionProps<RecordType>;
};

export type TableEditorProps = {
  customColumn: TableColumn;
  editValidator?: {
    rules: Rule[];
  };
  record: JsonRecord;
  actionProps?: TableCustomActionProps<any>;
};

export type EditValidator = {
  rules: Rule[];
};

export type EditValidatorsMap = {
  [key: string]: EditValidator;
};

export type TableCustomActionProps<RecordType> = {
  editingRowKey?: React.Key | null;
  editingCellKey?: React.Key | null;
  editValidators?: EditValidatorsMap;
  isFormValid?: boolean;

  getEditingRow?: (record: RecordType) => boolean;
  canEdit?: (record: RecordType) => boolean;
  onEditCancelClick?: () => void;
  onEditRowClick?: (record: RecordType) => void;
  onEditSaveClick?: (record: RecordType) => void;
  canEditCell?: (record: RecordType, column: TableColumn) => boolean;

  onHandleEdit?: (record: RecordType) => void;
  onHandleDelete?: (record: RecordType) => void;
  onHandleWatch?: (record: RecordType) => void;
  onHandleDownload?: (record: RecordType, type?: string) => void;
};
