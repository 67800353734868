import { message } from 'antd';

import { NaturalDisasterStateSelector } from '@modules/naturalDisaster/domain/store/selector';
import {
  convertCatalogImageToPath,
  createCatalog,
  updateCatalog,
} from '@modules/naturalDisaster/domain/store/slice';
import { CreateTypeDisasterForm } from '@modules/naturalDisaster/ui/CreateTypeDisasterForm';
import { EditTypeDisasterForm } from '@modules/naturalDisaster/ui/EditTypeDisasterForm';
import { CustomModal } from '@ui-kit/customModal';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';

interface TypeDisasterFormActionsProps {
  openTypeDisasterCreate: boolean;
  closeTypeDisasterCreate: () => void;
  openTypeDisasterEdit: boolean;
  closeTypeDisasterEdit: () => void;
}

export const TypeDisasterFormActions: React.FC<
  TypeDisasterFormActionsProps
> = ({
  closeTypeDisasterCreate,
  openTypeDisasterCreate,
  openTypeDisasterEdit,
  closeTypeDisasterEdit,
}) => {
  const dispatch = useAppDispatch();
  const { currentTabLang, currentDisaster, currentTypeDisaster, languages } =
    useAppSelector(NaturalDisasterStateSelector);
  const [messageApi, contextHolder] = message.useMessage();

  const createTypeDisasterMessageKey = 'createTypeDisasterKey';
  const updateTypeDisasterMessageKey = 'updateTypeDisasterKey';

  const createTypeDisasterFinish = (value: any) => {
    if (!currentDisaster) return;

    messageApi.open({
      key: createTypeDisasterMessageKey,
      type: 'loading',
      content: 'Загрузка..',
    });

    const newLanguages: any = {};
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const languagesKey in languages) {
      newLanguages[languagesKey] = { ...value, groupId: currentDisaster.id };
    }

    dispatch(
      createCatalog({
        catalog: newLanguages,
        lang: currentTabLang,
      }),
    ).then(({ meta }) => {
      if (meta.requestStatus === 'fulfilled') {
        messageApi.open({
          key: createTypeDisasterMessageKey,
          type: 'success',
          content: 'Данные успешно созданы.',
          duration: 1,
        });
      } else {
        messageApi.open({
          key: createTypeDisasterMessageKey,
          type: 'error',
          content: 'Произошла ошибка при создании данных.',
          duration: 1,
        });
      }
    });
    closeTypeDisasterCreate();
  };

  const editTypeDisasterFinish = async (value: any) => {
    if (!currentTypeDisaster) return;

    messageApi.open({
      key: updateTypeDisasterMessageKey,
      type: 'loading',
      content: 'Загрузка..',
    });

    if (typeof value.photo === 'string') {
      dispatch(
        updateCatalog({
          catalog: value,
          lang: currentTabLang,
          code: currentTypeDisaster.code,
        }),
      ).then(({ meta }) => {
        if (meta.requestStatus === 'fulfilled') {
          messageApi.open({
            key: updateTypeDisasterMessageKey,
            type: 'success',
            content: 'Данные успешно обновлены.',
            duration: 1,
          });
        } else {
          messageApi.open({
            key: updateTypeDisasterMessageKey,
            type: 'error',
            content: 'Произошла ошибка при обновлении данных.',
            duration: 1,
          });
        }
      });
    } else {
      const { payload } = await dispatch(
        convertCatalogImageToPath({ image: value.photo.file }),
      );

      dispatch(
        updateCatalog({
          catalog: { ...value, photo: payload },
          lang: currentTabLang,
          code: currentTypeDisaster.code,
        }),
      ).then(({ meta }) => {
        if (meta.requestStatus === 'fulfilled') {
          messageApi.open({
            key: updateTypeDisasterMessageKey,
            type: 'success',
            content: 'Данные успешно обновлены.',
            duration: 1,
          });
        } else {
          messageApi.open({
            key: updateTypeDisasterMessageKey,
            type: 'error',
            content: 'Произошла ошибка при обновлении данных.',
            duration: 1,
          });
        }
      });
    }

    closeTypeDisasterEdit();
  };
  return (
    <>
      {contextHolder}
      <CustomModal
        width="70%"
        open={openTypeDisasterCreate}
        onCancel={closeTypeDisasterCreate}
        destroyOnClose
        footer={null}
      >
        <CreateTypeDisasterForm onSave={createTypeDisasterFinish} />
      </CustomModal>
      <CustomModal
        width="70%"
        open={openTypeDisasterEdit}
        onCancel={closeTypeDisasterEdit}
        footer={null}
      >
        <EditTypeDisasterForm onSave={editTypeDisasterFinish} />
      </CustomModal>
    </>
  );
};
