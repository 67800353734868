import { useEffect } from 'react';

import { Button, Flex, Form, Input, Select, Spin, Upload } from 'antd';

import { UploadOutlined } from '@ant-design/icons';
import { getDistrict, getRegions } from '@modules/layers/domain/store/slice';
import { convertCatalogImageToPath } from '@modules/naturalDisaster/domain/store/slice';
import { SubmitButton } from '@ui-kit/button';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { LayersStateSelector } from '../domain/store/selector';

interface IInformationCreateFormProps {
  onSave: (value: any) => void;
  onCancel: () => void;
}

export const LayersCreateForm: React.FC<IInformationCreateFormProps> = ({
  onSave,
  onCancel,
}) => {
  const { region, district, loading } = useAppSelector(LayersStateSelector);
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const valueRegion: number = Form.useWatch('region', form);

  const onFinish = async (value: any) => {
    const { payload } = await dispatch(
      convertCatalogImageToPath({ image: value.file.file }),
    );

    onSave({ ...value, file: payload });
    form.resetFields();
  };

  useEffect(() => {
    if (valueRegion) {
      form.setFieldsValue({ district: '' });
      dispatch(getDistrict(valueRegion));
    } else {
      dispatch(getRegions());
    }
  }, [valueRegion]);

  return (
    <Form
      onFinish={onFinish}
      form={form}
      name="LayersCreateForm"
      layout="vertical"
    >
      <Form.Item name="region" label="Область" rules={[{ required: true }]}>
        <Select options={region} />
      </Form.Item>
      <Form.Item name="district" label="Район" rules={[{ required: true }]}>
        <Select options={district} />
      </Form.Item>
      <Form.Item name="title" label="Название" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item name="file" label="Обложка" rules={[{ required: true }]}>
        <Upload
          beforeUpload={() => false}
          multiple={false}
          name="file"
          accept="image/*"
          maxCount={1}
        >
          <Button icon={<UploadOutlined />}>Загрузить изображение</Button>
        </Upload>
      </Form.Item>
      <Form.Item name="value" label="Ссылка" rules={[{ required: true }]}>
        <Input />
      </Form.Item>

      <Form.Item>
        <Flex justify="space-between" align="center">
          <Button onClick={onCancel}>Отмена</Button>
          <div>
            <SubmitButton form={form} />
            {loading && <Spin style={{ marginLeft: 10 }} />}
          </div>
        </Flex>
      </Form.Item>
    </Form>
  );
};
