import { axiosInstance } from '@libs/http';
import {
  IMessagesRequest,
  IMessagesStore,
} from '@modules/messages/domain/interface/interface';
import { MessagesApi } from '@modules/messages/domain/store/api';
import { MessagesSliceConstants } from '@modules/messages/domain/store/sliceConstants';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { useParams } from '@shared/hooks/useParams/useParams';

const initialState: IMessagesStore = {
  messages: [],
  loading: false,
  error: null,
  currentTab: 'false',
  currentMessage: null,
  messageCount: 0,
  page: 1,
  perPage: 5,
};

export const getAllMessages = createAsyncThunk(
  MessagesSliceConstants.GetAllMessages,
  async function (
    messagesParams: {
      archived: string;
      page: number;
      perPage: number;
    },
    { rejectWithValue },
  ) {
    try {
      const params = useParams({
        ...messagesParams,
        isArchived: messagesParams.archived,
      });
      const { data } = await axiosInstance.get<Awaited<IMessagesRequest>>(
        `${MessagesApi.messages}${params}`,
      );

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getMessagesImages = createAsyncThunk(
  MessagesSliceConstants.GetMessagesImages,
  async function ({ images }: { images: any[] }, { rejectWithValue }) {
    try {
      const informationImages = images.map((item) =>
        axiosInstance.get(`${MessagesApi.imagesMessage}?path=${item.path}`, {
          responseType: 'blob',
        }),
      );
      return Promise.all(informationImages);
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const archiveMessage = createAsyncThunk(
  MessagesSliceConstants.ArchiveMessage,
  async function (
    {
      id,
      archived,
      page,
      perPage,
    }: { id: number; archived: string; page: number; perPage: number },
    { rejectWithValue, dispatch },
  ) {
    try {
      const { data } = await axiosInstance.patch(
        `${MessagesApi.archiveMessage}/${id}`,
        { archived: true },
      );

      dispatch(getAllMessages({ archived, page, perPage }));

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

const MessagesSlice = createSlice({
  name: 'MessagesSlice',
  initialState,
  reducers: {
    changeCurrentTab: (state, { payload }) => {
      state.currentTab = payload;
    },
    changeCurrentMessage: (state, { payload }) => {
      state.currentMessage = payload;
    },
    changeCurrentPage: (state, { payload }) => {
      state.page = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllMessages.fulfilled, (state, { payload }) => {
        return {
          ...state,
          messages: payload.content,
          messageCount: payload.count,
          loading: false,
          error: null,
        };
      })
      .addCase(getAllMessages.pending, (state) => {
        return {
          ...state,
          loading: true,
          error: null,
        };
      })
      .addCase(getAllMessages.rejected, (state, action: any) => {
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      });
  },
});

export const { changeCurrentTab, changeCurrentPage, changeCurrentMessage } =
  MessagesSlice.actions;
export default MessagesSlice.reducer;
