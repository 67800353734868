import { useEffect, useState } from 'react';

import { Image, Spin } from 'antd';

import { InformationApi } from '@modules/information/domain/store/api';
import { InformationStateSelector } from '@modules/information/domain/store/selector';
import { useUploadFiles } from '@shared/hooks/useUploadFiles/useUploadFilies';

import { useAppSelector } from '../../../store/hooks';

export const InformationImagePreview: React.FC = () => {
  const [files, setFiles] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const { currentInformation } = useAppSelector(InformationStateSelector);

  const getImages = (images: any[]) => {
    setLoading(true);
    useUploadFiles(images, InformationApi.informationGetImage)
      .then((response) => {
        const urlFromBlob = response.map((item) =>
          URL.createObjectURL(item.data),
        );

        setFiles(urlFromBlob);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!currentInformation) return;
    if (!currentInformation.photo.length) return;

    getImages(currentInformation.photo);
  }, [currentInformation]);

  if (loading) {
    return <Spin spinning={loading} />;
  }

  return (
    <Image.PreviewGroup>
      {files.map((item) => (
        <Image key={item} src={item} width={200} height={200} />
      ))}
    </Image.PreviewGroup>
  );
};
