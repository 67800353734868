import {
  CustomTableAction,
  CustomTableColumnType,
} from '@ui-kit/customTable/types';

export const useCatalogTableColumns = () => {
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      xtype: CustomTableColumnType.STRING,
    },
    {
      title: 'Описание',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      xtype: CustomTableColumnType.STRING,
    },
    {
      title: 'Содержание',
      dataIndex: 'content',
      key: 'content',
      align: 'center',
      xtype: CustomTableColumnType.ACTION_COLUMN,
      actions: [CustomTableAction.WATCH],
    },
    {
      title: 'Действия',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      xtype: CustomTableColumnType.ACTION_COLUMN,
      actions: [CustomTableAction.EDIT, CustomTableAction.ISREADONLY],
    },
  ];

  return columns;
};
