import { Empty, Space } from 'antd';

import { SupportStateSelector } from '@modules/support/domain/store/selector';
import { SupportCard } from '@modules/support/ui/SupportCard';

import { useAppSelector } from '../../../store/hooks';

export const SupportBody: React.FC = () => {
  const { supportMessage = [] } = useAppSelector(SupportStateSelector);
  return (
    <Space direction="vertical" size="large" style={{ display: 'flex' }}>
      {supportMessage.length ? (
        supportMessage.map((item) => <SupportCard key={item.id} item={item} />)
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </Space>
  );
};
