import { Button, Collapse, Flex, Popconfirm, Space, Typography } from 'antd';

import {
  DeleteOutlined,
  EditOutlined,
  FileAddOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import {
  IGroups,
  IMaterials,
} from '@modules/materials/domain/interface/interface';
import { MaterialsStateSelector } from '@modules/materials/domain/store/selector';
import {
  changeCurrentGroup,
  changeCurrentMaterial,
  deleteGroup,
  deleteMaterial,
} from '@modules/materials/domain/store/slice';
import { useMaterialTableColumns } from '@modules/materials/services';
import { ModulesTitle } from '@shared/components/ModulesTitle/ModulesTitle';
import { ModulesWrapper } from '@shared/components/ModulesWrapper/ModulesWrapper';
import { PrimaryButton } from '@ui-kit/button';
import { TableCustom } from '@ui-kit/customTable';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import styles from './materials.module.scss';

interface IMaterialsItems {
  showCreateGroupModal: () => void;
  showEditGroupModal: () => void;
  showCreateMaterialModal: () => void;
  showEditMaterialModal: () => void;
  showPreview: () => void;
}

export const MaterialBody: React.FC<IMaterialsItems> = ({
  showEditGroupModal,
  showCreateGroupModal,
  showCreateMaterialModal,
  showEditMaterialModal,
  showPreview,
}) => {
  const { currentTabLang, groups } = useAppSelector(MaterialsStateSelector);

  const dispatch = useAppDispatch();
  const columns = useMaterialTableColumns();

  const handleCreateGroup = () => {
    dispatch(changeCurrentGroup(null));
    showCreateGroupModal();
  };

  const handleUpdateGroup = (group: IGroups) => {
    dispatch(changeCurrentGroup(group));
    showEditGroupModal();
  };

  const handleDeleteGroup = (id: number) => {
    dispatch(deleteGroup({ id, lang: currentTabLang }));
  };

  const handleCreateMaterial = (record: IGroups) => {
    dispatch(changeCurrentMaterial(null));
    dispatch(changeCurrentGroup(record));
    showCreateMaterialModal();
  };

  const handleUpdateMaterial = (record: IMaterials) => {
    dispatch(changeCurrentMaterial(record));
    showEditMaterialModal();
  };

  const handleDeleteMaterial = (record: IMaterials) => {
    dispatch(deleteMaterial({ id: record.id, lang: currentTabLang }));
  };

  const handlePreview = (record: IMaterials) => {
    dispatch(changeCurrentMaterial(record));
    showPreview();
  };

  return (
    <ModulesWrapper
      header={
        <Flex justify="space-between" style={{ flexWrap: 'wrap' }}>
          <ModulesTitle fontSize={20}>Материалы</ModulesTitle>
          <PrimaryButton icon={<FileAddOutlined />} onClick={handleCreateGroup}>
            Создать группу
          </PrimaryButton>
        </Flex>
      }
    >
      <Space direction="vertical" style={{ width: '100%' }} size="middle">
        {groups.map((item) => (
          <Collapse
            size="large"
            accordion
            key={item.id}
            collapsible="header"
            items={[
              {
                key: item.id,
                label: (
                  <Flex
                    justify="space-between"
                    align="center"
                    className={styles.materialBody}
                  >
                    <Typography style={{ fontWeight: 600 }}>
                      {item.title}
                    </Typography>
                    <Space onClick={(e) => e.stopPropagation()}>
                      <Button
                        type="primary"
                        onClick={() => handleCreateMaterial(item)}
                        icon={<FileAddOutlined />}
                      />
                      <Button
                        icon={<EditOutlined style={{ color: '#8C8C8C' }} />}
                        onClick={(e) => handleUpdateGroup(item)}
                        type="default"
                      />
                      <Popconfirm
                        title="Удалить данные."
                        description="Вы уверены, что хотите эти данные?"
                        onConfirm={() => handleDeleteGroup(item.id)}
                        okText="Да"
                        cancelText="Нет"
                        icon={
                          <QuestionCircleOutlined style={{ color: 'red' }} />
                        }
                      >
                        <Button
                          danger
                          style={{ backgroundColor: '#FF4D4F' }}
                          icon={<DeleteOutlined style={{ color: '#FFF' }} />}
                        />
                      </Popconfirm>
                    </Space>
                  </Flex>
                ),
                children: (
                  <TableCustom
                    rowKey={(record) => record.id}
                    dataSource={item?.materials ?? []}
                    columns={columns as any}
                    actionProps={{
                      onHandleEdit: handleUpdateMaterial,
                      onHandleDelete: handleDeleteMaterial,
                      onHandleWatch: handlePreview,
                    }}
                  />
                ),
              },
            ]}
          />
        ))}
      </Space>
    </ModulesWrapper>
  );
};
