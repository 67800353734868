import { useEffect } from 'react';

import { message, Pagination, Tabs } from 'antd';

import { InformationStateSelector } from '@modules/information/domain/store/selector';
import {
  changeCurrentLanguage,
  changeInformationQueryParams,
  createInformation,
  getAllInformation,
  getDistricts,
  updateInformation,
} from '@modules/information/domain/store/slice';
import { InformationBody } from '@modules/information/ui/InformationBody';
import { InformationCreateForm } from '@modules/information/ui/InformationCreateForm';
import { InformationEditForm } from '@modules/information/ui/InformationEditForm';
import { InformationHeader } from '@modules/information/ui/InformationHeader';
import { InformationImagePreview } from '@modules/information/ui/InformationImagePreview';
import { CustomModalWrapper } from '@shared/components/CustomModalWrapper/CustomModalWrapper';
import { ModulesTitle } from '@shared/components/ModulesTitle/ModulesTitle';
import { useModal } from '@shared/hooks/useModal/useModal';
import { useTabs } from '@shared/hooks/useTabs/useTabs';
import { CustomModal } from '@ui-kit/customModal';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';

const createInformationMessageKey = 'createInformation';
const updateInformationMessageKey = 'updateInformation';

export const InformationPage: React.FC = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const { informationQueryParams: infoParams } = useAppSelector(
    InformationStateSelector,
  );
  const tabItems = useTabs();

  const dispatch = useAppDispatch();
  const {
    language,
    districts,
    totalInformation,
    informationQueryParams,
    languages,
  } = useAppSelector(InformationStateSelector);

  const {
    open: openCreateInformation,
    showModal: showCreateInformation,
    closeModal: closeCreateInformation,
  } = useModal();

  const {
    open: openUpdateInformation,
    showModal: showUpdateInformation,
    closeModal: closeUpdateInformation,
  } = useModal();

  const {
    open: openPreviewInformation,
    showModal: showPreviewInformation,
    closeModal: closePreviewInformation,
  } = useModal();

  const handleCreateFinish = (value: any) => {
    messageApi.open({
      key: createInformationMessageKey,
      type: 'loading',
      content: 'Загрузка..',
    });

    const newLanguages: any = {};
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const languagesKey in languages) {
      newLanguages[languagesKey] = value;
    }

    dispatch(
      createInformation({ information: newLanguages, lang: language }),
    ).then(({ meta }) => {
      if (meta.requestStatus === 'fulfilled') {
        messageApi.open({
          key: createInformationMessageKey,
          type: 'success',
          content: 'Данные успешно созданы.',
          duration: 1,
        });
      } else {
        messageApi.open({
          key: createInformationMessageKey,
          type: 'error',
          content: 'Ошибка при создании данных.',
          duration: 1,
        });
      }
    });
    closeCreateInformation();
  };

  const handleEditFinish = (value: any) => {
    messageApi.open({
      key: updateInformationMessageKey,
      type: 'loading',
      content: 'Загрузка..',
    });

    dispatch(updateInformation({ information: value, lang: language })).then(
      ({ meta }) => {
        if (meta.requestStatus === 'fulfilled') {
          messageApi.open({
            key: updateInformationMessageKey,
            type: 'success',
            content: 'Данные успешно обновлены.',
            duration: 1,
          });
        } else {
          messageApi.open({
            key: updateInformationMessageKey,
            type: 'error',
            content: 'Ошибка при обновлений данных.',
            duration: 1,
          });
        }
      },
    );
    closeUpdateInformation();
  };

  const handleChangeTab = (newLanguage: string) => {
    const params = { ...informationQueryParams, page: 1 };

    dispatch(changeCurrentLanguage(newLanguage));
    dispatch(
      getAllInformation({
        lang: newLanguage,
        ...params,
      }),
    );
  };

  const handleChangePage = (page: number) => {
    dispatch(
      changeInformationQueryParams({
        page,
      }),
    );
  };

  useEffect(() => {
    if (!districts.length) {
      dispatch(getDistricts());
    }
    dispatch(getAllInformation({ lang: language, ...informationQueryParams }));
  }, [informationQueryParams]);

  return (
    <>
      {contextHolder}
      <ModulesTitle>Штормовые предупреждения</ModulesTitle>
      <Tabs
        style={{ marginBottom: 32 }}
        activeKey={language}
        defaultActiveKey="ru"
        items={tabItems}
        onChange={handleChangeTab}
      />
      <InformationHeader />
      <InformationBody
        showPreviewModal={showPreviewInformation}
        showCreateModal={showCreateInformation}
        showEditModal={showUpdateInformation}
      />
      <CustomModalWrapper
        handleChangeTab={handleChangeTab}
        modalTitle="Создание новости"
        tabs
        open={openCreateInformation}
        onCancel={closeCreateInformation}
        footer={null}
      >
        <InformationCreateForm
          onCancel={closeCreateInformation}
          onSave={handleCreateFinish}
        />
      </CustomModalWrapper>
      <CustomModalWrapper
        modalTitle="Редактирование новости"
        open={openUpdateInformation}
        tabs
        handleChangeTab={handleChangeTab}
        onCancel={closeUpdateInformation}
        footer={null}
      >
        <InformationEditForm
          onCancel={closeUpdateInformation}
          onSave={handleEditFinish}
        />
      </CustomModalWrapper>
      <CustomModal
        open={openPreviewInformation}
        onCancel={closePreviewInformation}
        footer={null}
      >
        <InformationImagePreview />
      </CustomModal>
      <Pagination
        onChange={handleChangePage}
        style={{ margin: 10 }}
        pageSize={infoParams.perPage}
        total={totalInformation}
      />
    </>
  );
};
