import { useEffect, useRef } from 'react';

import { Flex } from 'antd';

import { axiosInstance } from '@libs/http';
import { MaterialsApi } from '@modules/materials/domain/store/api';
import { MaterialsStateSelector } from '@modules/materials/domain/store/selector';

import { useAppSelector } from '../../../store/hooks';

export const MaterialPreview: React.FC = () => {
  const { currentMaterial } = useAppSelector(MaterialsStateSelector);
  const imageRef = useRef<any>(null);

  const handleUpload = (imagePath: string) => {
    axiosInstance
      .get(`${MaterialsApi.uploadMaterial}?path=${imagePath}`, {
        responseType: 'blob',
      })
      .then((response) => {
        const url = URL.createObjectURL(response.data);

        imageRef.current.src = url;
      });
  };

  useEffect(() => {
    if (!currentMaterial) return;
    handleUpload(currentMaterial.image);
  }, [currentMaterial]);

  return (
    <Flex justify="center">
      <img src="" ref={imageRef} alt="preview" />
    </Flex>
  );
};
