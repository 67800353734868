import { useEffect, useState } from 'react';

import { Button, Divider, Space } from 'antd';

import L from 'leaflet';

import 'leaflet-draw';

interface IMapModalPageProps {
  coords?: any;
  setCoords?: React.Dispatch<React.SetStateAction<any>>;
  onCancel?: () => void;
  type: string;
}

export const Map: React.FC<IMapModalPageProps> = ({
  setCoords,
  coords,
  type,
  onCancel,
}) => {
  const [leafletLayer, setLeafletLayer] = useState(null);

  useEffect(() => {
    const map = L.map('map', {
      attributionControl: false,
      zoomControl: false,
    }).setView([42.8741216689044, 74.60295149241576], 13);

    L.Icon.Default.imagePath = 'https://unpkg.com/leaflet/dist/images/';

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '',
      id: 'mapbox',
    }).addTo(map);

    let layer: any = null;

    if (coords) {
      if (type === 'Polygon') {
        layer = L.polygon(coords, { color: 'red' }).addTo(map);
      } else if (type === 'LineString') {
        layer = L.polyline(coords, { color: 'red' }).addTo(map);
      } else if (type === 'Point') {
        layer = L.marker(coords).addTo(map);
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (type === 'Polygon') {
        layer = new L.Draw.Polygon(map).enable();
      } else if (type === 'LineString') {
        layer = new L.Draw.Polyline(map).enable();
      } else if (type === 'Point') {
        layer = new L.Draw.Marker(map).enable();
      }
    }

    map.on('draw:created', (e) => {
      layer = e.layer;
      if ((e as any).layerType !== 'marker') {
        layer.editing.enable();
      }
      map.addLayer(layer);
      setLeafletLayer(layer);
    });

    if (layer) {
      (layer as any).editing.enable();
      map.addLayer(layer);
      setLeafletLayer(layer as any);
    }

    return () => {
      map.remove();
    };
  }, []);

  const flattenArray = (arr: any[]) => {
    const flattened: any[] = [];

    arr.forEach((item) => {
      if (Array.isArray(item)) {
        flattened.push(...flattenArray(item)); // Recursively flatten nested array
      } else {
        flattened.push(item); // Add non-array elements to the flattened array
      }
    });

    return flattened;
  };

  const handleOk = () => {
    if (type === 'Point') {
      const pointCoordinates = {
        coord: [
          (leafletLayer as any)._latlng.lat,
          (leafletLayer as any)._latlng.lng,
        ],
        tracker: 'ff',
        seaLevelMark: 'ff',
        type,
      };

      if (setCoords)
        setCoords((prev: any) => ({
          ...prev,
          [type]: pointCoordinates,
        }));

      if (onCancel) onCancel();
      return;
    }

    const validCoordinates: number[][] = [];
    const latLng = (leafletLayer as any).getLatLngs();

    flattenArray(latLng).forEach((item) => {
      validCoordinates.push([item.lat, item.lng]);
    });

    const mapCoordinates = {
      coord: validCoordinates,
      tracker: 'ff',
      seaLevelMark: 'ff',
      type,
    };

    if (setCoords)
      setCoords((prev: any) => ({
        ...prev,
        [type]: mapCoordinates,
      }));

    if (onCancel) onCancel();
  };

  return (
    <>
      <div id="map" style={{ height: '600px', width: '100%' }} />
      {onCancel && (
        <>
          <Divider />
          <Space>
            <Button onClick={handleOk}>Ок</Button>
            <Button onClick={onCancel}>Закрыть</Button>
          </Space>
        </>
      )}
    </>
  );
};
