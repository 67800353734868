import React, { SyntheticEvent, useCallback } from 'react';

import {
  CustomTableColumnType,
  TableColumn,
  TableCustomActionProps,
} from '@ui-kit/customTable/types';

import { EditorFactory } from './editor';
import { RendererFactory } from './renderer';

export type JsonRecord = Record<string, any>;

export interface EditableCellProps<RecordType>
  extends React.HTMLAttributes<HTMLElement> {
  customColumn?: TableColumn;
  record: RecordType;
  actionProps: TableCustomActionProps<RecordType>;
}

export function EditableCell<RecordType extends JsonRecord>({
  customColumn,
  record,
  actionProps,
  ...restProps
}: EditableCellProps<RecordType>): React.ReactElement {
  const isEditPossible = actionProps?.editingRowKey === null;
  const isEditingRow = actionProps?.getEditingRow?.(record);

  const onClick = useCallback(
    (e: SyntheticEvent) => {
      // if (isEditingCell) {
      e.stopPropagation();
      // }
      // if (customColumn?.editableCell && !isEditingCell) {
      //   actionProps?.onEditCellClick?.(record, customColumn);
      // }
    },
    [customColumn],
  );

  if (!customColumn) return <td {...restProps} />;

  if (customColumn.editableRow && isEditingRow) {
    const editValidator = actionProps.editValidators?.[customColumn.dataIndex];
    const editorElement =
      customColumn.xtype === CustomTableColumnType.RENDER_CUSTOM
        ? customColumn.renderCustomEditor!({ customColumn, record })
        : EditorFactory.createEditor({
            customColumn,
            record,
            editValidator,
            actionProps,
          });

    return (
      <td aria-hidden="true" {...restProps}>
        {editorElement}
      </td>
    );
  }

  const renderProps = {
    customColumn,
    record,

    isEditPossible,
    isEditingRow,
    actionProps,
  };

  const renderedElement =
    customColumn.xtype === CustomTableColumnType.RENDER_CUSTOM
      ? customColumn.renderCustom!(renderProps)
      : RendererFactory.createRenderer(renderProps);

  return (
    <td onClick={onClick} {...restProps}>
      {renderedElement}
    </td>
  );
}
