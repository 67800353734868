import React from 'react';

import { Empty, Space, Tabs } from 'antd';

import { MessagesStateSelector } from '@modules/messages/domain/store/selector';
import { MessagesCard } from '@modules/messages/ui/MessagesCard';
import { MessagesImagePreview } from '@modules/messages/ui/MessagesImagePreview';
import { MessagesVideoPreview } from '@modules/messages/ui/MessagesVideoPreview';
import { useModal } from '@shared/hooks/useModal/useModal';
import { CustomModal } from '@ui-kit/customModal';

import { useAppSelector } from '../../../store/hooks';

export const MessagesBody: React.FC = () => {
  const {
    open: openPreview,
    closeModal: closePreview,
    showModal: showPreview,
  } = useModal();

  const items = [
    {
      key: '1',
      label: 'Видео',
      children: <MessagesVideoPreview />,
    },
    {
      key: '2',
      label: 'Фото',
      children: <MessagesImagePreview />,
    },
  ];

  const { messages } = useAppSelector(MessagesStateSelector);
  return (
    <Space direction="vertical" size="large" style={{ display: 'flex' }}>
      {messages.length ? (
        messages.map((item) => (
          <MessagesCard key={item.id} item={item} showPreview={showPreview} />
        ))
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
      <CustomModal
        destroyOnClose
        open={openPreview}
        onCancel={closePreview}
        footer={null}
      >
        <Tabs defaultActiveKey="1" items={items} />
      </CustomModal>
    </Space>
  );
};
