import React, { useState } from 'react';

import { Tabs } from 'antd';

import { ContactsStateSelector } from '@modules/contacts/domain/store/selector';
import {
  changeCurrentLanguage,
  createContact,
  editContact,
  getContact,
  getContacts,
} from '@modules/contacts/domain/store/slice';
import { ContactCreateForm } from '@modules/contacts/ui/ContactCreateForm';
import { EditCreateForm } from '@modules/contacts/ui/ContactEditForm';
import { ContactsBody } from '@modules/contacts/ui/ContactsBody';
import { CustomModalWrapper } from '@shared/components/CustomModalWrapper/CustomModalWrapper';
import { ModulesTitle } from '@shared/components/ModulesTitle/ModulesTitle';
import { useModal } from '@shared/hooks/useModal/useModal';
import { useTabs } from '@shared/hooks/useTabs/useTabs';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';

export const ContactsPage: React.FC = () => {
  const tabItems = useTabs();
  const [activeTab, setActiveTab] = useState('ru');
  const dispatch = useAppDispatch();
  const { language, currentContact } = useAppSelector(ContactsStateSelector);

  const {
    open: openCreateContact,
    closeModal: closeCreateContact,
    showModal: showCreateContact,
  } = useModal();

  const {
    open: openEditContact,
    closeModal: closeEditContact,
    showModal: showEditContact,
  } = useModal();

  const handleChangeTab = (key: string) => {
    setActiveTab(key);
    dispatch(changeCurrentLanguage(key));
    dispatch(getContacts({ lang: key }));
  };

  const handleChangeEditTab = (key: string) => {
    setActiveTab(key);
    if (!currentContact) return;
    dispatch(getContact({ lang: key, code: currentContact.code }));
  };

  const handleCreateFinish = (value: any) => {
    dispatch(createContact({ contacts: value, language }));
    closeCreateContact();
  };

  const handleEditFinish = (value: any) => {
    dispatch(editContact({ contact: value, language }));
    closeEditContact();
  };

  return (
    <>
      <ModulesTitle>Контакты</ModulesTitle>
      <Tabs
        style={{ marginBottom: 32 }}
        activeKey={activeTab}
        items={tabItems}
        onChange={handleChangeTab}
      />
      <ContactsBody
        showEditContactModal={showEditContact}
        showCreateContactModal={showCreateContact}
      />
      <CustomModalWrapper
        handleChangeTab={handleChangeTab}
        activeTabsKey={activeTab}
        modalTitle="Создание данных"
        tabs
        open={openCreateContact}
        onCancel={closeCreateContact}
        footer={null}
      >
        <ContactCreateForm
          onCancel={closeCreateContact}
          onSave={handleCreateFinish}
        />
      </CustomModalWrapper>
      <CustomModalWrapper
        activeTabsKey={activeTab}
        handleChangeTab={handleChangeEditTab}
        modalTitle="Редактирование данных"
        tabs
        open={openEditContact}
        onCancel={closeEditContact}
        footer={null}
      >
        <EditCreateForm onCancel={closeEditContact} onSave={handleEditFinish} />
      </CustomModalWrapper>
    </>
  );
};
