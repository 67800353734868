import { useCallback, useState } from 'react';

import { Button, Col, Form, Input, Row, Space, Typography, Upload } from 'antd';

import { UploadOutlined } from '@ant-design/icons';
import { convertCatalogImageToPath } from '@modules/naturalDisaster/domain/store/slice';
import { TiptapEditor } from '@shared/components/TiptapEditor/TiptapEditor';
import { SubmitButton } from '@ui-kit/button';

import { useAppDispatch } from '../../../store/hooks';

interface INaturalDisasterFormProps {
  onSave: (value: any) => void;
}

export const CreateTypeDisasterForm: React.FC<INaturalDisasterFormProps> = ({
  onSave,
}) => {
  const dispatch = useAppDispatch();
  const [editorValue, setEditorValue] = useState('');
  const [descriptionValue, setDescriptionValue] = useState('');

  const [form] = Form.useForm();

  const onChangeEditor = useCallback((value: string) => {
    setEditorValue(value);
  }, []);

  const onChangeDescription = useCallback((value: string) => {
    setDescriptionValue(value);
  }, []);

  const onFinish = async (value: any) => {
    const { payload } = await dispatch(
      convertCatalogImageToPath({ image: value.photo.file }),
    );
    onSave({
      ...value,
      content: editorValue,
      photo: payload,
      description: descriptionValue,
    });
    form.resetFields();
  };

  return (
    <Form onFinish={onFinish} form={form} name="validateOnly" layout="vertical">
      <Form.Item name="name" label="Название">
        <Input />
      </Form.Item>
      <Row>
        <Typography>Описание</Typography>
        <Col span={24}>
          <TiptapEditor onChange={onChangeDescription} />
        </Col>
      </Row>
      <Form.Item initialValue="" name="iframe" label="iframe">
        <Input />
      </Form.Item>
      <Form.Item initialValue="" name="title" label="title">
        <Input />
      </Form.Item>
      <Form.Item initialValue="" name="iframe2" label="iframe 2">
        <Input />
      </Form.Item>
      <Form.Item initialValue="" name="title2" label="title 2">
        <Input />
      </Form.Item>
      <Form.Item initialValue="" name="iframe3" label="iframe 3">
        <Input />
      </Form.Item>
      <Form.Item initialValue="" name="title3" label="title 3">
        <Input />
      </Form.Item>
      <Form.Item initialValue="" name="iframe4" label="iframe 4">
        <Input />
      </Form.Item>
      <Form.Item initialValue="" name="title4" label="title 4">
        <Input />
      </Form.Item>
      <Form.Item initialValue="" name="iframe5" label="iframe 5">
        <Input />
      </Form.Item>
      <Form.Item initialValue="" name="title5" label="title 5">
        <Input />
      </Form.Item>
      <Form.Item name="photo" label="Изображение" rules={[{ required: true }]}>
        <Upload
          beforeUpload={() => false}
          multiple={false}
          name="photo"
          accept="image/*"
          maxCount={1}
        >
          <Button icon={<UploadOutlined />}>Загрузить изображение</Button>
        </Upload>
      </Form.Item>
      <Row>
        <Typography>Содержание</Typography>
        <Col span={24}>
          <TiptapEditor onChange={onChangeEditor} />
        </Col>
      </Row>
      <Form.Item style={{ marginTop: 20 }}>
        <Space>
          <SubmitButton form={form} />
        </Space>
      </Form.Item>
    </Form>
  );
};
