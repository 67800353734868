export enum AppRoutes {
  default = '/',
  usefulResources = '/useful-resources',
  information = '/information',
  news = '/news',
  login = '/login',
  about = '/about',
  materials = '/materials',
  proposals = '/proposals',
  layers = '/layers',
  messages = '/messages',
  naturalDisaster = '/natural-disaster',
  contacts = '/contacts',
  invalid = '*',
}
