import {
  CustomTableAction,
  CustomTableColumnType,
} from '@ui-kit/customTable/types';

export const useInformationTableColumns = () => {
  const columns = [
    {
      title: 'Описание',
      dataIndex: 'content',
      key: 'content',
      align: 'center',
      xtype: CustomTableColumnType.STRING,
    },
    {
      title: 'Район',
      dataIndex: 'district',
      key: 'district',
      align: 'center',
      xtype: CustomTableColumnType.STRING,
    },
    {
      title: 'Предпросмотр',
      dataIndex: 'photo',
      key: 'photo',
      align: 'center',
      xtype: CustomTableColumnType.ACTION_COLUMN,
      actions: [CustomTableAction.WATCH],
    },
  ];

  return columns;
};
