import { Button, Col, Form, Input, Row, Space, Upload } from 'antd';

import { UploadOutlined } from '@ant-design/icons';
import { SubmitButton } from '@ui-kit/button';

interface IMaterialFormProps {
  onSave: (value: any) => void;
}

export const CreateMaterialForm: React.FC<IMaterialFormProps> = ({
  onSave,
}) => {
  const [form] = Form.useForm();

  const onFinish = async (value: any) => {
    onSave(value);
    form.resetFields();
  };

  return (
    <Form
      onFinish={onFinish}
      form={form}
      name="CreateMaterialForm"
      layout="vertical"
    >
      <Form.Item name="name" label="Название" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Row>
        <Col span={24}>
          <Form.Item
            name="link"
            label="Ссылка на документ"
            rules={[
              { required: true },
              { type: 'url', message: 'Пожалуйста, введите Ссылку' },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="image" label="Обложка" rules={[{ required: true }]}>
            <Upload
              beforeUpload={() => false}
              multiple={false}
              name="image"
              accept="image/*"
            >
              <Button icon={<UploadOutlined />}>Загрузить обложку</Button>
            </Upload>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Space>
          <SubmitButton form={form} />
        </Space>
      </Form.Item>
    </Form>
  );
};
