import { axiosInstance } from '@libs/http';
import {
  IGroups,
  IMaterials,
  IMaterialsInfo,
  IMaterialsState,
} from '@modules/materials/domain/interface/interface';
import { MaterialsApi } from '@modules/materials/domain/store/api';
import { MaterialsSliceConstants } from '@modules/materials/domain/store/sliceConstants';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { isNull } from 'lodash';

const initialState: IMaterialsState = {
  materials: [],
  groups: [],
  currentTabLang: 'ru',
  materialsInfo: null,
  currentGroup: null,
  currentMaterial: null,
  languages: {
    ru: {},
    en: {},
    kg: {},
  },
};

export const getAllGroups = createAsyncThunk(
  MaterialsSliceConstants.GetAllMaterials,
  async function (lang: string, { rejectWithValue }) {
    try {
      const { data } = await axiosInstance.get<Awaited<IGroups[]>>(
        `${MaterialsApi.groups}?lang=${lang}`,
      );

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getAllMaterialsInfo = createAsyncThunk(
  MaterialsSliceConstants.GetAllMaterialsInfo,
  async function (lang: string, { rejectWithValue }) {
    try {
      const { data } = await axiosInstance.get<Awaited<IMaterialsInfo>>(
        `${MaterialsApi.materialsInfo}?lang=${lang}`,
      );

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getAllMaterials = createAsyncThunk(
  MaterialsSliceConstants.GetAllMaterialsGroup,
  async function (lang: string, { rejectWithValue }) {
    try {
      const { data } = await axiosInstance.get<Awaited<IMaterials[]>>(
        `${MaterialsApi.materials}?lang=${lang}`,
      );

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const createMaterialInfo = createAsyncThunk(
  MaterialsSliceConstants.CreateMaterialInfo,
  async function (
    {
      materialInfo,
      lang,
    }: { materialInfo: { description: string }; lang: string },
    { rejectWithValue, dispatch, getState },
  ) {
    try {
      const response = await axiosInstance.post(
        MaterialsApi.createMaterialInfo,
        {
          [lang]: materialInfo,
        },
      );

      dispatch(getAllMaterialsInfo(lang));

      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const createGroup = createAsyncThunk(
  MaterialsSliceConstants.CreateGroup,
  async function (
    { group, lang }: { group: Omit<IGroups, 'id'>; lang: string },
    { rejectWithValue, dispatch, getState },
  ) {
    try {
      const response = await axiosInstance.post(
        MaterialsApi.createGroup,
        group,
      );

      dispatch(getAllGroups(lang));

      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const updateGroup = createAsyncThunk(
  MaterialsSliceConstants.UpdateGroup,
  async function (
    { group, lang, id }: { group: Omit<IGroups, 'id'>; lang: string; id: any },
    { rejectWithValue, dispatch },
  ) {
    try {
      const data = await axiosInstance.patch(
        `${MaterialsApi.updateGroup}/${id}`,
        { title: group.title, materials: group.materials },
      );

      dispatch(getAllGroups(lang));

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getGroup = createAsyncThunk(
  MaterialsSliceConstants.GetGroup,
  async function (
    { lang, code }: { lang: string; code: string },
    { rejectWithValue, dispatch },
  ) {
    try {
      const { data } = await axiosInstance.get<any>(
        `${MaterialsApi.getGroup}/${code}?lang=${lang}&code=${code}`,
      );

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const createMaterial = createAsyncThunk(
  MaterialsSliceConstants.CreateMaterial,
  async function (
    {
      material,
      lang,
      currentGroup,
    }: { material: Omit<IMaterials, 'id'>; lang: string; currentGroup: any },
    { rejectWithValue, dispatch, getState },
  ) {
    try {
      const formData = new FormData();
      formData.append('file', material.image.fileList[0].originFileObj);

      const { data: imageId } = await axiosInstance.post(
        MaterialsApi.uploadImage,
        formData,
      );

      const response = await axiosInstance.post(MaterialsApi.createMaterial, {
        [lang]: { ...material, image: imageId },
      });

      const { data } = response;

      dispatch(
        updateGroup({
          lang,
          id: currentGroup.id,
          group: {
            title: currentGroup.title,
            materials: [
              ...currentGroup.materials.map((item: any) => item.id),
              data[0].id,
            ],
            code: currentGroup.code,
          },
        }),
      );
      dispatch(getAllMaterials(lang));

      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const deleteGroup = createAsyncThunk(
  MaterialsSliceConstants.DeleteGroup,
  async function (
    { id, lang }: { id: number; lang: string },
    { rejectWithValue, dispatch },
  ) {
    try {
      const { data } = await axiosInstance.delete<number>(
        `${MaterialsApi.deleteGroup}/${id}`,
      );

      dispatch(getAllGroups(lang));

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const deleteMaterial = createAsyncThunk(
  MaterialsSliceConstants.DeleteMaterial,
  async function (
    { id, lang }: { id: number; lang: string },
    { rejectWithValue, dispatch },
  ) {
    try {
      const { data } = await axiosInstance.delete<number>(
        `${MaterialsApi.deleteMaterial}/${id}`,
      );

      dispatch(getAllMaterials(lang));
      dispatch(getAllGroups(lang));

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const deleteMaterialInfo = createAsyncThunk(
  MaterialsSliceConstants.DeleteMaterialInfo,
  async function (
    { id, lang }: { id: number; lang: string },
    { rejectWithValue, dispatch },
  ) {
    try {
      const { data } = await axiosInstance.delete<number>(
        `${MaterialsApi.deleteMaterialInfo}/${id}`,
      );

      dispatch(getAllMaterialsInfo(lang));

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const updateMaterial = createAsyncThunk(
  MaterialsSliceConstants.UpdateMaterial,
  async function (
    { material, lang }: { material: IMaterials; lang: string },
    { rejectWithValue, dispatch },
  ) {
    try {
      if (Array.isArray(material.image)) {
        const formData = new FormData();
        formData.append('file', material.image[0].originFileObj);

        const { data: imageId } = await axiosInstance.post(
          MaterialsApi.uploadImage,
          formData,
        );

        const data = await axiosInstance.post(
          `${MaterialsApi.updateMaterial}/${material.code}?code=${material.code}&lang=${lang}`,
          { ...material, image: imageId },
        );

        dispatch(getAllMaterials(lang));
        dispatch(getAllGroups(lang));

        return data;
      }

      const data = await axiosInstance.post(
        `${MaterialsApi.updateMaterial}/${material.code}?code=${material.code}&lang=${lang}`,
        material,
      );

      dispatch(getAllMaterials(lang));
      dispatch(getAllGroups(lang));

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const updateMaterialInfo = createAsyncThunk(
  MaterialsSliceConstants.UpdateMaterialInfo,
  async function (
    {
      materialInfo,
      lang,
    }: { materialInfo: { description: string }; lang: string },
    { rejectWithValue, dispatch },
  ) {
    try {
      const data = await axiosInstance.patch(
        `${MaterialsApi.updateMaterialInfo}?lang=${lang}`,
        materialInfo,
      );

      dispatch(getAllMaterialsInfo(lang));

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

const MaterialsSlice = createSlice({
  name: 'MaterialsSlice',
  initialState,
  reducers: {
    changeCurrentLanguage: (state, { payload }) => {
      state.currentTabLang = payload;
    },
    changeCurrentGroup: (state, { payload }) => {
      state.currentGroup = payload;
    },
    changeCurrentMaterial: (state, { payload }) => {
      state.currentMaterial = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getAllGroups.fulfilled,
      (state: IMaterialsState, { payload = [] }): IMaterialsState => {
        return {
          ...state,
          groups: payload.map((item) => ({
            ...item,
            materials: item.materials.filter((f) => !isNull(f)),
          })),
        };
      },
    );
    builder.addCase(
      getAllMaterials.fulfilled,
      (state: IMaterialsState, { payload }): IMaterialsState => {
        return {
          ...state,
          materials: payload,
        };
      },
    );
    builder
      .addCase(
        getAllMaterialsInfo.fulfilled,
        (state: IMaterialsState, { payload }): IMaterialsState => {
          return {
            ...state,
            materialsInfo: payload,
          };
        },
      )
      .addCase(getAllMaterialsInfo.rejected, (state, { payload }) => {
        return {
          ...state,
          materialsInfo: null,
        };
      });
    builder.addCase(
      getGroup.fulfilled,
      (state: IMaterialsState, { payload }): IMaterialsState => {
        return {
          ...state,
          currentGroup: {
            ...payload,
            materials: JSON.parse(payload.materials ?? '[]'),
          },
        };
      },
    );
  },
});

export const {
  changeCurrentGroup,
  changeCurrentMaterial,
  changeCurrentLanguage,
} = MaterialsSlice.actions;
export default MaterialsSlice.reducer;
